import React, { useState, useContext, useEffect, useRef } from 'react'
import ProductContext from '../../../context/product/productContext'
import AuthContext from '../../../context/auth/authContext'

import './FavoriteCheckbox.css'

const FavoriteCheckbox = ({ watchlisted, project_id, isDashboard }) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const { isAuthenticated } = authContext
    const [checked, setChecked] = useState(false)
    const { addWatchlist, removeWatchlist, dispatchWishlistArr, wishlistArr } = productContext
    const watchlistId = useRef(watchlisted)

    useEffect(() => {
        // initialize
        if (watchlisted != null && watchlisted > 0) {
            let wisli = [...wishlistArr]
            let index = wisli.indexOf(project_id)
            if (index === -1) {
                wisli.push(project_id)
                dispatchWishlistArr(wisli)
            }
            console.log(wisli, wishlistArr, 'wish')
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [])

    useEffect(() => {
        watchlistId.current = watchlisted
    })
    console.log(wishlistArr, 'wishh', project_id)
    if (isDashboard) {
        useEffect(() => {
            if (watchlistId.current != null && watchlistId.current > 0) {
                let wisli = [...wishlistArr]
                let index = wisli.indexOf(project_id)
                if (index === -1) {
                    wisli.push(project_id)
                    dispatchWishlistArr(wisli)
                }
                setChecked(true)
            } else {
                setChecked(false)
            }
        }, [watchlisted])
    }

    useEffect(() => {
        let index = wishlistArr.indexOf(project_id)
        if (index !== -1) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }, [wishlistArr])

    const toggleFavourite = () => {
        let wisli = [...wishlistArr]
        if (checked) {
            removeWatchlist({ project_id })
            watchlistId.current = null
            let index = wisli.indexOf(project_id)
            wisli.splice(index, 1)
            dispatchWishlistArr(wisli, project_id)
            setChecked(false)
        } else {
            addWatchlist({ project_id })
            let index = wishlistArr.indexOf(project_id)
            if (index === -1) {
                wisli.push(project_id)
            }
            dispatchWishlistArr(wisli)
            setChecked(true)
        }
    }

    return (
        <>
            {isAuthenticated && (
                <div className="favoriteCheck d-flex justify-content-center align-items-center">
                    <input
                        id={project_id}
                        type="checkbox"
                        checked={checked}
                        onChange={toggleFavourite}
                    />
                    <label htmlFor={project_id}>
                        {checked ? (
                            <span className="material-icons">star</span>
                        ) : (
                            <span className="material-icons">star_bordered</span>
                        )}
                    </label>
                </div>
            )}
        </>
    )
}
export default FavoriteCheckbox
