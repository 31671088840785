import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { mapData } from '../../../common/components'
import CustomDialog from '../../molecules/CustomDialog'
import PrimaryButton from '../../atoms/PrimaryButton'

const SubmitOffer = ({ open, handleClose, title }) => {
    const validationArray = Yup.object({
        offer_amount: Yup.string().required('Enter your offer amount'),
        offer_notes: Yup.string().required('Enter offer notes'),
    })

    const formik = useFormik({
        initialValues: {
            offer_amount: '',
            offer_notes: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {},
    })

    const loginInfo = [
        {
            label: 'Offer Amount',
            type: 'number',
            name: 'offer_amount',
            placeholder: 'Enter your price',
            class: 'col-12 offer-amount',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'Notes',
            type: 'text',
            name: 'offer_notes',
            placeholder: 'Any notes you&apos;d like to add?',
            class: 'col-12 offer-notes',
            formik: formik,
        },
    ]

    return (
        <CustomDialog open={open} handleClose={handleClose}>
            <h6 className="cd-product-title">{title}</h6>
            <form className="offer-row">
                <div className="row">{Object.values(mapData(loginInfo))}</div>
                <div className="pv-bottom-actions justify-content-center">
                    <PrimaryButton
                        // disabled={loading}
                        type="button"
                        label="Submit Offer"
                        btnSize="small"
                    />
                </div>
            </form>
            <p className="offer-term-note">
                By placing an offer, You agree to our <Link to="/terms">offer terms</Link>
            </p>
        </CustomDialog>
    )
}

export default SubmitOffer
