import React, { useContext, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'
import TertiaryButton from '../../atoms/TertiaryButton'
import FavoriteCheckbox from '../../atoms/FavoriteCheckbox'
import { Link } from 'react-router-dom'
import CustomDialog from '../../molecules/CustomDialog'
import OfferTimeline from '../../molecules/OfferTimeline'
import './productTable.css'
import moment from 'moment'
import { handleRedirectInternal } from '../../../common/components'
import authContext from '../../../context/auth/authContext'

export default function ProductTable({ headData, productData, page }) {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})
    const [productDataLocal, setProductDataLocal] = useState([])
    const [watchlist, setWatchList] = useState(false)
    const handleClose = () => setOpen(false)
    useEffect(() => {
        setProductDataLocal(productData)
    }, [productData])
    const handleOpen = (data) => {
        setData(data)
        setOpen(true)
    }
    const { isAuthenticated } = useContext(authContext)
    return (
        <>
            <TableContainer component={Paper} className="product-table-container">
                <Table>
                    <TableHead>
                        <TableRow className="product-table-head-row">
                            {headData.map((head) => (
                                <TableCell
                                    key={`product-head-${head}`}
                                    align={head == 'Action' ? 'center' : 'left'}
                                >
                                    {head}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productDataLocal.length == 0 ? (
                            <TableRow className="product-body-row">
                                <TableCell colSpan={headData.length}>No Product Found</TableCell>
                            </TableRow>
                        ) : (
                            productDataLocal.map((product) => (
                                <TableRow
                                    className="product-body-row text-decoration-none"
                                    // component={Link}
                                >
                                    <TableCell
                                        className="font-weight-bold"
                                        onClick={() =>
                                            (window.location.href = `/product/view/${product.id}`)
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {product.title}
                                    </TableCell>
                                    {/* {page == 'dashboard' ? (
                                        <>
                                            <TableCell>{product.status}</TableCell>
                                            <TableCell>{product.price}</TableCell>
                                            <TableCell>{product.currency}</TableCell>
                                        </>
                                    ) : ( */}
                                    <>
                                        <TableCell
                                            onClick={() =>
                                                (window.location.href = `/product/view/${product.id}`)
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div>{product.category}</div>
                                            <div> {product.category_name}</div>
                                        </TableCell>
                                        <TableCell
                                            className="text-center"
                                            onClick={() =>
                                                (window.location.href = `/product/view/${product.id}`)
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {product.qty}
                                        </TableCell>
                                        <TableCell
                                            className="tableCellWidth"
                                            onClick={() =>
                                                (window.location.href = `/product/view/${product.id}`)
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {product.currency_symbol}{' '}
                                            {parseFloat(
                                                product.buynow_amount ||
                                                    product.counter_amount ||
                                                    product.offer_amount ||
                                                    product.wprice_org ||
                                                    product.wprice,
                                            ).toFixed(3)}
                                        </TableCell>
                                        {/* <TableCell
                                            className="tableCellWidth"
                                            onClick={() =>
                                                (window.location.href = `/product/view/${product.id}`)
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {moment(product.date_added).format('MM-DD-YYYY')}
                                        </TableCell> */}
                                    </>
                                    {/* )} */}

                                    <TableCell>
                                        {page == 'dashboard' ? (
                                            // <PrimaryButton
                                            //     // disabled={loading}
                                            //     type="button"
                                            //     label="View Offer"
                                            //     btnSize="small"
                                            //     onClick={() => handleOpen(product)}
                                            // />
                                            <Link
                                                to={`/invoice/${product.invoice_id}`}
                                                className="make-offer"
                                            >
                                                View Order
                                            </Link>
                                        ) : (
                                            <div className="watchListBtn">
                                                <Link
                                                    className="make-offer"
                                                    onClick={() =>
                                                        (window.location.href = `/product/view/${product.id}`)
                                                    }
                                                >
                                                    View Details
                                                </Link>
                                                {isAuthenticated ? (
                                                    <>
                                                        <FavoriteCheckbox
                                                            watchlisted={product.watchlistid}
                                                            project_id={product.id}
                                                        />
                                                    </>
                                                ) : null}
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {page == 'dashboard' ? (
                <CustomDialog open={open} handleClose={handleClose}>
                    <h6 className="cd-product-title">{data?.title}</h6>
                    <h6 className="offer-history-head">Offer History</h6>
                    <OfferTimeline data={data} />
                    <div className="offer-actions">
                        <h6 className="current-offer">
                            Current Offer: <b>&euro; 200</b>
                        </h6>
                        <div className="counter-offer-actions">
                            <TertiaryButton
                                // disabled={loading}
                                type="button"
                                label="Reject"
                                btnSize="small"
                            />
                            <SecondaryButton
                                // disabled={loading}
                                type="button"
                                label="Counter Offer"
                                btnSize="small"
                            />
                            <PrimaryButton
                                // disabled={loading}
                                type="button"
                                label="Accept"
                                btnSize="small"
                            />
                        </div>
                    </div>
                </CustomDialog>
            ) : null}
        </>
    )
}
