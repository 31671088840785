import React from 'react'
import { Link } from 'react-router-dom'

export default function NavItem({ href, isActive, children }) {
    return (
        <li>
            <Link to={href} className={isActive ? 'nav-item-link active' : 'nav-item-link'}>
                {children}
            </Link>
        </li>
    )
}
