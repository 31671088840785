import React, { useContext, useState, useEffect } from 'react'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import '../Login/Login.css'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import AlertContext from '../../context/alert/alertContext'
import UserContext from '../../context/user/userContext'
import { Link } from 'react-router-dom'
import { logo, siteName } from '../../Utils'
import CommonContext from '../../context/common/commonContext'

const ForgotPassword = () => {
    const userContext = useContext(UserContext)
    const alertContext = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)

    const { forgotPassword, responseStatus, clearResponse } = userContext
    const { setAlert } = alertContext
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    const validationArray = Yup.object({
        email: Yup.string().email(phrase.invalid_format).required(phrase.this_is_required),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            values['site_id'] = global.site_id
            setLoader(true)
            forgotPassword(values)
        },
    })

    const forgotValues = [
        {
            label: phrase.email_address,
            name: 'email',
            type: 'email',
            placeholder: `${phrase.enter} ${phrase.email_address}`,
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'forgotPassword') {
                setLoader(false)
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus?.message, responseStatus?.type ? 'success' : 'error')
                    if (responseStatus?.type) {
                        handleRedirectInternal(history, '')
                    }
                }
            }
            clearResponse()
        }
    }, [responseStatus])

    useEffect(() => {
        formik.setTouched({
            ...formik.touched,
        })
    }, [localStorage.getItem('language')])
    return (
        <div className="row loginContainer forgotPassword">
            <div className="col-12">
                <div className="loginFormBox py-5">
                    <div className="loginBox">
                        <span className="ledLogo">
                            <a className="nav-standard-logo" href="/">
                                <img src="../assets/images/logo.png" alt="logo" />
                            </a>
                        </span>
                        <form
                            className="login-ip-box"
                            onSubmit={formik.handleSubmit}
                            autoComplete="nofill"
                        >
                            <h1 className="signin">
                                Forgot <span>Password?</span>
                            </h1>
                            <div className="row loginFields">
                                {Object.values(mapData(forgotValues))}
                            </div>

                            <div className="text-center form-group">
                                <PrimaryButton type="submit" label="Reset Password" />
                            </div>
                            <div className="newAcc mt-3 text-center">
                                <Link to="/login">Back to Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword
