import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import '../Login/Login.css'
import UserContext from '../../context/user/userContext'
import { Link } from 'react-router-dom'
import { logo, siteName } from '../../Utils'
import CommonContext from '../../context/common/commonContext'
import PropTypes from 'prop-types'

const UnsubscribeEmail = (props) => {
    const userContext = useContext(UserContext)
    const { phrase } = useContext(CommonContext)
    const { decodeResetPasswordToken, unsubscribeEmail } = userContext

    const [email, setEmail] = useState('')
    const [completed, isCompleted] = useState(false)

    useEffect(async () => {
        if (props.match.params.token) {
            const data = await decodeResetPasswordToken(props.match.params.token)
            setEmail(data.emailid)
        }
    }, [])

    const unsubscribe = () => {
        if (email != '') {
            var formData = new FormData()
            formData.append('site_id', global.site_id)
            formData.append('email', email)
            let response = unsubscribeEmail(formData)
            if (response) {
                isCompleted(true)
            }
        }
    }

    return (
        <div className="loginContainer">
            <div className="login forgotPassword">
                <Link to="/">
                    <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                </Link>
                <h2 className="loginTitle">
                    <span>Unsubscribe</span>
                </h2>
                {!completed ? (
                    <>
                        <h4>Would you like to unsubscribe your email?</h4>
                        <div className="row ml-2 mr-2">
                            <b>
                                <label>Your Email</label>: &nbsp; {email}
                            </b>
                        </div>
                        <p>
                            After unsubscribe you will no longer receive Outbid email, winning
                            email, etc. You can re-subscribe in &quot;My Account&quot; and select
                            how/what you wish to be notified.
                        </p>
                        <PrimaryButton type="submit" label="Unsubscribe" onClick={unsubscribe} />{' '}
                    </>
                ) : (
                    <>
                        <h4>You are unsubscribed!</h4>
                        <div className="loginMiscAction">
                            <p>
                                <span className="material-icons">arrow_back</span>
                                <Link to="/login">{phrase.back_to_login}</Link>
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

UnsubscribeEmail.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string,
        }),
    }),
}

export default UnsubscribeEmail
