import React from 'react'
import './style.css'
const PreviewImage = (props) => {
    return (
        <div>
            <div className="row pt-2">
                {props.preview_image &&
                props.preview_image instanceof Array &&
                props.preview_image.length > 0
                    ? props.preview_image.map((value, key) => (
                          <div className="col-md-12 mt-2">
                              <div className="pp-uimg mx-auto d-block">
                                  <span
                                      className="img-del "
                                      onClick={() => props.removeImage(value.name)}
                                      name={key}
                                  >
                                      {' '}
                                      X{' '}
                                  </span>
                              </div>
                              <img
                                  key={key}
                                  src={value.file ? URL.createObjectURL(value.file) : value.src}
                                  style={{ width: '100px', height: '100px' }}
                                  className="pro-img rounded img-fluid mx-auto d-block"
                              />
                              <p>{value.file_name}</p>
                          </div>
                      ))
                    : null}
            </div>
        </div>
    )
}

export default PreviewImage
