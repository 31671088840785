export const filterList = [
    {
        id: 1,
        filterTitle: 'Networking',
        lists: [
            {
                id: '1a',
                title: 'Networking1',
                value: 'networking',
                name: 'country',
            },
        ],
    },
    {
        id: 2,
        filterTitle: 'HDD',
        lists: [],
    },
    {
        id: 3,
        filterTitle: 'Desktops',
        lists: [],
    },
    {
        id: 4,
        filterTitle: 'Laptops',
        lists: [],
    },
    {
        id: 5,
        filterTitle: 'Mobility',
        lists: [],
    },
    {
        id: 6,
        filterTitle: 'All-in-Ones',
        lists: [],
    },
    {
        id: 7,
        filterTitle: 'Monitors',
        lists: [],
    },
    {
        id: 8,
        filterTitle: 'Parts/Spares',
        lists: [],
    },
    {
        id: 9,
        filterTitle: 'Refurbished',
        lists: [],
    },
    {
        id: 10,
        filterTitle: 'Tablets',
        lists: [],
    },
    {
        id: 11,
        filterTitle: 'Workstations',
        lists: [],
    },
]

export const sortOptions = [
    { show: 'Price: Low - High', value: 3 },
    { show: 'Price: High- Low', value: 4 },
]

export const perPageOptions = [
    { show: 'Results Per Page', value: '' },
    { show: 10, value: 10 },
    { show: 25, value: 25 },
    { show: 50, value: 50 },
    { show: 100, value: 100 },
]

export const menuList = [
    // {
    //     label: 'Offers',
    //     href: '/dashboard/buy_now',
    //     icon_name: 'offer.svg',
    //     active_icon: 'offer-red.svg',
    // },
    {
        label: 'Change Password',
        href: '/change-password',
        icon_name: 'lock.svg',
        active_icon: 'lock-red.svg',
    },
    {
        label: 'Profile',
        href: '/my_account',
        icon_name: 'user.svg',
        active_icon: 'user-red.svg',
    },
    {
        label: 'Messages',
        href: '/messages',
        icon_name: 'mail.svg',
        active_icon: 'mail-red.svg',
    },
]
export const offerMenuList = [
    {
        label: 'Active',
        href: '/dashboard/buy_now',
        icon_name: 'offer.svg',
        active_icon: 'offer-red.svg',
    },
    {
        label: 'Completed',
        href: '/dashboard/make_offer',
        icon_name: 'offer.svg',
        active_icon: 'offer-red.svg',
    },
]
