import { STATICPAGELOADER } from './loader.types'

export default (state, action) => {
    console.log('Loader reducer called', action, state)
    switch (action) {
        case STATICPAGELOADER:
            console.log(action, state, 'asdadadadadadadadadsda')
            return {
                ...state,
                isStaticPageLoad: action.payload,
            }
        default:
            return state
    }
}
