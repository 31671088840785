import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
    root: {
        '& > *': {
            width: (props) => (props.width ? props.width : '100%'),
            height: '60px',
        },
    },
})

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        borderRadius: '5px',
        boxShadow: '0px 4px 12px rgba(var(--primColor), 20%)',
        backgroundColor: 'var(--primColor)',
        border: '1px solid var(--primColor)',
        transition: '200ms all ease-in-out',
        // borderBottom: '3px solid #0e7340',
        '&:hover': {
            backgroundColor: 'var(--primColor)',
            color: '#fff',
            border: '1px solid var(--primColor)',
            opacity: '0.9',
            boxShadow: '0px 4px 12px rgba(var(--primColor), 20%)',
            // borderBottom: '3px solid #0e7340',
        },
    },
}))(Button)
const PrimaryButton = (props) => {
    const classes = useStyles(props)
    return (
        <div className={`${classes.root} ${props.btnSize} primButton`}>
            <ColorButton
                variant="contained"
                color="primary"
                id={props.id}
                onClick={props.onClick}
                type={props.type}
                disabled={props.disabled ? true : false}
            >
                {props.iconLeft}
                {props.label}
                {props.children}
                {props.iconRight}
            </ColorButton>
        </div>
    )
}

export default PrimaryButton
