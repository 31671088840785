import React, { useReducer } from 'react'
import AuthContext from './authContext'
import AuthReducer from './authReducer'
import { apiCall, setAuthToken } from '../../common/api'
import { response } from '../common'
import { USER_LOADED, LOGOUT, RESPONSE_STATUS, CLEAR_RESPONSE } from './authTypes'
import { useContext } from 'react'
import commonContext from '../common/commonContext'

const AuthState = (props) => {
    const { changeLanguage } = useContext(commonContext)
    const initialState = {
        token: localStorage.getItem('token'),
        isAuthenticated: null,
        isAdmin: null,
        loading: true,
        user: {},
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuthReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    //Upload Tax Exempt File
    const uploadFile = async (formData) => {
        try {
            setAuthToken(formData.nonloginToken)
            const [res] = await Promise.all([
                apiCall('post', '', formData, '', 'register/savefile'),
            ])
            resp.commonResponse(res.data, 'register')
            return res
        } catch (err) {
            resp.commonErrorResponse('register')
            return err
        }
    }

    // Register User
    const register = async (formData, type) => {
        try {
            setAuthToken(formData.nonloginToken)
            if (type == 'after_register') {
                const [res] = await Promise.all([
                    apiCall('post', '', formData, '', 'afterregister-save'),
                ])
                localStorage.setItem('token', res.data.token)
                await loadUser()
                resp.commonResponse(res.data, 'register')
                return res
            } else {
                const [res] = await Promise.all([
                    apiCall('post', '', formData, '', 'register-save'),
                ])
                resp.commonResponse(res.data, 'register')
                return res
            }
        } catch (err) {
            resp.commonErrorResponse('register')
            return err
        }
    }

    // Check Email User
    const checkValidation = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkValidation', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'checkValidation')
        } catch (err) {
            resp.commonErrorResponse('checkValidation')
        }
    }

    // Login User
    const login = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'save', formData, '', 'login')])

            console.log(res.data, 'this is res.data')
            if (res.data.returnval === 3) {
                resp.commonResponse(res.data, 'login')
                localStorage.setItem('token', res.data.token)
                loadUser()
                return res.data
            } else if (res.data.returnval === 5) {
                return res.data
            } else {
                resp.commonResponse(res.data, 'login')
            }
            resp.commonResponse(res.data, 'login')
        } catch (err) {
            resp.commonErrorResponse('login')
        }
    }

    // Load User
    const loadUser = async () => {
        if (localStorage.token) {
            setAuthToken(localStorage.token)
        }
        try {
            const [res] = await Promise.all([apiCall('post', 'validateUser', {}, '', '')])
            if (res.data.status === 'success') {
                var userObj = res.data.user
                userObj.isBuyer = true
                // if user is admin, or user is seller
                // is_emp = 1, is seller
                if (res.data.user.admin != '0' || res.data.user.is_emp == '1') {
                    userObj.isBuyer = false
                }
                dispatch({
                    type: USER_LOADED,
                    payload: {
                        data: userObj,
                    },
                })
                changeLanguage(userObj.languageuser)
            } else if (res.data.status === 'error') {
                dispatch({
                    type: LOGOUT,
                })
            } else {
                dispatch({
                    type: LOGOUT,
                })
            }
        } catch (err) {
            console.log('ERROR: ', err)
            dispatch({
                type: LOGOUT,
            })
        }
    }

    // Log out
    const logout = () =>
        dispatch({
            type: LOGOUT,
        })

    // Clear Response
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                isAdmin: state.isAdmin,
                loading: state.loading,
                user: state.user,
                responseStatus: state.responseStatus,
                register,
                uploadFile,
                checkValidation,
                login,
                logout,
                loadUser,
                clearResponse,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState
