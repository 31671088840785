import React from 'react'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../atoms/PrimaryButton'

const Maintainance = () => {
    const history = useHistory()
    const leaveMaintainacePage = () => {
        global.maintainance_mode = false
        history.push('/')
    }
    return (
        <div style={{ height: '600px', marginTop: '100px' }}>
            <h1>API Disabled</h1>
            <p>The service is currently unavailable. Please contact the administrator.</p>
            <PrimaryButton
                btnSize="small"
                label="Ok"
                width="50px"
                onClick={() => leaveMaintainacePage()}
            />
        </div>
    )
}

export default Maintainance
