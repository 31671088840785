import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall, setAuthToken, getAuthToken } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_INVOICE,
    GET_ALL_SEARCH,
    GET_ALL_SIMILAR,
    GET_ALL_BID_HISTORY,
    GET_ALL_ACTIVE_SORTS,
    GET_ALL_DASHBOARD,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_USER_INVOICES,
    PROJECTSMAIN,
    PROJECTSCOMPLETED,
    SELECTEDCATEGORIES,
    PROJECTSMAINARRLOADING,
    PROJECTSCOMPLETEDARRLOADING,
    DASHBOARD_LOADING,
    WISHLISTARR,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        search_alldashboardproducts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        active_sorts: {
            sorts: {},
        },
        search_allsimilar: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_allproducts: {
            category: [],
            banner: [],
            category: [],
            sub_category: [],
            countrylist: [],
            itemcondition: [],
            lottype: [],
            regionlist: [],
        },
        get_allbidhistory: {
            records: [],
            totalRecords: 0,
            // setDisp: '',
            // sorts: {},
        },
        search_allinvoiceproducts: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        search_alluserinvoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        responseStatus: null,
        projectsMain: [],
        projectCompleted: [],
        selectedCategories: [],
        projmainloading: false,
        projcompletedloading: false,
        dashboardLoading: false,
        wishlistArr: [],
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const getDashboardInfo = async (dashboardType) => {
        try {
            const res = await Promise.all([
                apiCall('post', `${dashboardType}`, formData, '', 'dashboard'),
            ])
            const from = 'dashboard'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_DASHBOARD_INFO,
                    payload: {
                        category: res[1].data.data.categoryhtm,
                        itemcondition: res[1].data.data.itemconditionhtm,
                        auctiontype: res[1].data.data.auctiontypehtm,
                        regionlist: res[1].data.data.regionlisthtm,
                        lottype: res[1].data.data.lottypehtm,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('[getDashboardIndo] ', err)
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllDashboardProducts = async (formData) => {
        try {
            dispatch({
                type: DASHBOARD_LOADING,
                payload: true,
            })
            const [res] = await Promise.all([
                apiCall('post', 'searchdashprojects', formData, '', 'dashboard'),
            ])
            const from = 'dashboard'
            //console.log('DASHBOARD PRODUCTS: ', res.data)
            if (res.data.status === 'success') {
                //console.log('DASHBOARD PRODUCTS: ', res.data.data)
                dispatch({
                    type: GET_ALL_DASHBOARD,
                    payload: {
                        records: res.data.data.dashproj.length > 0 ? res.data.data.dashproj : [],
                        totalRecords: res.data.data.total_pagecnt,
                        setDisp: res.data.data.set_disp,
                        sorts: '',
                    },
                })
                dispatch({
                    type: DASHBOARD_LOADING,
                    payload: false,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('[getAllDashboardProducts] ', err)
            dispatch({
                type: DASHBOARD_LOADING,
                payload: false,
            })
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllInvoiceProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'invoice', formData, '', 'product')])
            const from = 'invoicesearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems.length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData.allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData.appointmentDetail,
                        locationrecord: res.data.data.responseData.locationDetail,
                        cartvalues: res.data.data.responseData.cartValues,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUserInvoices = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allinvoices', formData, '', 'product'),
            ])
            const from = 'allinvoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    // get side search filter panel values
    const getAllSearchProducts = async (formData, type) => {
        if (type === 'search') {
            try {
                setAuthToken(getAuthToken())
                const [res] = await Promise.all([
                    apiCall(
                        'get',
                        '',
                        formData,
                        '',
                        `search${localStorage.getItem('token') ? '' : '_new'}?v=2`,
                    ),
                ])
                const from = 'search'
                res.data.status = res.data.status ? 'success' : 'error'
                if (res.data.status === 'success') {
                    let payloadObj = {}
                    payloadObj['category'] = res.data.arr_temp['category']
                    payloadObj['sub_category'] = res.data.arr_temp['sub_category']
                    payloadObj['banner'] = res.data.arr_temp['banner']
                    payloadObj['auctiontype'] = res.data.arr_temp['auctiontypehtm']
                    payloadObj['countrylist'] = res.data.arr_temp['countrylisthtm']
                    payloadObj['itemcondition'] = res.data.arr_temp['itemconditionhtm']
                    payloadObj['lottype'] = res.data.arr_temp['lottypehtm']
                    payloadObj['regionlist'] = res.data.arr_temp['regionlisthtm']
                    //console.log('search products filter panel::: ', payloadObj)
                    dispatch({
                        type: GET_ALL_SEARCH,
                        payload: payloadObj,
                    })
                } else if (res.data.status === 'error') {
                    resp.commonResponse(res.data, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            } catch (err) {
                console.log('ERROR_______', err)
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Something went wrong!',
                })
            }
        } else if (type === 'dashboard') {
            try {
                const [res] = await Promise.all([apiCall('post', '', formData, '', 'dashboard')])
                const from = 'dashboard'
                if (res.data.status === 'success') {
                    //console.log('DASHBOARD PRODUCTS: ', res.data.data)
                    dispatch({
                        type: GET_ALL_SEARCH,
                        payload: {
                            category: res.data.data.categories,
                            sub_category: res.data.data.sub_category,
                            itemcondition: res.data.data.conditionpop,
                            auctiontype: res.data.data.auctiontypehtm,
                            countrylist: [],
                            regionlist: res.data.data.regionlisthtm,
                            lottype: res.data.data.lottypehtm,
                        },
                    })
                } else if (res.data.status === 'error') {
                    resp.commonResponse(res.data, from)
                } else {
                    resp.commonErrorResponse(from)
                }
            } catch (err) {
                console.log('[getAllSearchProducts] ', err)
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'Something went wrong!',
                })
            }
        }
    }

    const getAllActiveSorts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'activeSorts', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_ACTIVE_SORTS,
                    payload: {
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSimilarProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'similar', formData, '', 'product')])
            const from = 'similar'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SIMILAR,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlist = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', `add-to-wishlist/${formData.project_id}`, formData, '', `product`),
            ])
            res.data.status = res.data.status ? 'success' : 'error'
            res.data.message = 'Watch list successfully updated'
            res.data.responseData = {
                project_id: formData.project_id,
            }
            resp.commonResponse(res.data, 'watchlist')
        } catch (err) {
            resp.commonErrorResponse('watchlist')
        }
    }

    const removeWatchlist = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    `remove-from-wishlist/${formData.project_id}`,
                    formData,
                    '',
                    'product',
                ),
            ])
            // message is returned from the backend
            res.data.responseData = {
                project_id: formData.project_id,
            }
            resp.commonResponse(res.data, 'removeWatchlist')
        } catch (err) {
            resp.commonErrorResponse('removeWatchlist')
        }
    }

    const getAllBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('get', `view-bidhistory/${formData.id}`, formData, '', 'product'),
            ])
            const from = 'product'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.data.bids.length ? res.data.data.bids : [],
                        totalRecords: res.data.data.bids.length,
                        // setDisp: res.data.data.responseData.setDisp,
                        // sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getProjectsMain = async (formData) => {
        formData.version = 2
        dispatch({
            type: PROJECTSMAINARRLOADING,
            payload: true,
        })
        const res = await apiCall(
            'post',
            ``,
            formData,
            '',
            `searchprojectsmain${localStorage.getItem('token') ? '' : '_new'}`,
        )
        let respData = res.data
        let projectmainProject = res.data.data
        //console.log('[getProjectsMain] ', res.data)
        if (Object.keys(projectmainProject).length) {
            let payload = {
                projectsMainArr: projectmainProject.proj,
                set_disp: projectmainProject.set_disp,
                totalRecords: projectmainProject.totalRecords,
            }
            dispatch({
                type: PROJECTSMAIN,
                payload: payload,
            })
            dispatch({
                type: PROJECTSMAINARRLOADING,
                payload: false,
            })
        }
    }
    const getProjectsCompleted = async (formData) => {
        formData.version = 2
        dispatch({
            type: PROJECTSCOMPLETEDARRLOADING,
            payload: true,
        })
        const res = await apiCall(
            'post',
            ``,
            formData,
            '',
            `searchprojectscompleted${localStorage.getItem('token') ? '' : '_new'}`,
        )
        //console.log('[getProjectsCompleted] ', res.data)
        let respData = res.data
        let projectCompleted = res.data.data
        if (Object.keys(projectCompleted).length) {
            let payload = {
                projcompletedArr: projectCompleted.projcompleted,
                set_disp: projectCompleted.set_dispcompleted,
                totalRecords: projectCompleted.totalRecords,
                total_onlycompleted: projectCompleted.total_onlycompleted,
            }
            dispatch({
                type: PROJECTSCOMPLETED,
                payload: payload,
            })
            dispatch({
                type: PROJECTSCOMPLETEDARRLOADING,
                payload: false,
            })
        }
    }
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    const dispatchSelectedCategories = (categoryArr) => {
        dispatch({
            type: SELECTEDCATEGORIES,
            payload: categoryArr,
        })
    }
    const dispatchWishlistArr = (wishlistarr, remove_id) => {
        console.log(wishlistarr, 'wishlistarr')
        dispatch({
            type: WISHLISTARR,
            payload: wishlistarr,
            remove_id,
        })
    }
    console.log(state.wishlistArr, 'state.wishlistArr')
    return (
        <ProductContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                search_allproducts: state.search_allproducts,
                search_alldashboardproducts: state.search_alldashboardproducts,
                search_allinvoiceproducts: state.search_allinvoiceproducts,
                search_alluserinvoices: state.search_alluserinvoices,
                active_sorts: state.active_sorts,
                get_allbidhistory: state.get_allbidhistory,
                search_allsimilar: state.search_allsimilar,
                responseStatus: state.responseStatus,
                projectsMain: state.projectsMain,
                projectCompleted: state.projectCompleted,
                selectedCategories: state.selectedCategories,
                projcompletedloading: state.projcompletedloading,
                projmainloading: state.projmainloading,
                dashboardLoading: state.dashboardLoading,
                wishlistArr: state.wishlistArr,
                clearResponse,
                getAllDashboardProducts,
                getAllSearchProducts,
                getAllInvoiceProducts,
                getAllUserInvoices,
                getAllSimilarProducts,
                addWatchlist,
                removeWatchlist,
                getAllActiveSorts,
                getAllBidHistory,
                getProjectsMain,
                getProjectsCompleted,
                dispatchSelectedCategories,
                dispatchWishlistArr,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
