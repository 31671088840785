import React, { useContext } from 'react'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
//import html2pdf from 'html2pdf.js'

// Style / CSS
import './invoice.css'
import { png_logo, siteName } from '../../../Utils'
import { Button } from '@material-ui/core'

// common function
import { dateFormatFrontDay } from '../../../common/components'

// context
import CommonContext from '../../../context/common/commonContext'
import AlertContext from '../../../context/alert/alertContext'

function Invoice({ data, buynowConfirm }) {
    const { phrase } = useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)

    const downloadInvoice = () => {
        // var opt = {
        //     margin: [10, 0, 0, 0],
        //     pagebreak: { mode: [], avoid: ['.media', '.inv-btm-wrap']}
        // };
        // html2pdf().set(opt).from(document.getElementById('invoice')).save('invoice.pdf');
        html2canvas(document.getElementById('invoice'), {
            scale: 2,
            allowTaint: true,
            useCORS: true,
        })
            .then((canvas) => {
                // Your IMAGE_DATA_URI
                var imgData = canvas.toDataURL('image/jpeg')
                // Make pdf
                // let options = { pagesplit: true }
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'px',
                    format: [720, 420],
                })
                // add image
                console.log(imgData)
                var width = 720
                var height = 420

                pdf.addImage(imgData, 'PNG', 0, 0, width, height)
                // Save document
                pdf.save('invoice.pdf')
            })
            .catch((e) => {
                console.log(e)
                setAlert(phrase.something_went_wrong, 'error')
            })
    }

    return (
        <div className="invoiceWrapper">
            {buynowConfirm ? null : (
                <div className="downloadInvoice">
                    <Button className="backBtn my-2" onClick={() => downloadInvoice()}>
                        {phrase.download}
                    </Button>
                </div>
            )}
            <div className="invoice" id="invoice">
                <div className="row invoiceInfo">
                    <div className="col-md-3">
                        <h2>{phrase.order}</h2>
                    </div>
                    <div className="col-md-3">
                        <label>
                            {phrase.order} {phrase.date}
                        </label>
                        <h5> {dateFormatFrontDay(data.date_added)}</h5>
                    </div>
                    <div className="col-md-3">
                        {buynowConfirm ? null : (
                            <>
                                <label>Status</label>
                                <h5 className="primeColor">
                                    {data.paid == 0 ? phrase.unpaid : phrase.paid}
                                </h5>
                            </>
                        )}
                    </div>
                    <div className="col-md-3 text-right">
                        <img
                            src={png_logo}
                            className="invoiceLogo"
                            id="svg-container"
                            alt={`${siteName} logo`}
                        />
                    </div>
                </div>
                <div className="row invoiceInfo mt-4">
                    <div className="col-md-3">
                        <div className="buyerAddress">
                            <h3>{data.companyname}</h3>
                            <address>
                                {data.address1} <br />
                                {data.city}, {data.state} {data.zip} <br />
                                {data.country}
                            </address>
                        </div>
                    </div>
                    <div className="col-md-3">
                        {buynowConfirm ? null : (
                            <div className="mb-3">
                                <label>{phrase.order} #</label>
                                <h5>{data.common_invoice}</h5>
                            </div>
                        )}

                        <div>
                            <label>{phrase.delivery_method}</label>
                            <h5>
                                {data.buyership == 0
                                    ? data.shipping_service
                                    : phrase.shipping_handled_by_buyer}
                            </h5>
                        </div>
                    </div>
                    <div className="col-md-6 text-right">
                        {buynowConfirm ? null : (
                            <div className="salesRepInfo">
                                {/* <h6>
                                    Account #1 :{' '}
                                    {data.iff_num && data.iff_num != null ? data.iff_num : null}
                                </h6>
                                <h6>
                                    Account #2 :{' '}
                                    {data.ifs_impacc && data.ifs_impacc != null
                                        ? data.ifs_impacc
                                        : null}
                                </h6>

                                <h6>
                                    Account #3 :{' '}
                                    {data.ifs_imm && data.ifs_imm != null ? data.ifs_imm : null}
                                </h6>
                                <h6>
                                    Account #4 :{' '}
                                    {data.ifs_rl && data.ifs_rl != null ? data.ifs_rl : null}
                                </h6>
                                <h6>
                                    Account #5 :{' '}
                                    {data.ifs_anfra && data.ifs_anfra != null
                                        ? data.ifs_anfra
                                        : null}
                                </h6> */}
                                <h5>
                                    {phrase.sales_rep}: {data.repfirstname} {data.replastname}
                                </h5>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row mt-4 table">
                    <div className="table-responsive">
                        <table className="table">
                            <thead className="thead-light">
                                {data.auctiontype != 'lineitem' ? (
                                    <tr>
                                        {/* <th scope="col">Image</th> */}
                                        <th scope="col">Product Name</th>
                                        <th scope="col">{phrase.lot_id} </th>
                                        <th scope="col">{phrase.no_of_items}</th>
                                        <th scope="col"> {phrase.condition}</th>
                                        <th scope="col"> {phrase.lot_size}</th>
                                        {/* <th scope="col">Master Lot Id</th> */}
                                        <th scope="col">{phrase.amount}</th>
                                    </tr>
                                ) : (
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">{phrase.lot_id}</th>
                                        <th scope="col">{phrase.master_lot_id}</th>
                                        <th scope="col">{phrase.no_of_items}</th>
                                        <th scope="col">{phrase.amount}</th>
                                    </tr>
                                )}
                            </thead>

                            <tbody>
                                {data.auctiontype != 'lineitem' ? (
                                    <tr>
                                        {/* <td>
                                            <img
                                                src={
                                                    global.site_url +
                                                    `/uploads/product/compressed/${data.avatar}`
                                                }
                                            />
                                        </td> */}
                                        <td>{data.title}</td>
                                        <td>{data.id}</td>
                                        <td>
                                            {data.qty} {phrase.items}
                                        </td>
                                        <td>{phrase[data.condition]}</td>
                                        <td>
                                            {phrase[data.lottype]} {phrase.lot}
                                        </td>
                                        <td>
                                            {data.currency_symbol}
                                            {parseFloat(data.amount).toFixed(3)}
                                        </td>
                                    </tr>
                                ) : (
                                    data.lineitem.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{item.title}</td>
                                                <td>{item.id}</td>
                                                <td>{item.linesublot}</td>
                                                <td>
                                                    {item.qty} {phrase.items}
                                                </td>
                                                <td>
                                                    {data.currency_symbol}
                                                    {parseFloat(item.amount).toFixed(3)}
                                                </td>
                                            </tr>
                                        )
                                    })
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr className="mb-2" />
                <div className="row">
                    <div className="col-12">
                        <div className="invoiceDue d-flex flex-wrap justify-content-between">
                            <div>
                                {buynowConfirm ? null : (
                                    <>
                                        <label>
                                            {data.pay_reject == 1
                                                ? phrase.cancelled + ' ' + phrase.date
                                                : data.paid == 0
                                                ? phrase.due_date
                                                : phrase.paid + ' ' + phrase.date}
                                        </label>
                                        <h4>
                                            {data.pay_reject == 1
                                                ? dateFormatFrontDay(data.r_date)
                                                : data.paid == 0
                                                ? dateFormatFrontDay(data.inv_createddate)
                                                : dateFormatFrontDay(data.inv_paiddate)}
                                        </h4>
                                    </>
                                )}
                            </div>

                            <div className="totalDue">
                                <label>
                                    {data.paid == 0 ? phrase.total_due : phrase.total_paid}:
                                </label>
                                <h4>
                                    {data.currency_symbol}
                                    {data.buyership == 0
                                        ? (
                                              parseFloat(data.amount) +
                                              parseFloat(data.shipping_fee)
                                          ).toFixed(3)
                                        : parseFloat(data.amount).toFixed(3)}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice
