import React from 'react'
import { siteName } from '../../Utils'

export default function grading() {
    return (
        <div className="staticContent" style={{ color: '#000' }}>
            <strong
                style={{
                    display: 'block',
                    marginBottom: '25px',
                    textAlign: 'center',
                    padding: '10px',
                    border: '0',
                    fontSize: '22px',
                    borderTop: '3px #0057b8',
                    borderBottom: '3px #0057b8',
                    textTransform: 'capitalize',
                    fontWeight: '700',
                    borderStyle: 'double',
                }}
            >
                Device Type: <u>iPads</u>
            </strong>
            <b style={{ display: 'block', marginBottom: '10px' }}>Packaging & Labeling:</b>
            <p>
                All iPad Packaging is retail ready, white boxed and kitted. Diamond Assets utilizes
                a standard serial number barcode generated on each label. A product identifier is
                also located on our labeling. Additionally, UPC’s can be applied if necessary
            </p>
            <b style={{ display: 'block', marginBottom: '25px' }}>Packaging Photos:</b>

            <figure>
                <img
                    style={{
                        width: '200px',
                        height: ' 200px',
                        objectFit: 'contain',
                        marginRight: '15px',
                    }}
                    src="/assets/images/ipad1.PNG"
                    alt="Packing Box"
                />
                <img
                    style={{
                        width: '200px',
                        height: ' 200px',
                        objectFit: 'contain',
                        marginRight: '15px',
                    }}
                    src="/assets/images/ipad2.PNG"
                    alt="Packing Box"
                />
                <img
                    style={{
                        width: '200px',
                        height: ' 200px',
                        objectFit: 'contain',
                        marginRight: '15px',
                    }}
                    src="/assets/images/ipad3.PNG"
                    alt="Packing Box"
                />
            </figure>

            <b style={{ display: 'block', marginBottom: '15px' }}>
                Box Dimensions, Weights, and Palletizing:
            </b>
            <p style={{ marginBottom: '0px' }}>Height:2.5”</p>
            <p style={{ marginBottom: '0px' }}>Width:8”</p>
            <p style={{ marginBottom: '0px' }}>Length:12.5”</p>

            <p style={{ marginTop: '10px' }}>Completed Weight:1.6lbs</p>
            <strong style={{ display: 'block', textAlign: 'center', marginBottom: '25px' }}>
                iPad Grading Criteria
            </strong>
            <table className="table" Border="1" style={{ boderColor: '#000' }}>
                <tr>
                    <th width="150px">Product Grade</th>
                    <th>Grading Description</th>
                </tr>
                <tr>
                    <td>Grade A:</td>
                    <td>
                        Device may contain light scratching on screen or casing, light wear. No
                        dented corners.
                    </td>
                </tr>
                <tr>
                    <td>Grade B:</td>
                    <td>
                        Light scratch(es) on screen, minor dented corners, minor knick(s) or
                        blemish(es), light Wear. Two or less light dead pixels.
                    </td>
                </tr>
                <tr>
                    <td>Grade C:</td>
                    <td>
                        Moderate scratch(es) on screen. Scratches can be felt with fingernail or
                        visible when powered on. Moderate dented corner(s). Multiple knicks and/or
                        blemishes. Multiple dead pixels. Moderate wear markings.
                    </td>
                </tr>
                <tr>
                    <td>Grade D:</td>
                    <td>
                        Deep Scratch(es) on screen. Major dented corners. Major dead pixels. Severe
                        Wear. Unit is functional
                    </td>
                </tr>
                <tr>
                    <td>Grade F:</td>
                    <td>Non-Functional or *functionally defective device</td>
                </tr>
                <tr>
                    <td>**Repair Grade</td>
                    <td></td>
                </tr>
            </table>
            <strong
                style={{
                    display: 'block',
                    textAlign: 'left',
                    marginTop: '25px',
                    marginBottom: '25px',
                }}
            >
                *Functional Defects
            </strong>

            <ul>
                <li>Bad Digitzer</li>
                <li>Broken Volume Control</li>
                <li>Broken Mute Button</li>
                <li>Bad LCD</li>
                <li>Broken Power Button</li>
                <li>Broken Home Button</li>
                <li>Defective Charging Port</li>
                <li>Bad Battery</li>
                <li>Broken Headphone Jack</li>
                <li>Broken Camera</li>
                <li>Non-Functioning Audio</li>
                <li>Non-Functioning Wifi</li>
            </ul>

            <strong
                style={{
                    display: 'block',
                    marginBottom: '25px',
                    textAlign: 'center',
                    padding: '10px',
                    border: '0',
                    fontSize: '22px',
                    borderTop: '3px #0057b8',
                    borderBottom: '3px #0057b8',
                    textTransform: 'capitalize',
                    fontWeight: '700',
                    borderStyle: 'double',
                }}
            >
                Device Type: <u>MacBooks</u>
            </strong>
            <b style={{ display: 'block', marginBottom: '10px' }}>Packaging & Labeling:</b>
            <p>
                All MacBook Packaging is retail ready, white boxed and kitted. Diamond Assets
                utilizes a standard serial number barcode generated on each label. A product
                identifier is also located on our labeling. Additionally, UPC’s can be applied if
                necessary.
            </p>
            <b style={{ display: 'block', marginBottom: '25px' }}>Packaging Photos:</b>

            <figure>
                <img
                    style={{
                        width: '200px',
                        height: ' 200px',
                        objectFit: 'contain',
                        marginRight: '15px',
                    }}
                    src="./assets/images/mac1.PNG"
                    alt="Packing Box"
                />
                <img
                    style={{
                        width: '200px',
                        height: ' 200px',
                        objectFit: 'contain',
                        marginRight: '15px',
                    }}
                    src="./assets/images/mac2.PNG"
                    alt="Packing Box"
                />
                <img
                    style={{
                        width: '200px',
                        height: ' 200px',
                        objectFit: 'contain',
                        marginRight: '15px',
                    }}
                    src="./assets/images/mac3.PNG"
                    alt="Packing Box"
                />
            </figure>

            <b style={{ display: 'block', marginBottom: '15px' }}>
                Box Dimensions, Weights, and Palletizing:
            </b>
            <p style={{ marginBottom: '0px' }}>Height:3.75”</p>
            <p style={{ marginBottom: '0px' }}>Width:10”</p>
            <p style={{ marginBottom: '0px' }}>Length:17.5”</p>

            <p style={{ marginTop: '10px' }}>Completed Weight:7lbs</p>

            <strong style={{ display: 'block', textAlign: 'center', marginBottom: '25px' }}>
                MacBook Grading Criteria
            </strong>
            <table className="table" Border="1" style={{ boderColor: '#000' }}>
                <tr>
                    <th width="150px">Product Grade</th>
                    <th>Grading Description</th>
                </tr>
                <tr>
                    <td>Grade A:</td>
                    <td>
                        Device may contain light scratching on screen or casing, light wear. No
                        Dented corner. No worn or faded keys
                    </td>
                </tr>
                <tr>
                    <td>Grade B:</td>
                    <td>
                        Light scratch(es) on screen, minor dented corners, minor knick(s) or
                        blemish(es), light Wear. Two or less light dead pixels. No worn or faded
                        keys.
                    </td>
                </tr>
                <tr>
                    <td>Grade C:</td>
                    <td>
                        Moderate scratch(es) on screen. Moderate dented corner(s). Multiple knicks
                        and/or blemishes. Multiple dead pixels. Moderate wear markings. Cracked
                        Hinge. Worn or faded keys.
                    </td>
                </tr>
                <tr>
                    <td>Grade D:</td>
                    <td>
                        Deep Scratch(es) on screen. Major dented corners. Major dead pixels. Severe
                        Wear. Unit is functional
                    </td>
                </tr>
                <tr>
                    <td>Grade F:</td>
                    <td>Non-Functional or *functionally defective device</td>
                </tr>
                <tr>
                    <td>**Repair Grade</td>
                    <td></td>
                </tr>
            </table>
            <strong
                style={{
                    display: 'block',
                    textAlign: 'left',
                    marginTop: '25px',
                    marginBottom: '25px',
                }}
            >
                *Functional Defects
            </strong>

            <ul>
                <li>Swollen Battery</li>
                <li>Consumed Battery</li>
                <li>Defective Superdrive</li>
                <li>Damaged/Defective LCD</li>
                <li>Damaged/Missing Keys</li>
                <li>Logic Board Failure</li>
                <li>Damaged/Defective Trackpad</li>
                <li>Damaged/Defective Power Adapted</li>
                <li>Damaged/Defective Ports</li>
                <li>Defective iSight Camera</li>
                <li>Missing Screws</li>
                <li>Missing/Defective RAM</li>
                <li>Non Functioning Audio</li>
                <li>Non Functioning Wifi</li>
            </ul>
        </div>
    )
}
