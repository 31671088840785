import React, { useState, useContext, useEffect } from 'react'
import { Button, Divider, ListItem } from '@material-ui/core'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import CustomInput from '../../atoms/Inputs/CustomInput'
import './SearchNav.css'
import SearchIcon from '@material-ui/icons/Search'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import { handleRedirectInternal } from '../../../common/components'
import ReactHtmlParser from 'react-html-parser'
import AuthContext from '../../../context/auth/authContext'
import CommonContext from '../../../context/common/commonContext'
import ProductContext from '../../../context/product/productContext'

const SearchNav = (props) => {
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const { pathname } = useLocation()
    const [categoryAnchor, setCategoryAnchor] = useState(null)
    const [conditionAnchor, setConditionAnchor] = useState(null)
    const [categoryMenu, setCategoryMenu] = useState([])
    const [conditionMenu, setConditionMenu] = useState([])

    const history = useHistory()
    const { phrase, staticPages, language, getSitemap, setSearchValue, searchValue } =
        useContext(CommonContext)
    const { dispatchSelectedCategories } = useContext(ProductContext)

    useEffect(async () => {
        // get static content like categories, conditions
        await getSitemap()
    }, [language])

    useEffect(() => {
        // category loaded
        if (staticPages.categories) {
            let categories = staticPages.categories.map((cat) => {
                return {
                    label: phrase[cat.language_var] ? phrase[cat.language_var] : cat.name,
                    id: cat.id,
                }
            })
            setCategoryMenu(categories)
        }
        // conditions loaded
        if (staticPages.condition) {
            //  {$_phrase.{"`$val.language_var`_cond_content"}}
            let conditions = staticPages.condition.map((cond) => {
                return {
                    title: phrase[cond.language_var],
                    info: phrase[`${cond.language_var}_cond_content`],
                    id: cond.id,
                }
            })
            setConditionMenu(conditions)
        }
    }, [staticPages])

    const setCategoryOpen = (event) => {
        setCategoryAnchor(event.currentTarget)
    }

    // category clicked
    const setCategoryClose = (type, data) => (event) => {
        if (typeof type !== 'undefined') {
            if (typeof data !== 'undefined') {
                if (props.searchFilterFunc) {
                    props.searchFilterFunc({
                        id: 'category' + data.id,
                        type,
                        name: data.label,
                        filterBy: data.id,
                    })
                }
                // not on search page
                else {
                    let selectedCatPayload = []
                    selectedCatPayload.push({
                        id: 'category' + data.id,
                        name: data.label,
                        type: 'category',
                        filterBy: data.id,
                    })
                    dispatchSelectedCategories(selectedCatPayload)
                    history.push('/search')
                }
            }
        }
        setCategoryAnchor(null)
    }

    const setConditionOpen = (event) => {
        setConditionAnchor(event.currentTarget)
    }

    // condition clicked
    const setConditionClose = (type, data) => (event) => {
        if (typeof type !== 'undefined') {
            if (typeof data !== 'undefined') {
                if (props.searchFilterFunc) {
                    props.searchFilterFunc({
                        id: 'itemcondition' + data.id,
                        type,
                        name: data.title,
                        filterBy: data.id,
                    })
                }
                // not on search page
                else {
                    let selectedConPayload = []
                    selectedConPayload.push({
                        id: 'itemcondition' + data.id,
                        name: data.title,
                        type: 'condition',
                        filterBy: data.id,
                    })
                    dispatchSelectedCategories(selectedConPayload)
                    history.push('/search')
                }
            }
        }
        setConditionAnchor(null)
    }

    const LightTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }))(Tooltip)

    const newlyAddedClick = () => {
        let eve = {
            target: {
                value: 2,
            },
        }
        if (props.sortByChange) {
            props.sortByChange(eve)
        }
        // not on search page
        else {
            setSearchValue({ ...searchValue, orderby: 2 })
            history.push('/search')
        }
    }

    const handleSearchBarValue = (event) => {
        let { key } = event
        let { value } = event.target
        setSearchValue({ ...searchValue, searchbar: value })
        if (key === 'Enter') {
            history.push('/search')
        }
    }

    // user hit on search icon on top bar
    const searchBarSubmit = () => {
        setSearchValue({ ...searchValue, searchbar: searchValue.searchbar })
        history.push('/search')
    }

    return (
        <>
            <CustomInput
                label={phrase.search_inventory}
                size="small"
                placeholder={phrase.search_inventory}
                defaultValue={
                    props.searchBarValue
                        ? props.searchBarValue
                        : searchValue.searchbar
                        ? searchValue.searchbar
                        : ''
                }
                value={props.searchBarValue}
                onChange={props.searchInv ? props.searchInv : handleSearchBarValue}
                onKeyDown={props.searchInv ? props.searchInv : handleSearchBarValue}
            />
            <Button
                type="button"
                onClick={props.searchBarSubmit ? props.searchBarSubmit : searchBarSubmit}
                className="search-button"
            >
                <SearchIcon />
            </Button>
        </>
    )
}

export default SearchNav
