import React, { useReducer } from 'react'
import AuctionContext from './auctionContext'
import AuctionReducer from './auctionReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { GET_ALL_SEARCH, RESPONSE_STATUS, CLEAR_RESPONSE } from './auctionTypes'

const AuctionState = (props) => {
    const initialState = {
        search_allauctions: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AuctionReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllAuctionProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'auction')])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AuctionContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                search_allauctions: state.search_allauctions,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllAuctionProducts,
            }}
        >
            {props.children}
        </AuctionContext.Provider>
    )
}

export default AuctionState
