import { Divider, ListItem } from '@material-ui/core'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'

// context
import CommonContext from '../../context/common/commonContext'

const SideNav = () => {
    const { phrase } = useContext(CommonContext)
    return (
        <div className="sideNav">
            <h5>My offers</h5>
            <ul>
                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard_offer/buy_now">
                        Active Offer
                    </NavLink>
                </ListItem>

                <Divider />

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard_offer/make_offer">
                        Accepted Offer
                    </NavLink>
                </ListItem>

                <Divider />

                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard_offer/make_offer_declined">
                        Declined Offer
                    </NavLink>
                </ListItem>
                <Divider />
                <ListItem button>
                    <NavLink activeClassName="active" to="/dashboard_offer/watchlist">
                        Watch List
                    </NavLink>
                </ListItem>
            </ul>
        </div>
    )
}

export default SideNav
