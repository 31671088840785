import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import openSocket from 'socket.io-client'
import FilterPanel from '../../components/organisms/FilterPanel/index'
import GridView from '../../components/molecules/ProductCard/GridView'
import './Search.css'
import CustomSelect from '../../components/atoms/Inputs/CustomSelect'
import { Button, MenuItem } from '@material-ui/core'
import ListView from '../../components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
// import ProductView from '../../components/organisms/ProductView'
import ProductContext from '../../context/product/productContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import CommonContext from '../../context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import { useFormik } from 'formik'
import Banner from '../../components/molecules/Banner'
import { messageHandler } from '../Common/socketHandler'
import { useCustomMediaQuery } from '../../common/components'
import SearchNav from '../../components/molecules/SearchNav'
import Loader from '../../components/molecules/Loader'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { perPageOptions, sortOptions } from '../../Utils/filterList'
import ProductTable from '../../components/organisms/ProductTable'
import Layout from '../../components/templates/Layout'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import DashboardOffers from '../Dashboard/index_offers'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const Search = (props) => {
    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const { setSearchValue, searchValue, phrase } = commonContext
    const { type } = useParams()
    const params = new URLSearchParams(window.location.search.substring(1))
    const [completed, setCompleted] = useState(type === 'completed')
    // useEffect(() => {
    //     setCompleted(type === 'completed')
    // }, [type])
    const classes = useStyles()
    const history = useHistory()
    const {
        search_allproducts,
        getAllSearchProducts,
        responseStatus,
        getProjectsMain,
        projectsMain,
        getProjectsCompleted,
        projectCompleted,
        dispatchSelectedCategories,
        selectedCategories,
        projmainloading,
        projcompletedloading,
    } = productContext

    const { user, isAuthenticated, saveSearch } = authContext

    const { setAlert } = alertContext

    const [auctionView, setAuctionView] = useState('Grid')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        left: false,
        data: {},
    })
    let [viewProduct, setViewProduct] = useState([])
    let [projectsMainCompleted, setProjectsMainCompleted] = useState([])
    const [projectsMainPayload, setProjectsMainPayload] = useState({
        ajax: 1,
        sh_limit: 15,
        orderby: searchValue.orderby ? searchValue.orderby : 3,
        searchbar: searchValue.searchbar ? searchValue.searchbar : '',
        page: 1,
        pagecom: 1,
        condition: '',
        lottype: '',
        category: props.category ? props.category : '',
        view: '',
        listfmt: '',
        listfmtnew: '',
        reglist: '',
        countrylist: '',
        similar_id: props.similar_id ? props.similar_id : '',
    })
    const headData = ['Title', 'Category', 'Qty', 'Price', 'Action']
    // search form for ended auctions
    const [endedProjectsPayload, setEndedProjectsPayload] = useState({
        ajax: 1,
        sh_limit: completed ? 15 : 6,
        orderby: searchValue.orderby ? searchValue.orderby : 3,
        searchbar: searchValue.searchbar ? searchValue.searchbar : '',
        page: 1,
        pagecom: 1,
        condition: '',
        lottype: '',
        category: '',
        view: '',
        listfmt: '',
        listfmtnew: '',
        reglist: '',
        countrylist: '',
    })
    let [pageOptionsSelected, setPageOptionsSelected] = useState({
        value: 15,
    })
    // 3 is default for Price Low - High
    let [sortShowSelected, setShortshowSelected] = useState({
        value: searchValue.orderby ? searchValue.orderby : 3,
    })
    const [bannerList, setBannerList] = useState([])

    const pageOptions = [
        {
            value: '15',
            show: '15',
        },
        {
            value: '30',
            show: '30',
        },
        {
            value: '45',
            show: '45',
        },
    ]

    const sortShow = [
        {
            value: 2,
            show: phrase.newly_added,
        },
        {
            value: 3,
            show: phrase.price_low_high,
        },
        {
            value: 4,
            show: phrase.price_high_low,
        },
        {
            value: 5,
            show: 'Qty Low - High',
        },
        {
            value: 6,
            show: 'Qty High - Low',
        },
    ]
    const toggleBottomDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }

        setState({ ...state, [anchor]: open })
    }
    const formik = useFormik({
        initialValues: {
            searchbar: '',
        },
    })

    // user hit keys on top keyword search input
    const handleKeyDown = (event) => {
        console.log(event)
        let { key } = event
        let { value } = event.target
        setSearchValue({ ...searchValue, searchbar: value })
        if (key === 'Enter') {
            let payload = {
                ...projectsMainPayload,
                searchbar: value,
            }
            setProjectsMainPayload(payload)
            setEndedProjectsPayload(payload)
        }
    }

    // user hit on search icon on top bar
    const searchBarSubmit = () => {
        //console.log('SEARCH BAR: ', searchbarValue)
        let payload = {
            ...projectsMainPayload,
            searchbar: searchValue.searchbar,
        }
        setProjectsMainPayload(payload)
        setEndedProjectsPayload(payload)
    }

    useEffect(() => {
        document.title = global.site_title + ' | Search'
        getAllSearchProducts({}, 'search')
    }, [])

    useEffect(() => {
        if (
            typeof search_allproducts.banner !== 'undefined' &&
            search_allproducts.banner.length > 0
        ) {
            setBannerList(search_allproducts.banner)
        }
    }, [search_allproducts])

    const initialLoad = useRef(true)
    useEffect(() => {
        // this is because, this useffect will be called again once the [selectedcategories] useeffect is invoked
        if (initialLoad.current) {
            initialLoad.current = false
        } else {
            if (Object.keys(projectsMainPayload).length) {
                console.log('projects main payload change________________', projectsMainPayload)
                getProjectsMain(projectsMainPayload)
                //getProjectsCompleted(projectsMainPayload)
            }
        }
        //  return () => {}
    }, [projectsMainPayload])

    const initialLoadEndedProjects = useRef(true)
    useEffect(() => {
        // this is because, this useffect will be called again once the [selectedcategories] useeffect is invoked
        if (initialLoadEndedProjects.current) {
            initialLoadEndedProjects.current = false
        } else {
            if (Object.keys(endedProjectsPayload).length) {
                //console.log('projects main payload change________________', projectsMainPayload)
                if (completed) {
                    getProjectsCompleted(endedProjectsPayload)
                } else {
                    getProjectsCompleted({ ...endedProjectsPayload, sh_limit: 6 })
                }
            }
        }
    }, [endedProjectsPayload])

    // loaded active auctions
    useEffect(() => {
        if (
            typeof projectsMain.projectsMainArr !== 'undefined' &&
            Array.isArray(projectsMain.projectsMainArr)
        ) {
            setViewProduct(projectsMain.projectsMainArr)
        }
    }, [projectsMain])

    // loaded ended auctions
    useEffect(() => {
        if (typeof projectCompleted !== 'undefined' && Object.keys(projectCompleted).length) {
            if (typeof projectCompleted.projcompletedArr !== 'undefined') {
                //console.log('ended auction loaded_______', projectCompleted)
                setProjectsMainCompleted(projectCompleted.projcompletedArr)
            }
        }
    }, [projectCompleted])

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'watchlist') {
                handler(
                    {
                        id: responseStatus.data.project_id,
                        status: responseStatus.data.status,
                    },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('drop_price', (data) => {
            handler(data, 'drop_price')
        })

        socket.on('cancelbidemitted', (data) => {
            cancelBidHandler(data)
        })
        socket.on('bid_extended', function (data) {
            console.log(data, 'data++++++')
            handler(data, 'bid_extended')
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })

            socket.off('drop_price', (data) => {
                handler(data, 'drop_price')
            })
            socket.off('cancelbidemitted', (data) => {
                cancelBidHandler(data)
            })
            socket.off('bid_extended', function (data) {
                handler(data, 'bid_extended')
            })
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const fromArrToStr = (arr, type, isName, isregion) => {
        return arr
            .filter((ele) => {
                if (isregion) return ele.type === type && typeof ele.country === 'undefined'
                return ele.type === type
            })
            .map((ele) => {
                return ele.filterBy
                //return isName ? ele.name : ele.id
            })
            .join()
    }

    // page changed - active auctions
    const onHandlePage = (event, value) => {
        setProjectsMainPayload({ ...projectsMainPayload, page: value })
        window.scrollTo(0, 0)
    }

    // page changed - ended auctions
    const onHandlePageEndedAuctions = (event, value) => {
        setEndedProjectsPayload({ ...endedProjectsPayload, page: value, pagecom: value })
    }

    useEffect(() => {
        if (typeof projectsMainPayload !== 'undefined') {
            if (Object.keys(projectsMainPayload).length) {
                if (Array.isArray(selectedCategories) && selectedCategories.length) {
                    let category = fromArrToStr(selectedCategories, 'category', 0, 0)
                    let sub_category = fromArrToStr(selectedCategories, 'sub_category', 0, 0)
                    let condition = fromArrToStr(selectedCategories, 'condition', 0, 0)
                    let listfmt = fromArrToStr(selectedCategories, 'listing', 1, 0)
                    let reglist = fromArrToStr(selectedCategories, 'region', 0, 1)
                    let countrylist = fromArrToStr(selectedCategories, 'region', 0, 0)
                    let lottype = fromArrToStr(selectedCategories, 'lot', 1, 0)
                    let payload = {
                        ...projectsMainPayload,
                        searchbar: searchValue.searchbar ? searchValue.searchbar : '',
                        orderby: sortShowSelected.value,
                        category: category,
                        sub_category,
                        condition: condition,
                        listfmt: listfmt,
                        reglist: reglist,
                        countrylist: countrylist,
                        lottype: lottype,
                    }
                    let payloadEndedAuctions = {
                        ...endedProjectsPayload,
                        searchbar: searchValue.searchbar ? searchValue.searchbar : '',
                        orderby: sortShowSelected.value,
                        category: category,
                        sub_category,
                        condition: condition,
                        listfmt: listfmt,
                        reglist: reglist,
                        countrylist: countrylist,
                        lottype: lottype,
                    }
                    //console.log('payload____________', payload)
                    setProjectsMainPayload(payload)
                    setEndedProjectsPayload(payloadEndedAuctions)
                }
                // no filter selected
                else {
                    console.log('No filter selected')
                    let payload = {
                        ...projectsMainPayload,
                        searchbar: searchValue.searchbar ? searchValue.searchbar : '',
                        orderby: sortShowSelected.value,
                        category: props.category ? props.category : '',
                        similar_id: props.similar_id ? props.similar_id : '',
                        sub_category: '',
                        condition: '',
                        listfmt: '',
                        reglist: '',
                        countrylist: '',
                        lottype: '',
                    }
                    let payloadEndedAuctions = {
                        ...endedProjectsPayload,
                        searchbar: searchValue.searchbar ? searchValue.searchbar : '',
                        orderby: sortShowSelected.value,
                        category: props.category ? props.category : '',
                        similar_id: props.similar_id ? props.similar_id : '',
                        sub_category: '',
                        condition: '',
                        listfmt: '',
                        reglist: '',
                        countrylist: '',
                        lottype: '',
                    }
                    setProjectsMainPayload(payload)
                    setEndedProjectsPayload(payloadEndedAuctions)
                }
            }
        }
    }, [selectedCategories])

    // show results - per page value changed
    const pageOptionIntialLoad = useRef(true)
    useEffect(() => {
        if (
            typeof pageOptionsSelected.value !== 'undefined' &&
            pageOptionIntialLoad.current == false
        ) {
            let payload = {
                ...projectsMainPayload,
                sh_limit: pageOptionsSelected.value,
            }
            let payloadEndedAuctions = {
                ...endedProjectsPayload,
                sh_limit: pageOptionsSelected.value,
            }
            setProjectsMainPayload(payload)
            setEndedProjectsPayload(payloadEndedAuctions)
        }
        // don't update the main payload initially
        else {
            pageOptionIntialLoad.current = false
        }
    }, [pageOptionsSelected.value])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const clearSearchFilter = () => {
        setSearch({
            ...search,
            limit: 12,
            page: 1,
            orderby: '',
            location: [],
            state: [],
            city: [],
            category: [],
            condition: [],
            radius: '',
            price: '',
            enddate: null,
            fromprice: 0,
            toprice: 0,
            auctionid: '',
            searchbar: '',
        })
        formik.values.searchbar = ''
    }

    const searchFilterFunc = ({ id, name, type, country, filterBy }, isclearAll) => {
        if (isclearAll) {
            setShortshowSelected({ value: 3 }) // set the sort by to default
            dispatchSelectedCategories([])
            setSearchValue({ ...searchValue, searchbar: '' })
            return
        }
        let selcat = [...selectedCategories]
        const selectedInd = selcat.findIndex((ele) => ele.id.toString() === id.toString())
        console.log(id, 'selectedInd', selcat)
        if (selectedInd >= 0) {
            selcat.splice(selectedInd, 1)
        } else {
            selcat.push({
                id,
                name,
                type: type,
                country,
                filterBy: filterBy,
            })
        }
        dispatchSelectedCategories(selcat)
    }

    const perPageChange = (event) => {
        let { value } = event.target
        let payload = {
            ...projectsMainPayload,
            sh_limit: value,
            page: 1,
        }
        let payloadEndedAuctions = {
            ...endedProjectsPayload,
            sh_limit: value,
            page: 1,
        }
        setProjectsMainPayload(payload)
        setEndedProjectsPayload(payloadEndedAuctions)
        setPageOptionsSelected({ value })
    }

    // sort by option changed
    const sortByChange = (event) => {
        let { value } = event.target
        let payload = {
            ...projectsMainPayload,
            orderby: value,
        }
        let payloadEndedAuctions = {
            ...endedProjectsPayload,
            orderby: value,
        }
        setProjectsMainPayload(payload)
        setEndedProjectsPayload(payloadEndedAuctions)
        setShortshowSelected({ value })
    }
    return (
        <Layout
            showHeader={props.from === 'product_view' ? 'false' : 'true'}
            showFooter={props.from === 'product_view' ? 'false' : 'true'}
        >
            <div className="customContainer search-container">
                <h1 className="deals-head">
                    {props.from === 'product_view' ? 'Similar' : ''} Deals
                </h1>
                <div className="search-wrapper">
                    {props.from !== 'product_view' ? (
                        <>
                            {useCustomMediaQuery('(min-width: 1025px)') ? (
                                <>
                                    <div className="respSort">
                                        {/* <SideNav /> */}
                                        <div className="deskFilter">
                                            <FilterPanel
                                                clearSearch={clearSearchFilter}
                                                //search={search}
                                                page="search"
                                                from={1}
                                                searchFilterFunc={searchFilterFunc}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <Drawer
                                    anchor="left"
                                    open={state['left']}
                                    className="responsiveFilterDrawer"
                                    onClose={toggleBottomDrawer('left', false)}
                                    onOpen={toggleBottomDrawer('left', true)}
                                >
                                    <Button
                                        className="headDrawerClose justify-content-end"
                                        onClick={toggleBottomDrawer('left', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                    <div
                                        className={clsx(classes.list, 'left')}
                                        role="presentation"
                                        // onClick={toggleBottomDrawer('left', false)}
                                        onKeyDown={toggleBottomDrawer('left', false)}
                                    >
                                        <div className="dashboardFilter dashboardFilChange">
                                            {/* <SideNav /> */}
                                            <FilterPanel
                                                clearSearch={clearSearchFilter}
                                                //search={search}
                                                page="search"
                                                from={1}
                                                searchFilterFunc={searchFilterFunc}
                                            />
                                        </div>
                                    </div>
                                </Drawer>
                            )}
                        </>
                    ) : null}

                    {/* {props.from !== 'product_view' && (
                        <div className="search-left">
                            <FilterPanel
                                clearSearch={clearSearchFilter}
                                //search={search}
                                page="search"
                                from={1}
                                searchFilterFunc={searchFilterFunc}
                            />
                        </div>
                    )} */}
                    <div className={props.from === 'product_view' ? '' : 'search-right'}>
                        {props.from !== 'product_view' && (
                            <div className="search-filters-top">
                                <div className="sft-left">
                                    <Button
                                        className="toggleRespDrawer"
                                        variant="outlined"
                                        onClick={toggleBottomDrawer('left', true)}
                                    >
                                        <span className="material-icons">tune</span>
                                        {phrase.filter_by}
                                    </Button>
                                    <CustomSelect
                                        label="Sort by"
                                        name="orderby"
                                        size="small"
                                        onChange={sortByChange}
                                        value={sortShowSelected.value}
                                    >
                                        {sortShow.map((opt, optindex) => (
                                            <option key={optindex} value={opt.value}>
                                                {opt.show}
                                            </option>
                                        ))}
                                    </CustomSelect>
                                    <CustomSelect
                                        label="Results Per Page"
                                        name="resultsPerPage"
                                        size="small"
                                        onChange={perPageChange}
                                        value={pageOptionsSelected.value}
                                    >
                                        {pageOptions.map((opt, optindex) => (
                                            <option key={optindex} value={opt.value}>
                                                {opt.show}
                                            </option>
                                        ))}
                                    </CustomSelect>
                                </div>
                                <div className="sft-right">
                                    {/* <CustomInput
                                    label="Search deal titles..."
                                    size="regular"
                                    placeholder="Search deal titles..."
                                />
                                <CustomInput
                                    label="Show me all deals"
                                    size="regular"
                                    placeholder="Show me all deals"
                                />
                                <Button className="search-button">
                                    <span className="material-icons">search</span>
                                </Button> */}
                                    <SearchNav
                                        searchFilterFunc={searchFilterFunc}
                                        sortByChange={sortByChange}
                                        searchBarValue={searchValue.searchbar}
                                        searchBarSubmit={searchBarSubmit}
                                        searchInv={handleKeyDown}
                                        completed={completed}
                                    />
                                </div>
                            </div>
                        )}
                        {location.pathname === '/search/my_offers' ? (
                            <div>
                                <DashboardOffers type="buy_now" />
                            </div>
                        ) : location.pathname === '/search/accepted_offers' ? (
                            <div>
                                <DashboardOffers type="make_offer" />
                            </div>
                        ) : location.pathname === '/search/declined_offers' ? (
                            <div>
                                <DashboardOffers type="make_offer_declined" />
                            </div>
                        ) : (
                            <>
                                {projmainloading ? (
                                    <Loader />
                                ) : (
                                    <ProductTable headData={headData} productData={viewProduct} />
                                )}
                                <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                                    <Pagination
                                        count={Math.ceil(
                                            projectsMain.totalRecords /
                                                projectsMainPayload.sh_limit,
                                        )}
                                        page={projectsMainPayload.page}
                                        onChange={onHandlePage}
                                        siblingCount={3}
                                        showFirstButton
                                        showLastButton
                                        boundaryCount={2}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default Search
