import { GET_ALL_SEARCH, RESPONSE_STATUS, CLEAR_RESPONSE } from './auctionTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_SEARCH:
            return {
                ...state,
                search_allauctions: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
