import { Button, Divider } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import './Message.css'
import CustomInput from '../../components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import Popup from '../../components/organisms/Popup'
import moment from 'moment'

function Conversations(props) {
    const [message, setMessage] = useState('')
    const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false)
    const [messageId, setMessageId] = useState('')
    const [uploadedFile, setUploadedFile] = useState()

    useEffect(() => {
        scrollToBottom()
    }, [props.conversations])

    const messageEnd = React.createRef()

    const scrollToBottom = () => {
        if (messageEnd != '') {
            messageEnd.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const messageSubmitHandler = (e) => {
        e.preventDefault()
        if (message != '') {
            setUploadedFile('')
            props.handleMessageSubmit(
                message,
                props.conversations.sellerid,
                props.conversations.projectid,
                uploadedFile,
            )
            setMessage('')
        }
    }

    const fileChangeHanlder = (e) => {
        setUploadedFile(e.target.files[0])
    }

    return (
        <div className="conversations">
            <div className="conversationsCnt">
                {props.conversations.actualmessages ? (
                    props.conversations.actualmessages.map((conversation, index) => (
                        <div key={index}>
                            <div className="dateSent d-flex justify-content-between align-items-center">
                                <Divider />
                                <span>{conversation.date}</span>
                                <Divider />
                            </div>
                            {conversation.datearray.map((conversationForDate, index) => (
                                <div key={index}>
                                    {conversationForDate.from_id == props.conversations.sellerid ? (
                                        <div className="messageRow d-flex justify-content-start">
                                            <div className="messageReceived">
                                                {conversationForDate.image > 0 ? (
                                                    <div className="imageConversation">
                                                        <a
                                                            href={
                                                                global.site_url +
                                                                '/uploads/messages/' +
                                                                conversationForDate.img_basename
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img
                                                                src={
                                                                    global.site_url +
                                                                    '/uploads/messages/' +
                                                                    conversationForDate.img_basename
                                                                }
                                                            />
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: conversationForDate.message,
                                                        }}
                                                    ></p>
                                                )}
                                                <span className="timeStamp">
                                                    {moment(conversationForDate.date_sent).format(
                                                        'hh:mm A',
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-end">
                                            <div className="messageSent">
                                                {conversationForDate.image > 0 ? (
                                                    <div className="imageConversation">
                                                        <a
                                                            href={
                                                                global.site_url +
                                                                '/uploads/messages/' +
                                                                conversationForDate.img_basename
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img
                                                                src={
                                                                    global.site_url +
                                                                    '/uploads/messages/' +
                                                                    conversationForDate.img_basename
                                                                }
                                                            />
                                                        </a>
                                                    </div>
                                                ) : (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: conversationForDate.message,
                                                        }}
                                                    ></p>
                                                )}
                                                <span className="timeStamp">
                                                    {moment(conversationForDate.date_sent).format(
                                                        'hh:mm A',
                                                    )}
                                                </span>
                                            </div>
                                            <Button
                                                className="deleteMsg"
                                                onClick={() => {
                                                    setMessageId(conversationForDate.id)
                                                    setDeleteConfirmationPopup(true)
                                                }}
                                            >
                                                <span className="material-icons">delete</span>
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div className="noSelection">
                        <div className="text-center">
                            <span className="material-icons">question_answer</span>
                            <h4>No Conversation Selected</h4>
                            <h6>Select a conversation to view messages</h6>
                        </div>
                    </div>
                )}
                <div style={{ float: 'left', clear: 'both' }} ref={messageEnd}></div>
            </div>
            {uploadedFile ? (
                <div className="uploadedImage">
                    <a
                        className="closeImage"
                        onClick={() => {
                            setUploadedFile('')
                        }}
                    >
                        <span className="material-icons">highlight_off</span>
                    </a>

                    <img
                        src={URL.createObjectURL(uploadedFile)}
                        className="img-thumbnail rounded float-right"
                    />
                </div>
            ) : (
                ''
            )}
            <div>
                <form
                    className="messageInput"
                    action=""
                    onSubmit={(e) => {
                        e.preventDefault()
                        messageSubmitHandler(e)
                    }}
                >
                    <CustomInput
                        label="Write a message..."
                        onChange={(e) => {
                            setMessage(e.target.value)
                        }}
                        value={message}
                    />
                    <div className="msgActBtn d-flex justify-content-between align-items-center">
                        <input
                            accept="image/*"
                            className="uploadInput"
                            id="contained-button-file"
                            type="file"
                            onChange={fileChangeHanlder}
                        />
                        <label htmlFor="contained-button-file">
                            <Button className="attachBtn" component="span">
                                <span className="material-icons">attach_file</span>
                            </Button>
                        </label>
                        {/* <PrimaryButton label="send" className="material-icons" type="submit" /> */}
                        <label htmlFor="contained-button-file">
                            <Button className="sendBtn" type="submit">
                                <span className="material-icons">send</span>
                            </Button>
                        </label>
                    </div>
                </form>
            </div>
            <Popup
                open={deleteConfirmationPopup}
                handleClose={() => setDeleteConfirmationPopup(false)}
                modaltitle={props.phrase.delete_confirmation}
                footer={
                    <>
                        <PrimaryButton
                            label={props.phrase.confirm}
                            onClick={() => {
                                props.handleMessageDelete(
                                    messageId,
                                    props.conversations.sellerid,
                                    props.conversations.projectid,
                                )
                                setDeleteConfirmationPopup(false)
                            }}
                        />
                        <SecondaryButton
                            label={props.phrase.cancel}
                            onClick={() => {
                                setDeleteConfirmationPopup(false)
                            }}
                        />
                    </>
                }
            >
                {props.phrase.confirm_delete_msg}
            </Popup>
        </div>
    )
}

export default Conversations
