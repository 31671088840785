import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import './cdialog.css'

export default function CustomDialog({ children, ...props }) {
    return (
        <Dialog open={props.open} onClose={props.handleClose} className="custom-dialog-wrapper">
            <DialogTitle>
                <img src="/assets/images/logo.png" className="cd-logo" />
                <span className="material-icons cd-close" onClick={props.handleClose}>
                    close
                </span>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    )
}
