//LOGO
export const logo = '/assets/images/logo.png'
export const logo2 = '/assets/svg/logo2.svg'
export const png_logo = '/assets/images/logo.png'
export const siteName = 'LedMax'

class langObj {
    constructor(show, value) {
        this.show = show
        this.value = value
    }
    getLangObj() {
        return this
    }
}
export const languageArr = [
    new langObj('English', 'English'),
    new langObj('Mandarin', 'Mandarin'),
    new langObj('French', 'French'),
    new langObj('Spanish', 'Spanish'),
    new langObj('Portugese', 'Portugese'),
    new langObj('BrazilPortugese', 'BrazilPortugese'),
    new langObj('German', 'German'),
]
