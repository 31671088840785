import React, { useEffect, useState } from 'react'
import { Badge, ListItem } from '@material-ui/core'
import moment from 'moment'
import './Message.css'

function ActiveSubjects(props) {
    const [firstSubject, setFirstSubject] = useState()

    const assignFirstSubject = (element, index) => {
        if (element && index === 0) {
            setFirstSubject(element)
        }
    }

    useEffect(() => {
        if (firstSubject) {
            firstSubject.click()
        }
    }, [firstSubject, props.isArchive])

    return (
        <ul className="activeSubjects">
            {console.log(props.sellerMessages.projectmessages, 'props.sellers')}

            {props.sellerMessages.projectmessages ? (
                props.sellerMessages.projectmessages.map((projectMessage, index) => (
                    <ListItem
                        button
                        key={index}
                        onClick={(e) =>
                            props.onClick(
                                e,
                                index,
                                props.sellerMessages.sellerid,
                                props.sellerMessages.messageunreadcount -
                                    projectMessage.unreadcount,
                                projectMessage.project_id,
                            )
                        }
                        ref={(element) => assignFirstSubject(element, index)}
                    >
                        <div className="d-flex justify-content-between flex-wrap w-100">
                            <div className="subjectContent d-flex justify-content-between w-100">
                                <h4 className="asTitle">{projectMessage.title}</h4>
                                <h6>{moment(projectMessage.date_sent).format('DD-MM-YYYY')}</h6>
                            </div>
                            <div className="asContent d-flex justify-content-between w-100">
                                <h5 className="asSubject">
                                    {projectMessage.conversationcount +
                                        ' ' +
                                        props.phrase.conversations}
                                </h5>
                                <div className="d-flex justify-content-end">
                                    {props.isArchive ? (
                                        <a
                                            onClick={(e) => {
                                                props.handleUnarchiveButtonClick(
                                                    props.sellerMessages.sellerid,
                                                    projectMessage.project_id,
                                                )
                                                e.stopPropagation()
                                            }}
                                        >
                                            <span className="material-icons">unarchive</span>
                                        </a>
                                    ) : (
                                        <a
                                            onClick={(e) => {
                                                props.handleArchiveButtonClick(
                                                    props.sellerMessages.sellerid,
                                                    projectMessage.project_id,
                                                )
                                                e.stopPropagation()
                                            }}
                                        >
                                            <span className="material-icons">archive</span>
                                        </a>
                                    )}

                                    {projectMessage.unreadcount > 0 ? (
                                        <Badge badgeContent={projectMessage.unreadcount}></Badge>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    </ListItem>
                ))
            ) : (
                <div className="noSelection seller">
                    <div className="text-center">
                        <span className="material-icons">person</span>
                        <h4>No Seller Selected</h4>
                        <h6>Select a seller to view conversations</h6>
                    </div>
                </div>
            )}
        </ul>
    )
}

export default ActiveSubjects
