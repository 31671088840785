import {
    GET_ALL_INVOICE,
    GET_ALL_SEARCH,
    GET_ALL_SIMILAR,
    GET_ALL_USER_INVOICES,
    GET_ALL_DASHBOARD,
    RESPONSE_STATUS,
    GET_ALL_BID_HISTORY,
    GET_ALL_ACTIVE_SORTS,
    CLEAR_RESPONSE,
    PROJECTSMAIN,
    PROJECTSCOMPLETED,
    SELECTEDCATEGORIES,
    PROJECTSMAINARRLOADING,
    PROJECTSCOMPLETEDARRLOADING,
    DASHBOARD_LOADING,
    WISHLISTARR,
} from './productTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_SEARCH:
            return {
                ...state,
                search_allproducts: action.payload,
            }
        case GET_ALL_BID_HISTORY:
            return {
                ...state,
                get_allbidhistory: action.payload,
            }
        case GET_ALL_USER_INVOICES:
            return {
                ...state,
                search_alluserinvoices: action.payload,
            }
        case GET_ALL_SIMILAR:
            return {
                ...state,
                search_allsimilar: action.payload,
            }
        case GET_ALL_INVOICE:
            return {
                ...state,
                search_allinvoiceproducts: action.payload,
            }
        case GET_ALL_ACTIVE_SORTS:
            return {
                ...state,
                active_sorts: action.payload,
            }
        case GET_ALL_DASHBOARD:
            return {
                ...state,
                search_alldashboardproducts: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case PROJECTSMAIN:
            return {
                ...state,
                projectsMain: action.payload,
            }
        case PROJECTSCOMPLETED:
            return {
                ...state,
                projectCompleted: action.payload,
            }
        case SELECTEDCATEGORIES:
            return {
                ...state,
                selectedCategories: action.payload,
            }
        case PROJECTSMAINARRLOADING:
            return {
                ...state,
                projmainloading: action.payload,
            }
        case PROJECTSCOMPLETEDARRLOADING:
            return {
                ...state,
                projcompletedloading: action.payload,
            }
        case DASHBOARD_LOADING:
            return {
                ...state,
                dashboardLoading: action.payload,
            }
        case WISHLISTARR:
            console.log(state.wishlistArr, ...action.payload, 'action.payload')
            let arr = state.wishlistArr
            let newValue = action.remove_id
            if (arr.indexOf(newValue) >= 0) {
                arr = arr.filter((val) => val !== newValue)
            }
            return {
                ...state,
                wishlistArr: [...new Set([...arr, ...action.payload])],
            }
        default:
            return state
    }
}
