import React, { useEffect, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../common/components'
import './Support.css'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import StaticPage from '../StaticPages'
import Layout from '../../components/templates/Layout'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

function Support() {
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [phoneNumber, setPhoneNumber] = useState('')
    const { postSupport } = useContext(CommonContext)
    let { language, getLangPhrase, phrase } = useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    const { user } = useContext(AuthContext)
    const formRef = useRef()
    const [loader, setLoader] = useState(false)
    useEffect(() => {
        getLangPhrase(language)
        document.title = global.site_title + ' | Support'
    }, [language])

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            formik.values.email = user.email
            formik.values.first_name = user.first_name
            formik.values.last_name = user.last_name
            formik.values.phone = user.phone
        }
    }, [user])

    const handleTabChange = (event, newValue) => {
        setValue(newValue)
    }
    const validationArray = Yup.object({
        email: Yup.string()
            .email(phrase.invalid_format)
            .required(`${phrase.enter} ${phrase.email_address}`),
        first_name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required(`${phrase.enter} ${phrase.first_name}`),
        last_name: Yup.string()
            .matches(/^[a-zA-Z\s]*$/g, 'The special characters and numbers are not allowed!')
            .max(20, 'Maximum 20 characters')
            .required(`${phrase.enter} ${phrase.last_name}`),
        //phone: Yup.string().required('Required Field'),
        subject: Yup.string().required(`${phrase.enter} ${phrase.subject}`),
        description: Yup.string().required(`${phrase.enter} ${phrase.description}`),
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            phone: '',
            subject: '',
            description: '',
            first_name: '',
            last_name: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            setLoader(true)
            let payload = {}
            payload['email'] = values['email']
            payload['first_name'] = values['first_name']
            payload['last_name'] = values['last_name']
            payload['phone'] = values['phone']
            payload['subject'] = values['subject']
            payload['decription'] = values['description']

            const status = await postSupport(payload)
            setLoader(false)
            if (status === 'success') {
                setAlert('Message Sent Successfully', 'success')
                values['email'] = ''
                values['first_name'] = ''
                values['last_name'] = ''
                values['subject'] = ''
                values['description'] = ''
                setPhoneNumber('')
            }
        },
    })

    useEffect(() => {
        const firstErrorFieldName = Object.keys(formik.errors)[0]

        if (firstErrorFieldName) {
            formRef.current[firstErrorFieldName].focus()
        }
    }, [formik.submitCount])

    const contactSupport = [
        {
            label: phrase.email_address,
            name: 'email',
            type: 'email',
            autoFocus: true,
            placeholder: `${phrase.enter} ${phrase.email_address}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.first_name}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.last_name}`,
            class: 'col-md-6 col-12',
            formik: formik,
        },
        {
            label: phrase.phone_number,
            placeholder: `${phrase.enter} ${phrase.phone_number}`,
            class: 'col-md-6 col-12',
            type: 'countrySelect',
            name: 'phone',
            defaultCountry: 'us',
            regions: 'america',
            formik: formik,
        },
        {
            label: phrase.subject,
            name: 'subject',
            type: 'text',
            placeholder: `${phrase.enter} ${phrase.subject}`,
            class: 'col-12',
            formik: formik,
        },
        {
            label: phrase.description,
            name: 'description',
            type: 'textarea',
            placeholder: `${phrase.enter} ${phrase.description}`,
            class: 'col-12',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="container-fluid">
                <h2 className="mt-4 pp-label">Support & FAQs</h2>
                <div className="support customTabs">
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleTabChange}
                            aria-label="simple tabs example"
                        >
                            <Tab label={phrase.contact_support} {...a11yProps(0)} />
                            <Tab label={phrase.frequently_asked_questions} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <form action="" onSubmit={formik.handleSubmit} ref={formRef}>
                            <div className="row">{Object.values(mapData(contactSupport))}</div>
                            <PrimaryButton label={phrase.submit} type="submit" disabled={loader} />
                        </form>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className="supportFaq">
                            <StaticPage page="FAQ_pallets" />
                        </div>
                    </TabPanel>
                </div>
            </div>
        </Layout>
    )
}

export default Support
