import React from 'react'
import { Link } from 'react-router-dom'
import './ErrorPage.css'

function ErrorPage() {
    return (
        <div className="errorPage">
            <span className="material-icons">warning</span>
            <h2>Something went wrong. We&apos;re sorry.</h2>
            <h5>
                Try to search or <Link to="">go to the homepage.</Link>
            </h5>
        </div>
    )
}

export default ErrorPage
