import React, { useEffect, useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import openSocket from 'socket.io-client'

// Components
import PrimaryButton from '../../components/atoms/PrimaryButton'
import Invoice from '../../components/molecules/Invoice'

// Context
import CommonContext from '../../context/common/commonContext'
import AlertContext from '../../context/alert/alertContext'
import AuthContext from '../../context/auth/authContext'

// Common Function
import { apiCall } from '../../common/api'
import { handleRedirectInternal } from '../../common/components'

const BuynowConfirm = (props) => {
    const history = useHistory()
    const { phrase } = useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    const { user } = useContext(AuthContext)

    // state
    const [invoiceData, setInvoiceData] = useState({})

    const invoiceDataRef = useRef(invoiceData)
    const userRef = useRef(user)

    const socketHandler = (data) => {
        // console.log('Socket Handler invoiceDataRef: ', invoiceDataRef.current)
        // console.log('Socket Handler UserRef: ', userRef.current)
        if (data.id == invoiceDataRef.current.id) {
            if (data.bpop_cbidder == userRef.current.id) {
                if (data.status == 'success') {
                    setAlert(phrase.order_completed, 'success')
                    handleRedirectInternal(history, `invoice/${data.invoiceid}`)
                } else {
                    setAlert(data.error, 'error')
                }
            } else {
                setAlert(phrase.this_item_is_out_of_stock, 'error')
            }
        }
    }
    useEffect(() => {
        if (props.match.params.id) {
            console.log('params id: ', props.match.params.id)
            getInvoiceData(props.match.params.id)
        }
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('buynowAddtime', (data) => {
            console.log('[BUYNOW SOCKET] ', data)
            socketHandler(data)
        })

        return () => {
            socket.off('buynowAddtime', (data) => {
                socketHandler(data)
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        invoiceDataRef.current = invoiceData
        userRef.current = user
    })

    // Get Invoice Data
    const getInvoiceData = (id) => {
        apiCall('get', `viewsample/${id}`, '', '', 'product')
            .then((results) => {
                let { data } = results
                if (data.status == 'success') {
                    // convert bprice to amount
                    data.data.invoicedata.amount = data.data.invoicedata.bprice
                    setInvoiceData(data.data.invoicedata)
                } else {
                    handleRedirectInternal(history, 'search')
                }
            })
            .catch((err) => {
                handleRedirectInternal(history, 'search')
            })
    }

    // Confirm Order
    const confirmOrder = () => {
        let data = {
            id: invoiceData.id,
            buyprice: invoiceData.bprice,
        }
        apiCall('post', 'buynowConfirm', data, '', 'product')
            .then((response) => {
                return
            })
            .catch((err) => {
                console.log(err)
                setAlert(phrase.something_went_wrong, 'error')
            })
    }

    return (
        <>
            {Object.keys(invoiceData).length > 0 ? (
                <>
                    <Invoice data={invoiceData} buynowConfirm={true} />
                    <div
                        className=" d-flex justify-content-center align-items-start mb-5"
                        style={{ backgroundColor: 'white', padding: '10px' }}
                    >
                        <div className="mr-1">
                            <PrimaryButton label={phrase.cancel} onClick={() => history.goBack()} />
                        </div>

                        <div>
                            <PrimaryButton
                                onClick={() => confirmOrder()}
                                label={phrase.confirm_your_order}
                            />
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default BuynowConfirm
