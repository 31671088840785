import React, { useContext, useState, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import { Button } from '@material-ui/core'
import { apiCall } from '../../common/api'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import BiddingCard from '../../components/molecules/Bidding/BiddingCard'
import './ProductView.css'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal, currencyFormat, dateFormatFront } from '../../common/components'
import { bidSocketHandler, messageHandler } from '../Common/socketHandler'
import FavoriteCheckbox from '../../components/atoms/FavoriteCheckbox'
import CurrencyConverter from './CurrencyConverter'
import Popup from '../../components/organisms/Popup'
import { PhotoSwipeGallery } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import * as XLSX from 'xlsx'
// context
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import ReadMore from '../../components/atoms/ReadMore'
import Layout from '../../components/templates/Layout'
import moment from 'moment'
import Spreadsheet from 'react-spreadsheet'
import Search from '../Search/search_old'
import { useCookies } from 'react-cookie'
const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
function TabPanel(props) {
    const { children, value, index, html, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {html ? (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        ></span>
                    ) : (
                        <span>{children}</span>
                    )}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

// includes both live / blind auction
const SingleAuction = ({
    product,
    images,
    downloadFile,
    setProduct,
    getProductView,
    hideBidding,
}) => {
    const authContext = useContext(AuthContext)
    const classes = useStyles()
    const alertContext = useContext(AlertContext)
    const { phrase } = useContext(CommonContext)
    const { setAlert } = alertContext
    const [value, setValue] = React.useState(0)
    const { user, responseStatus, clearResponse, saveSearch } = authContext
    const [productDescPopup, setProductDescPopup] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [excelData, setExcelData] = useState(null)
    const [column, setColumn] = useState([])
    const [userIn, setUserin] = useState(false)
    const [liveCount, setLiveCount] = useState(null)
    const [cookies, setCookie] = useCookies(['user_session'])
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)
    const [watchlist, setWatchList] = useState(false)
    let history = useHistory()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const userRef = useRef(user)
    const productRef = useRef(product)
    useEffect(() => {
        userRef.current = user
        productRef.current = product
    })
    const socketHandler = (data, type) => {
        bidSocketHandler(
            data,
            type,
            userRef.current,
            productRef.current,
            phrase,
            setProduct,
            setAlert,
        )
    }

    const cancelBidHandler = (data) => {
        if (data.project_id == productRef.current.id) {
            getProductView(productRef.current.id)
        }
    }

    const productDescClose = () => {
        setProductDescPopup(false)
    }

    let options = {}
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            console.log('[PRODUCT CLOSED SOCKET] ', data)
            socketHandler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            socketHandler(data, 'bidAddtime')
        })
        socket.on('drop_price', (data) => {
            socketHandler(data, 'drop_price')
        })
        // Bid Cancellation Event => Reload the product info
        socket.on('cancelbidemitted', function (data) {
            cancelBidHandler(data)
        })
        socket.on('reservepriceupdate', function (data) {
            socketHandler(data, 'reservepriceupdate')
        })
        socket.on('bid_extended', function (data) {
            socketHandler(data, 'bid_extended')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                socketHandler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                socketHandler(data, 'bidAddtime')
            })
            socket.off('drop_price', (data) => {
                socketHandler(data, 'drop_price')
            })
            socket.off('cancelbidemitted', function (data) {
                cancelBidHandler(data)
            })
            socket.off('reservepriceupdate', function (data) {
                socketHandler(data, 'reservepriceupdate')
            })
            socket.off('bid_extended', function (data) {
                socketHandler(data, 'bid_extended')
            })
            socket.disconnect()
        }
    }, [])
    useEffect(() => {
        const socket2 = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        console.log('Im In')
        setUserin(true)
        let socket_data = {
            action: 'userConnected',
            session: cookies.user_session || '',
            product_id: productRef.current.id,
        }
        socket2.emit('userWatch', socket_data)
        return () => {
            // if (userIn === true) {
            const socket1 = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
            console.log('Im out')
            setUserin(false)
            let socket_data = {
                action: 'userDisconnected',
                session: cookies.user_session || '',
                product_id: productRef.current.id,
            }
            socket1.emit('userWatch', socket_data)
            // }
        }
    }, [])
    useEffect(() => {
        window.onunload = window.onbeforeunload = () => {
            // update live view when close window
            let socket_data = {
                action: 'userDisconnected',
                session: cookies.user_session || '',
                product_id: productRef.current.id,
            }
            socket.emit('userWatch', socket_data)
        }
    }, [window])

    useEffect(() => {
        socket.on('liveView', (data) => {
            let min = 0
            if (data[productRef.current.id]) {
                min += data[productRef.current.id]?.length
            }
            console.log('live count emitted values', data, min)
            setLiveCount(min > 0 ? min - 1 : min)
            // if(min != 0){
            //     let temp = min - 1
            //     if(min - 1 === 0){

            //     }
            // }
        })
    }, [])
    let categories = ''
    // if (product) {
    //     for (let i in product.multiplecategoryshow) {
    //         // last index
    //         if (i == product.multiplecategoryshow.length - 1) {
    //             categories += phrase[product.multiplecategoryshow[i]]
    //         } else {
    //             categories += phrase[product.multiplecategoryshow[i]] + ', '
    //         }
    //     }
    // }

    const redirectFile = (event) => {
        if (event.target.alt !== 'img') {
            window.location.href = `${global.site_url}/uploads/product/${event.target.title}`
        } else {
            event.preventDefault()
            setIsOpen(true)
        }
    }

    async function getFileFromUrl(url, name, defaultType = 'application/vnd.ms-excel') {
        const response = await fetch(url)
        const data = await response.blob()
        return new File([data], name, {
            type: data.type || defaultType,
        })
    }
    const getDescriptionFile = async (e) => {
        const file = await getFileFromUrl(
            `${process.env.REACT_APP_DOMAIN}/api/getFile/${product.description_file}`,
            'example.xls',
        )
        let file2 = null
        let reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = (e) => {
            file2 = e.target.result
            const workbook = XLSX.read(file2, { type: 'buffer' })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            const data = XLSX.utils.sheet_to_json(worksheet)
            const temp = []
            data.map((val) => {
                temp.push(Object.keys(val).map((data) => ({ value: val[data], readOnly: true })))
            })
            setExcelData(temp)
            setColumn(Object.keys(data[0]))
        }
    }
    useEffect(() => {
        if (product.description_file) getDescriptionFile()
    }, [product])
    return (
        <Layout>
            <div className="product-view-container pt-4 pb-4 container">
                {/* <Breadcrumbs seperator=">">
                    <Link to="/search">Product</Link>
                    <Link to="/search">HP Elite Book</Link>
                    <Typography>Description</Typography>
                </Breadcrumbs> */}
                <Button className="backBtn my-2" onClick={() => history.goBack()}>
                    <span className="material-icons">arrow_back</span>Back
                </Button>
                <div className="pv-descp-text">
                    <div className="pv-top-box">
                        <div className="posted-date d-flex justify-content-between align-items-center">
                            Posted Date: {moment(product.date_added).format('MM-DD-YYYY')}
                            {user.isBuyer && product.isclosed >= 0 ? (
                                <FavoriteCheckbox
                                    watchlisted={product.isWatchlisted}
                                    project_id={product.id}
                                />
                            ) : null}
                        </div>
                        <h1 className="pv-product-title">{product.title}</h1>
                        {product.description_file && excelData ? (
                            <>
                                {' '}
                                <div className="spread-sheet-wrapper">
                                    <Spreadsheet data={excelData} columnLabels={column} />
                                </div>
                                <div className="pv-bottom-actions dp-list-buttons download-btn-cust">
                                    {/* <a
                                        className="dl-btn"
                                        href={
                                            global.site_url +
                                            '/uploads/product/' +
                                            product.description_file
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span className="material-icons">download</span>Download
                                        Product List
                                    </a> */}
                                    <SecondaryButton
                                        btnSize="small"
                                        type="button"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                'blank',
                                                window.open(
                                                    global.site_url +
                                                        '/uploads/product/' +
                                                        product.description_file,
                                                ),
                                            )
                                        }
                                    >
                                        <span className="material-icons">download</span> Download
                                        Product List
                                    </SecondaryButton>
                                </div>
                            </>
                        ) : null}
                        <div className="product-details-wrapper">
                            <div className="catgry">
                                <div>Category:</div>
                                <div>{product.category_name}</div>
                            </div>
                            <div className="pv-quantity">
                                <div>Quantity:</div>
                                <div>{product.qty}</div>
                            </div>
                            <div className="prcPro">
                                <div>Price:</div>
                                <div>
                                    {product.currency_symbol}{' '}
                                    {parseFloat(product.wprice).toFixed(3)} {product.currency}
                                </div>
                            </div>
                        </div>
                        <BiddingCard
                            product={product}
                            phrase={phrase}
                            setProduct={setProduct}
                            user={user}
                            liveCount={liveCount}
                        />
                        {/* {liveCount ? (
                            <p className="liveUsers">
                                You and {2}
                                <span className="material-icons">visibility</span>
                            </p>
                        ) : null} */}
                    </div>
                    <h6 className="pv-dhead">Description:</h6>
                    <p className="pv-dtext">
                        <ReadMore
                            limit={50}
                            data={product.desc_proc}
                            setHTML={true}
                            buttonText={phrase.view_more}
                            readMoreFunction={setProductDescPopup}
                            readMoreState={productDescPopup}
                        />
                    </p>
                </div>
                {product.category_id && (
                    <Search
                        category={product.category_id
                            ?.split(',')
                            // ?.filter((val) => val < 100)
                            ?.join(',')}
                        from="product_view"
                        similar_id={product.id}
                    />
                )}
            </div>
        </Layout>
    )
}

export default SingleAuction
