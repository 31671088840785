import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../atoms/Inputs/CustomInput'
import PrimaryButton from '../../atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import FavouriteCheckbox from '../../atoms/FavoriteCheckbox'
import SecondaryButton from '../../atoms/SecondaryButton'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import { useHistory } from 'react-router-dom'
import Popup from '../../organisms/Popup'
import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BuyerContext from '../../../context/buyer/buyerContext'
import AuthContext from '../../../context/auth/authContext'

const Bidding = ({ product, phrases, handleClose, wsprice }) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    let { bidConfirm } = buyerContext
    const { isAuthenticated, user } = authContext
    const history = useHistory()

    /** Convert Bid Amount
     * @param {string} type valToFormat = Actual bid amount to formatted amount, formatToVal = Formatted amount to actual value
     */
    const convertBidAmt = (amt, type) => {
        if (type == 'valToFormat') {
            if (amt == '0') return ''
            return amt
                .toString()
                .replace(/,/g, '')
                .replace(/\D/g, '') // replace non-numeric char with empty string
                .replace(/[^\d].+/, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else if (type == 'formatToVal') {
            if (amt == '') return 0
            return amt
                .toString()
                .replace(/,/g, '')
                .replace(/[^\d].+/, '')
        }
    }

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(product ? product.next_bid : 0, `Min. Bid $${product ? product.next_bid : 0}`)
            .required('Enter Bid Amount'),
    })
    // line item specific variable master_id,
    const formik = useFormik({
        initialValues: {
            wsprice: parseInt(wsprice),
            bidValDisplay: convertBidAmt(wsprice, 'valToFormat'),
            id: product.id,
            auctionType: product.auctiontype,
            bidType: product.bidtype,
            lotType: product.lottype,
            bid_increment: parseInt(product.incrementAmt),
            csvfile: product.csvfile,
            testbid_incre: parseInt(product.incrementAmt),
            realtime_nbid: parseInt(product.next_bid),
            master_id: product.master_id,
        },
        validationSchema: validationArray,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log('submitting')
            bidConfirm(values)
        },
    })

    const bidInputChangeHandler = (value) => {
        formik.setFieldValue('bidValDisplay', convertBidAmt(value, 'valToFormat'))
        formik.setFieldValue('wsprice', convertBidAmt(value, 'formatToVal'))
    }

    const [isBuyer, setIsBuyer] = useState(true)
    useEffect(() => {
        if (user) {
            // if user is admin, or user is seller
            // is_emp = 1, is seller
            if (user.admin != '0' || user.is_emp == '1') {
                console.log('IS BUYER:: false')
                setIsBuyer(false)
            }
        }
    }, [user])

    return (
        <>
            {product ? (
                <>
                    {/* {product.market_status === 'open' ? ( */}
                    <form onSubmit={formik.handleSubmit}>
                        <div className="biddingCnt d-flex justify-content-center align-items-center">
                            {isAuthenticated ? (
                                <>
                                    {product.auctiontype == 'lineitem' ? (
                                        <div>
                                            <div className="lineItemBidAct d-flex justify-content-start align-items-start flex-wrap">
                                                <CustomInput
                                                    size="small"
                                                    label="Enter bid"
                                                    value={formik.values.bidValDisplay}
                                                    name="wsprice"
                                                    inputProps={{
                                                        min: product.next_bid,
                                                    }}
                                                    onChange={(e) =>
                                                        bidInputChangeHandler(e.target.value)
                                                    }
                                                    placeholder={
                                                        `Min. Bid ` +
                                                        currencyFormat(product.next_bid)
                                                    }
                                                    min="0"
                                                    type="text"
                                                    error={formik.errors.wsprice}
                                                    helperText={
                                                        formik.errors.wsprice &&
                                                        formik.touched.wsprice &&
                                                        formik.errors.wsprice
                                                    }
                                                    disabled={
                                                        product.market_status != 'open'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                {product.market_status != 'open' || !isBuyer ? (
                                                    <PrimaryButton
                                                        type="submit"
                                                        label={phrases.submit}
                                                        disabled="true"
                                                    />
                                                ) : (
                                                    <PrimaryButton
                                                        type="submit"
                                                        label={phrases.submit}
                                                    />
                                                )}
                                            </div>
                                            {product.bidtype != 'sealed' ? (
                                                <div style={{ fontSize: '14px' }}>
                                                    {phrases.enter} {product.currency_symbol}
                                                    {parseInt(product.next_bid)} {phrases.or_more}
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <PrimaryButton
                                                    type="submit"
                                                    label={phrases.submit}
                                                    onClick={handleClose}
                                                />
                                            </div>
                                            <div className="margin-5">
                                                <SecondaryButton
                                                    label={phrases.cancel}
                                                    onClick={handleClose}
                                                ></SecondaryButton>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <PrimaryButton
                                    label="Login to Bid"
                                    btnSize="small"
                                    onClick={() => handleRedirectInternal(history, 'login')}
                                />
                            )}
                        </div>
                    </form>
                    {/* ) : null} */}
                </>
            ) : null}
        </>
    )
}

export default Bidding
