import React from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { SignalCellularNullRounded } from '@material-ui/icons'

export default function OfferTimeline({ data, user }) {
    const mediaCheck = window.matchMedia('(max-width:550px)').matches
    return (
        <Timeline align={!mediaCheck ? 'alternate' : 'right'}>
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent></TimelineContent>
            </TimelineItem>
            {data.map((offer, index) => (
                <>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <div className="media offer-media">
                                <div className="media-body">
                                    <div className="offer-user-name">{offer.display_name}</div>
                                    <div className="offer-initial-price">
                                        {offer.display_text} - €{offer.display_amount}
                                    </div>
                                    <div className="offer-date">
                                        {moment(offer.display_date).format('MM-DD-YYYY h:mm A')}
                                    </div>
                                    <div className="offer-date">
                                        <p className={offer.display_class}>
                                            {offer.display_status}
                                        </p>
                                    </div>
                                </div>
                                <div className="media-right clearfix">
                                    <Avatar>{offer.display_name.slice(0, 1)}</Avatar>
                                </div>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                    {/* {offer.offer_amount > 0 &&
                        offer.counter_by > 0 &&
                        offer.counter_by == user?.id && (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div className="media offer-media">
                                        <div className="media-body">
                                            <div className="offer-user-name">{offer.emp_name}</div>
                                            <div className="offer-initial-price">
                                                {`Counter Offer - €${
                                                    offer.counter_by > 0
                                                        ? offer.counter_amount
                                                        : offer.offer_amount
                                                }`}
                                            </div>
                                            <div className="offer-date">
                                                {moment(offer.counter_date).format('MM-DD-YYYY')}
                                            </div>
                                            <div className="offer-date">
                                                {offer.declined_by > 0 && (
                                                    <p className="text-danger">Declined!</p>
                                                )}
                                                {offer.accepted > 0 && (
                                                    <p className="text-success">Accepted!</p>
                                                )}
                                                {offer.counter_by == 0 && offer.declined_by == 0 ? (
                                                    <p className="">
                                                        Offer submitted, awaiting Admin review.
                                                    </p>
                                                ) : (
                                                    SignalCellularNullRounded
                                                )}
                                            </div>
                                        </div>
                                        <div className="media-right clearfix">
                                            <Avatar>{offer.username?.slice(0, 1)}</Avatar>
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        )} */}
                </>
            ))}
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot />
                </TimelineSeparator>
                <TimelineContent></TimelineContent>
            </TimelineItem>
        </Timeline>
    )
}
