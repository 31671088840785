import React, { useState, useContext, useEffect, useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import { Button, Popover } from '@material-ui/core'
import Step from '@material-ui/core/Step'
import PropTypes from 'prop-types'
import StepLabel from '@material-ui/core/StepLabel'
import clsx from 'clsx'
import { mapData, getLanguageArr } from '../../common/components'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import CommonContext from '../../context/common/commonContext'
import AuthContext from '../../context/auth/authContext'
import AlertContext from '../../context/alert/alertContext'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { Link } from 'react-router-dom'
import CheckBox from '../../components/atoms/CheckBox'
import './Registration.css'
import { logo, siteName } from '../../Utils'
import StepConnector from '@material-ui/core/StepConnector'
import csc from 'country-state-city'
import PreviewImage from '../../components/molecules/PreviewImage'
import Loader from '../../components/molecules/Loader'
import { terms_of_service } from './Terms'
import {
    step1InitialValues,
    step1ValidationSchema,
    step1Data,
    step2InitialValues,
    step2ValidationSchema,
    step2Data,
    step3InitialValues,
    step3ValidationSchema,
    step4InitialValues,
    step4ValidationSchema,
    step4Data,
    step5InitialValues,
    step5ValidationSchema,
} from './steps'
import { apiCall } from '../../common/api'
import StaticPage from '../StaticPages'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundColor: 'var(--primColor)',
        },
    },
    completed: {
        '& $line': {
            backgroundColor: 'var(--primColor)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
    root: {
        width: '45px',
        height: '45px',
        color: '#c9c9c9',
        border: '2px solid #c9c9c9',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        color: 'var(--primColor)',
        borderColor: 'var(--primColor)',
    },
    completed: {
        filter: 'var(--primColor)',
        color: 'var(--primColor)',
        borderColor: 'var(--primColor)',
    },
})

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    const icons = {
        1: <span className="material-icons">perm_contact_calendar</span>,
        2: <span className="material-icons">assignment</span>,
        3: <span className="material-icons">assignment_turned_in</span>,
        // 4: <span className="material-icons">loupe</span>,
        // 5: <span className="material-icons">lock</span>,
        // 6: <span className="material-icons">done</span>,
    }

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[props.icon]}
        </div>
    )
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
}

const Registration = (props) => {
    const tradeReferenceInitialValues = {
        trade_name: '',
        trade_email: '',
        trade_phone: '',
    }
    const bankReferenceInitialValues = {
        bank_name: '',
        bank_email: '',
        bank_location: '',
        bank_phone: '',
        contact_officer: '',
        account_number: '',
    }
    const ownersInitialValues = {
        owner_title: '',
        owner_name: '',
        owner_email: '',
    }
    const taxExemptChoices = [
        { show: 'No', value: 0, name: 'no' },
        { show: 'Yes', value: 1, name: 'yes' },
    ]
    const paymentMehtodOptions = [
        { show: 'Wire', value: 0, name: 'wire' },
        { show: 'Credit Card (3% fee added)', value: 1, name: 'credit_card' },
    ]
    const businessStructureOptions = [
        {
            show: 'Sole Proprietorship',
            value: 0,
            name: 'sole_proprietorship',
        },
        {
            show: 'Partnership',
            value: 1,
            name: 'partnership',
        },
        {
            show: 'Corporation',
            value: 2,
            name: 'corporation',
        },
        {
            show: 'LLC',
            value: 3,
            name: 'llc',
        },
        {
            show: 'Other',
            value: 4,
            name: 'other',
        },
    ]
    const classes = useStyles()
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const history = useHistory()
    const { nonloginToken, allCountries, phrase, language } = commonContext
    const { register, uploadFile } = authContext
    const { setAlert } = useContext(AlertContext)
    const [genericData, setGenericData] = useState({})
    const [activeStep, setActiveStep] = useState(0)
    const [statesinLocal, setStatesinLocal] = useState([])
    const [taxExemptOptions, settaxExemptOptions] = useState(taxExemptChoices)
    const [paymentMethods, setpaymentMethods] = useState(paymentMehtodOptions)
    const [businessStructures, setBusinessStructures] = useState(businessStructureOptions)
    const [tradeReferences, settradeReferences] = useState([tradeReferenceInitialValues])
    const [bankReferences, setbankReferences] = useState([bankReferenceInitialValues])
    const [isOtherSelected, setIsOtherSelected] = useState(false)
    const [stepLoader, setStepLoader] = useState(false)
    const [owners, setOwners] = useState([ownersInitialValues])
    const [taxExemptDocument, setTaxExemptDocument] = useState()
    const [loading, setLoading] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false)
    const [passwordShown2, setPasswordShown2] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }
    const togglePasswordVisiblity2 = () => {
        setPasswordShown2(passwordShown2 ? false : true)
    }
    const categoryList = useRef([])
    const formikValues = useRef({})
    const formRef = useRef()
    const steps = [
        phrase.enter_in_your_credentials,
        // `${phrase.enter} ${phrase.location}`,
        phrase.registerpagestatic_11,
        // 'Additional Information',
        // phrase.terms_conditions,
        // phrase.finish,
    ]
    const languageArr = getLanguageArr(phrase)
    const [afterRegister, setAfterRegister] = useState(null)

    // Initial Values for each step
    const initialvalues0 = step1InitialValues()
    const initialvalues1 = step2InitialValues()
    const initialvalues2 = step3InitialValues()
    const initialvalues4 = step4InitialValues()
    const initialvalues5 = step5InitialValues()
    // Validation Schema for each step
    const validateStep1 = step1ValidationSchema(phrase)
    const validateStep2 = step2ValidationSchema(phrase)
    const validateStep3 = step3ValidationSchema(phrase)
    const validateStep4 = step4ValidationSchema(phrase)
    const validateStep5 = step5ValidationSchema(phrase)

    // formik validate each step, and handle submission
    const formik = useFormik({
        initialValues: {
            ...initialvalues0,
            ...initialvalues1,
            ...initialvalues2,
            ...initialvalues4,
            ...initialvalues5,
        },
        validationSchema: (() => {
            switch (Number(activeStep)) {
                case 0:
                    return validateStep1
                case 1:
                    return validateStep2
                case 2:
                    return validateStep3
                case 3:
                    return validateStep4
                case 4:
                    return validateStep5
            }
        })(),
        onSubmit: async (values) => {
            if (activeStep == 0) {
                try {
                    setStepLoader(true)
                    // Check Unique Email
                    let queryUrl = `check_unique?email=${encodeURIComponent(
                        values.email,
                    )}&site_id=${global.site_id}`
                    apiCall('get', '', '', '', queryUrl).then((results) => {
                        console.log('RESULTS: ', results)
                        // this email is not unique
                        setStepLoader(false)
                        if (!results.data.status) {
                            return formik.setFieldError(
                                'email',
                                phrase.email_address_has_already_been_used,
                            )
                        }
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    })
                } catch (e) {
                    console.log('step 1 err: ', e)
                    setStepLoader(false)
                    setActiveStep((prevActiveStep) => prevActiveStep + 1)
                }
            }
            // STEP 2
            // else if (activeStep == 1) {
            //     if (typeof values.state === 'undefined') {
            //         values.state = ''
            //         formik.setFieldError('state', `${phrase.enter} ${phrase.state}`)
            //     } else {
            //         setActiveStep((prevActiveStep) => prevActiveStep + 1)
            //     }
            // }
            // STEP 3 - choose preferred categories
            else if (activeStep == 2) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            // STEP 4 - additional information
            else if (activeStep == 3) {
                if (formik.values.tax_exempt === 1 && !taxExemptDocument) {
                    return setAlert('Upload Tax Exempt Document', 'warning')
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            // STEP 5
            else if (activeStep == 1) {
                if (values.agree.length == 0) {
                    formik.setFieldError('agree', phrase.registerpagestatic_32)
                }
                // else if (values.agreeSalesTax.length == 0) {
                //     formik.setFieldError('agreeSalesTax', 'Please accept Sales Tax')
                // }
                else if (values.categories.length == 0) {
                    formik.setFieldError('categories', 'Please Select Atleast One Category!')
                } else {
                    let payload = {}
                    if (Array.isArray(values['agree'])) {
                        payload['termsagree'] = values['agree'][0]
                    }
                    payload['business_type'] = values['business_type']
                    if (Array.isArray(values['categories'])) {
                        payload['interestin'] = values['categories'].join()
                    }
                    let { name: countryName } = csc.getCountryById(values['counntry'])
                    let { name: stateName } = csc.getStateById(values['state'])
                    payload['city'] = values['city']
                    payload['companyname'] = values['company_name']
                    payload['confirm_email'] = values['confirm_email']
                    payload['password'] = values['password']
                    payload['password1'] = values['password1']
                    payload['country'] = countryName
                    payload['state'] = stateName
                    payload['zipcode'] = values['zip_code']
                    payload['email'] = values['email']
                    payload['address'] = values['street_address']
                    payload['username'] = values['username']
                    payload['landline'] = values['phone']
                    payload['first_name'] = values['first_name']
                    payload['last_name'] = values['last_name']
                    payload['phone'] = values['phone']
                    payload['company_id'] = values['company_id']
                    payload['language'] = values['language']
                    payload['nonloginToken'] = nonloginToken
                    payload['checkDuplicateUsername'] = 'true'
                    payload['custom_fields'] = {
                        sales_taxagree: values.agreeSalesTax[0],
                        parent_company: values.parent_company,
                        tax_exempt: taxExemptChoices[values.tax_exempt].name,
                        db_number: values.db_number,
                        tax_number: values.tax_number,
                        business_years: values.business_years,
                        payment_method: paymentMehtodOptions[values.payment_method].name,
                        business_structure: values.business_structure
                            ? values.business_structure !== 4
                                ? businessStructureOptions[values.business_structure].name
                                : values.other_business_structure
                            : '',
                        owners: owners,
                        ap_name: values.ap_name,
                        ap_phone: values.ap_phone,
                        ap_email: values.ap_email,
                        buyer_name: values.buyer_name,
                        buyer_phone: values.buyer_phone,
                        buyer_email: values.buyer_email,
                        trade_references: [...tradeReferences].filter(
                            (tR) => tR.trade_email && tR.trade_name && tR.trade_phone,
                        ),
                        bank_references: [...bankReferences].filter(
                            (bR) =>
                                bR.bank_name &&
                                bR.bank_phone &&
                                bR.bank_location &&
                                bR.contact_officer &&
                                bR.account_number,
                        ),
                    }

                    if (values.tax_exempt == 1) {
                        const formData = new FormData()
                        formData.append('tax_exempt_doc', taxExemptDocument)
                        const res = await uploadFile(formData)
                        payload.custom_fields['tax_exempt_doc'] = res.data.saved_file.name
                    }

                    if (afterRegister) {
                        payload['id'] = afterRegister.userId
                        const {
                            data: {
                                status,
                                code,
                                msg: { phoneError, landlineErr, phoneErrMsg },
                            },
                        } = await register(payload, 'after_register')
                        if (!status && code === 400) {
                            setActiveStep(0)
                            if (phoneError > 0) {
                                console.log('111111111111')
                                formik.setFieldError('phone', phoneErrMsg)
                            }
                            if (landlineErr > 0) {
                                console.log('222222222222')
                                formik.setFieldError('landline', phoneErrMsg)
                            }
                            return
                        }
                        history.push('/search')
                    } else {
                        setStepLoader(true)
                        const {
                            data: {
                                status,
                                code,
                                msg: {
                                    usernameLen,
                                    emailslen,
                                    usernameErrMsg,
                                    emailErrMsg,
                                    phoneError,
                                    landlineErr,
                                    phoneErrMsg,
                                },
                            },
                        } = await register(payload, 'register')
                        setStepLoader(false)
                        if (!status && code === 400) {
                            setActiveStep(0)
                            if (usernameLen > 0) {
                                formik.setFieldError('username', usernameErrMsg)
                            }
                            if (emailslen > 0) {
                                formik.setFieldError('email', emailErrMsg)
                            }
                            if (phoneError > 0) {
                                console.log('111111111111')
                                formik.setFieldError('phone', phoneErrMsg)
                            }
                            if (landlineErr > 0) {
                                console.log('222222222222')
                                formik.setFieldError('landline', phoneErrMsg)
                            }
                            return
                        }
                        // setActiveStep((prevActiveStep) => prevActiveStep + 1)
                        setAlert('Thanks For Your Registration!', 'success')
                        history.push('/login')
                    }
                }
            } else if (activeStep === 5) {
                history.push('/login')
            }
        },
    })

    // Input Fields for each step
    const step1 = step1Data(
        formik,
        phrase,
        languageArr,
        allCountries,
        statesinLocal,
        passwordShown,
        togglePasswordVisiblity,
        passwordShown2,
        togglePasswordVisiblity2,
    )
    let step2 = step2Data(formik, phrase, allCountries, statesinLocal)
    const step4 = step4Data(
        formik,
        phrase,
        taxExemptOptions,
        paymentMethods,
        businessStructures,
        isOtherSelected,
    )

    const handleTaxExemptDocumentUpload = (e) => {
        const { files, name } = e.target
        formik.setFieldTouched(name, true)
        var fnameArray = files[0].name.split('.')
        var ext = fnameArray[fnameArray.length - 1]
        if (ext === 'pdf' || ext === 'docx' || ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
            setTaxExemptDocument(files[0])
            formik.setFieldValue(name, files[0])
        } else {
            formik.setFieldError(name, 'File type should be docx / pdf / jpg / jpeg / png')
        }
    }
    const handleMenu1 = (event) => {
        setanchormenu1(event.currentTarget)
    }
    const handleClose1 = () => {
        setanchormenu1(null)
        setOpen1(false)
    }
    const handleArrayFieldsChange = (e, key, type) => {
        if (type === 'trade') {
            if (e.target) {
                const { name, value } = e.target
                let temp = [...tradeReferences]
                temp[key][name] = value
                settradeReferences(temp)
            } else {
                let temp = [...tradeReferences]
                temp[key]['trade_phone'] = e
                settradeReferences(temp)
            }
        }

        if (type === 'bank') {
            if (e.target) {
                const { name, value } = e.target
                let temp = [...bankReferences]
                temp[key][name] = value
                setbankReferences(temp)
            } else {
                let temp = [...bankReferences]
                temp[key]['bank_phone'] = e
                setbankReferences(temp)
            }
        }

        if (type === 'owner') {
            const { name, value } = e.target
            if (key === 0) {
                formik.setFieldValue(name, value)
            }
            let temp = [...owners]
            temp[key][name] = value
            setOwners(temp)
        }
    }
    const [open1, setOpen1] = React.useState(false)
    const [anchormenu1, setanchormenu1] = useState(null)
    const addArrayFields = (type) => {
        if (type === 'trade') {
            tradeReferences.length < 3
                ? settradeReferences([...tradeReferences, tradeReferenceInitialValues])
                : null
        }
        if (type === 'bank') {
            bankReferences.length < 3
                ? setbankReferences([...bankReferences, bankReferenceInitialValues])
                : null
        }
        if (type === 'owner') {
            owners.length < 3 ? setOwners([...owners, ownersInitialValues]) : null
        }
    }

    const removeArrayFields = (key, type) => {
        if (type === 'trade') {
            let temp = [...tradeReferences]
            temp.splice(key, 1)
            settradeReferences(temp)
        }
        if (type === 'bank') {
            let temp = [...bankReferences]
            temp.splice(key, 1)
            setbankReferences(temp)
        }
        if (type === 'owner') {
            let temp = [...owners]
            temp.splice(key, 1)
            setOwners(temp)
        }
    }

    useEffect(() => {
        document.title = global.site_title + ' | Registration'
        setLoading(true)

        if (props.match.params.token) {
            // get user details
            apiCall(
                'get',
                '',
                '',
                '',
                `getUserDetailsFromToken/${props.match.params.token}/${global.site_id}`,
            ).then((userDetails) => {
                formik.values.first_name = userDetails.data.data.first_name
                formik.values.last_name = userDetails.data.data.last_name
                formik.values.company_name = userDetails.data.data.companyname
                formik.values.email = userDetails.data.data.email
                formik.values.confirm_email = userDetails.data.data.email
                setAfterRegister({ userId: userDetails.data.data.id })
            })
        }

        apiCall('get', '', '', '', `register?site_id=${global.site_id}&version=2`)
            .then((results) => {
                if (results.data.success) {
                    setGenericData(results.data.data)
                    categoryList.current = results.data.data.categories
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        const { id, name } = csc.getCountryById(formik.values.counntry)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
    }, [formik.values.counntry])

    useEffect(() => {
        if (formik.values.business_structure === 4) {
            setIsOtherSelected(true)
        } else {
            setIsOtherSelected(false)
        }
    }, [formik.values.business_structure])

    // Category Options
    const RadioOptions = []
    categoryList.current.map((item) => {
        item.value.map((cat, i) => {
            RadioOptions.push({
                id: cat.id,
                description: (
                    <>
                        <p>{cat.name}</p>
                    </>
                ),
                formik: formik,
                name: 'categories',
                value: cat.id,
                checked() {
                    if (typeof formik.values !== 'undefined') {
                        if (typeof formik.values.categories !== 'undefined') {
                            if (Array.isArray(formik.values.categories)) {
                                if (formik.values.categories.length) {
                                    let index = formik.values.categories
                                        .map((ele) => Number(ele))
                                        .indexOf(this.value)
                                    return index >= 0 ? true : false
                                }
                            }
                        }
                    }
                    return false
                },
            })
        })
    })

    useEffect(() => {
        step2 = step2.map((ele) => {
            if (ele.label === 'Country') {
                ele.options = allCountries
            }
            return ele
        })
        setStep2_state(step2)
    }, [allCountries])

    useEffect(() => {
        let ster2arr = [...step2state]
        ster2arr = ster2arr.map((ele) => {
            if (ele.label === 'State') {
                ele.options = statesinLocal
            }
            return ele
        })
        setStep2_state(ster2arr)
    }, [statesinLocal])

    const [step1s, setStep1s] = useState(step1)
    const [step2state, setStep2_state] = useState(step2)
    const [m, setM] = useState({})

    useEffect(() => {
        setM(mapData(step1s))
    }, [step1s])

    useEffect(() => {
        if (activeStep == 0 || activeStep == 1 || activeStep == 3) {
            let firstErrorFieldName = Object.keys(formik.errors)[0]
            if (firstErrorFieldName && formRef.current[firstErrorFieldName]) {
                if (typeof formRef?.current[firstErrorFieldName]?.focus === 'function')
                    formRef?.current[firstErrorFieldName]?.focus()
            }
        }
        console.log(formik.errors)
    }, [formik.submitCount])
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // })
    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <h4 className="text-left personal">{phrase.personal_info}</h4>
                        <div className="row">{Object.values(mapData(step1))}</div>
                        {/* <h4 className="text-left personal">Personal Information</h4>
                        <div className="row">
                            {step2state.length ? Object.values(mapData(step2)) : null}
                        </div> */}
                    </>
                )
            case 1:
                return (
                    <div className="row">
                        <div className="regCategories">
                            {RadioOptions.map((data, index) => (
                                <div className="categorySelect">
                                    <CheckBox
                                        name={data.name}
                                        label={data.description}
                                        value={data.value}
                                        onchange={formik.handleChange}
                                        checked={data.checked()}
                                        variant="outlined"
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        onClick={handleMenu1}
                                    />
                                    <Popover
                                        id={'simple-menu'}
                                        open={Boolean(anchormenu1)}
                                        anchorEl={anchormenu1}
                                        onClose={handleClose1}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <div className="p-4">
                                            <div className="d-flex align-items-center">
                                                <CheckBox></CheckBox>
                                                <p className="ml-3">Dell</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <CheckBox></CheckBox>
                                                <p className="ml-3">Hp</p>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <CheckBox></CheckBox>
                                                <p className="ml-3">Lenovo</p>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            ))}
                        </div>
                        <div>
                            <p className="catError">
                                {formik.errors['categories']
                                    ? formik.errors['categories']
                                    : delete formik.errors['categories']}
                            </p>
                        </div>
                    </div>
                )
            case 3:
                return (
                    <div>
                        <div className="row">
                            {Object.values(mapData(step4[0]))}
                            {formik.values.tax_exempt == 1 ? (
                                <>
                                    <label htmlFor="contained-button-file" className="customUpload">
                                        <input
                                            className="uploadInput"
                                            id="contained-button-file"
                                            type="file"
                                            name="tax_exempt_document"
                                            hidden
                                            onChange={handleTaxExemptDocumentUpload}
                                        />
                                        <span className="material-icons">description</span>
                                        <h6>Upload a copy of tax exempt document.</h6>
                                    </label>
                                    {formik.errors.tax_exempt_document &&
                                    formik.touched.tax_exempt_document ? (
                                        <div className="invalid-feedback">
                                            {formik.errors.tax_exempt_document}
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {taxExemptDocument && formik.values.tax_exempt == 1 ? (
                                <PreviewImage
                                    preview_image={[
                                        {
                                            src: '/assets/images/pdf.png',
                                            name: 'tax_exempt_document',
                                            file_name: taxExemptDocument.name.split('.')[0],
                                        },
                                    ]}
                                    removeImage={() => {
                                        setTaxExemptDocument('')
                                    }}
                                />
                            ) : null}
                        </div>
                        <h2 className="h2Style">Officers/Owners</h2>
                        <div className="row">
                            {owners &&
                                owners.map((owner, key) => (
                                    <>
                                        {Object.values(
                                            mapData([
                                                {
                                                    label: 'Title',
                                                    name:
                                                        key === 0
                                                            ? 'owner_title'
                                                            : 'owner_title_more',
                                                    type: 'text',
                                                    value: owner.owner_title,
                                                    placeholder: `${phrase.enter} Title`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'owner'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Name',
                                                    name:
                                                        key === 0
                                                            ? 'owner_name'
                                                            : 'owner_name_more',
                                                    type: 'text',
                                                    value: owner.owner_name,
                                                    placeholder: `${phrase.enter} Principal`,
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'owner'),
                                                    class: 'col-md-4 col-12',
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Email',
                                                    name:
                                                        key === 0
                                                            ? 'owner_email'
                                                            : 'owner_email_more',
                                                    type: 'email',
                                                    value: owner.owner_email,
                                                    placeholder: `${phrase.enter} Owner`,
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'owner'),
                                                    class: 'col-md-3 col-12',
                                                    formik: formik,
                                                },
                                            ]),
                                        )}
                                        {owners.length > 1 ? (
                                            <span
                                                className="material-icons closenode"
                                                onClick={() => removeArrayFields(key, 'owner')}
                                            >
                                                highlight_off
                                            </span>
                                        ) : null}
                                    </>
                                ))}
                            <Button onClick={() => addArrayFields('owner')} className="mb-4">
                                <span className="material-icons pr-2">add_circle_outline</span>
                                Add Officer/Owner
                            </Button>
                        </div>
                        <h4 className="h2Style">A/P Contact</h4>
                        <div className="row">{Object.values(mapData(step4[1]))}</div>
                        <h4 className="h2Style">Buyer Contact</h4>
                        <div className="row">{Object.values(mapData(step4[2]))}</div>
                        <h2 className="h2Style">Trade References</h2>
                        <div className="row">
                            {tradeReferences &&
                                tradeReferences.map((tradeReference, key) => (
                                    <>
                                        {Object.values(
                                            mapData([
                                                {
                                                    label: 'Name',
                                                    name: 'trade_name',
                                                    type: 'text',
                                                    value: tradeReference.trade_name,
                                                    placeholder: `${phrase.enter} Name`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'trade'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Phone Number',
                                                    name: 'trade_phone',
                                                    type: 'countrySelect',
                                                    value: tradeReference.trade_phone,
                                                    defaultCountry: 'us',
                                                    regions: 'america',
                                                    placeholder: `${phrase.enter} Phone Number`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'trade'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Email',
                                                    name: 'trade_email',
                                                    type: 'email',
                                                    value: tradeReference.trade_email,
                                                    placeholder: `${phrase.enter} Email`,
                                                    class: 'col-md-3 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'trade'),
                                                    formik: formik,
                                                },
                                            ]),
                                        )}
                                        {tradeReferences.length > 1 ? (
                                            <span
                                                className="material-icons closenode"
                                                onClick={() => removeArrayFields(key, 'trade')}
                                            >
                                                highlight_off
                                            </span>
                                        ) : null}
                                    </>
                                ))}
                            <Button onClick={() => addArrayFields('trade')} className="mb-4">
                                <span className="material-icons pr-2">add_circle_outline</span>
                                Add Trade Reference
                            </Button>
                        </div>
                        <h2 className="h2Style">Bank References</h2>
                        <div className="row">
                            {bankReferences &&
                                bankReferences.map((bankReference, key) => (
                                    <div className="d-flex flex-wrap">
                                        {Object.values(
                                            mapData([
                                                {
                                                    label: 'Bank Name',
                                                    name: 'bank_name',
                                                    type: 'text',
                                                    value: bankReference.bank_name,
                                                    placeholder: `${phrase.enter} Bank Name`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'bank'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Location',
                                                    name: 'bank_location',
                                                    type: 'text',
                                                    value: bankReference.bank_location,
                                                    placeholder: `${phrase.enter} Location`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'bank'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Account Number',
                                                    name: 'account_number',
                                                    type: 'number',
                                                    value: bankReference.account_number,
                                                    placeholder: `${phrase.enter} Account Number`,
                                                    class: 'col-md-4 col-12',
                                                    formik: formik,
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'bank'),
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                },
                                                {
                                                    label: 'Phone Number',
                                                    name: 'bank_phone',
                                                    type: 'countrySelect',
                                                    defaultCountry: 'us',
                                                    value: bankReference.bank_phone,
                                                    regions: 'america',
                                                    placeholder: `${phrase.enter} Phone Number`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'bank'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Email',
                                                    name: 'bank_email',
                                                    type: 'email',
                                                    value: bankReference.bank_email,
                                                    placeholder: `${phrase.enter} Email`,
                                                    class: 'col-md-4 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'bank'),
                                                    formik: formik,
                                                },
                                                {
                                                    label: 'Contact Officer',
                                                    name: 'contact_officer',
                                                    type: 'text',
                                                    value: bankReference.contact_officer,
                                                    placeholder: `${phrase.enter} Contact Officer`,
                                                    class: 'col-md-3 col-12',
                                                    onChange: (e) =>
                                                        handleArrayFieldsChange(e, key, 'bank'),
                                                    formik: formik,
                                                },
                                            ]),
                                        )}
                                        {bankReferences.length > 1 ? (
                                            <span
                                                className="material-icons closenode"
                                                onClick={() => removeArrayFields(key, 'bank')}
                                            >
                                                highlight_off
                                            </span>
                                        ) : null}
                                    </div>
                                ))}
                            <Button onClick={() => addArrayFields('bank')}>
                                <span className="material-icons pr-2">add_circle_outline</span>
                                Add Bank Reference
                            </Button>
                        </div>
                    </div>
                )
            case 4:
                return (
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="regTerms"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        language == 'English'
                                            ? genericData.contentterms.for_english
                                            : language == 'Mandarin'
                                            ? genericData.contentterms.for_mandarin
                                            : language == 'French'
                                            ? genericData.contentterms.for_french
                                            : language == 'Spanish'
                                            ? genericData.contentterms.for_spanish
                                            : language == 'Portugese'
                                            ? genericData.contentterms.for_portugese
                                            : language == 'BrazilPortugese'
                                            ? genericData.contentterms.for_brazilportugese
                                            : genericData.contentterms.for_german,
                                }}
                            ></div>
                        </div>
                    </div>
                )
            case 41:
                return (
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="regTerms"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        language == 'English'
                                            ? genericData.content_sales_taxinfo.for_english
                                            : language == 'Mandarin'
                                            ? genericData.content_sales_taxinfo.for_mandarin
                                            : language == 'French'
                                            ? genericData.content_sales_taxinfo.for_french
                                            : language == 'Spanish'
                                            ? genericData.content_sales_taxinfo.for_spanish
                                            : language == 'Portugese'
                                            ? genericData.content_sales_taxinfo.for_portugese
                                            : language == 'BrazilPortugese'
                                            ? genericData.content_sales_taxinfo.for_brazilportugese
                                            : genericData.content_sales_taxinfo.for_german,
                                }}
                            ></div>
                        </div>
                    </div>
                )
            case 42:
                return (
                    <div className="row">
                        <div className="col-12">
                            <div className="regTerms">
                                <StaticPage page="terms" />
                            </div>
                        </div>
                    </div>
                )
            case 5:
                return (
                    <div className="row">
                        <div className="regSuccess">
                            <h3>{phrase.youre_done}!</h3>
                            <h5>{phrase.registerpagestatic_34}</h5>
                        </div>
                    </div>
                )
            default:
                return 'Unknown stepIndex'
        }
    }
    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, 'search')
        }
    }, [isAuthenticated])
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    useEffect(() => {
        console.log('kkkkkkkkkkkkkkkkkkkkkkkkkmmmm')
        formik.setFieldValue('vat_number', formik.values.company_id)
        formik.setFieldValue('ein_number', formik.values.company_id)
        console.log('kkkkkkkkkkkkkkkkkkkkkkkkk')
    }, [formik.values.company_id])
    useEffect(() => {
        formik.setTouched({
            ...formik.touched,
        })
    }, [localStorage.getItem('language')])
    return (
        <section className="registering">
            <div className="register_content">
                <span className="ledLogo">
                    {' '}
                    {isAuthenticated ? (
                        <a onClick={() => redirectDefaultSearch()}>
                            <img src={logo} alt={`${siteName} logo`} />
                        </a>
                    ) : (
                        <Link to="/">
                            <img src={logo} alt={`${siteName} logo`} />
                        </Link>
                    )}
                </span>
                {loading ? (
                    <Loader />
                ) : Object.keys(genericData).length > 0 ? (
                    <div className="registration d-flex justify-content-between container">
                        <div className="regBox">
                            <h2 className="regTitle">
                                Sign <span>Up</span>
                            </h2>
                            <h4 className="stepLabel">
                                Step {activeStep + 1}:{' '}
                                {steps.filter((steps, index) => index === activeStep)}
                            </h4>
                            <form action="" onSubmit={formik.handleSubmit} ref={formRef}>
                                <div className={classes.root}>
                                    <Stepper
                                        activeStep={activeStep}
                                        alternativeLabel
                                        connector={<ColorlibConnector />}
                                    >
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel
                                                    StepIconComponent={ColorlibStepIcon}
                                                ></StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>

                                    <div>
                                        {activeStep !== steps.length && (
                                            <div>
                                                {getStepContent(activeStep)}
                                                {activeStep === 1 ? (
                                                    <>
                                                        {getStepContent(42)}
                                                        <div className="col-12 regTnc">
                                                            <CheckBox
                                                                key={activeStep}
                                                                name={'agree'}
                                                                value={1}
                                                                onchange={formik.handleChange}
                                                                checked={
                                                                    formik.values.agree
                                                                        ? formik.values.agree[0] ==
                                                                          '1'
                                                                            ? true
                                                                            : false
                                                                        : false
                                                                }
                                                                label={[
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={'/terms'}
                                                                        key="termsOfSerice"
                                                                        style={{
                                                                            textDecoration:
                                                                                'underline',
                                                                        }}
                                                                    >
                                                                        {
                                                                            phrase.registerpagestatic_33
                                                                        }
                                                                        ,
                                                                    </a>,
                                                                ]}
                                                            />
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                    textAlign: 'left',
                                                                }}
                                                            >
                                                                {formik.errors['agree']
                                                                    ? formik.errors['agree']
                                                                    : delete formik.errors['agree']}
                                                            </p>
                                                        </div>
                                                        {/* {getStepContent(41)} */}
                                                        {/* <div className="col-12 regTnc">
                                                            <CheckBox
                                                                key={31}
                                                                name={'agreeSalesTax'}
                                                                value={1}
                                                                onchange={formik.handleChange}
                                                                checked={
                                                                    formik.values.agreeSalesTax
                                                                        ? formik.values
                                                                              .agreeSalesTax[0] ==
                                                                          '1'
                                                                            ? true
                                                                            : false
                                                                        : false
                                                                }
                                                                label="I AGREE to Sales Tax"
                                                            />
                                                            <p
                                                                style={{
                                                                    color: 'red',
                                                                    textAlign: 'left',
                                                                }}
                                                            >
                                                                {formik.errors['agreeSalesTax']
                                                                    ? formik.errors['agreeSalesTax']
                                                                    : delete formik.errors[
                                                                          'agreeSalesTax'
                                                                      ]}
                                                            </p>
                                                            <p>
                                                                No Sales Tax Info - Not Applicable
                                                            </p>
                                                        </div> */}
                                                    </>
                                                ) : null}
                                                <div className="crActBtn">
                                                    {activeStep > 0 && (
                                                        <SecondaryButton
                                                            disabled={activeStep === 0}
                                                            label={phrase.back}
                                                            onClick={() => {
                                                                handleBack()
                                                                window.scrollTo(0, 0)
                                                            }}
                                                            className={classes.backButton}
                                                        />
                                                    )}
                                                    <PrimaryButton
                                                        label={
                                                            activeStep === steps.length - 1
                                                                ? phrase.finish
                                                                : phrase.next
                                                        }
                                                        type="submit"
                                                        disabled={stepLoader}
                                                        onClick={() => {
                                                            window.scrollTo(0, 0)
                                                        }}
                                                    />
                                                </div>
                                                {activeStep === 0 && (
                                                    <div className="regMiscAction">
                                                        <p>
                                                            <span className="material-icons">
                                                                arrow_back
                                                            </span>
                                                            <Link
                                                                to="/login"
                                                                onMouseDown={(e) => {
                                                                    e.preventDefault()
                                                                }}
                                                            >
                                                                Back to Login
                                                            </Link>
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null}
            </div>
        </section>
    )
}

export default Registration
