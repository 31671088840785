import React, { useLocation } from 'react-router-dom'
import Nav from '../../atoms/Nav'
import NavItem from '../../atoms/NavItem'
import { menuList, offerMenuList } from '../../../Utils/filterList'

export default function DashboardNav({ type }) {
    let location = useLocation()
    return (
        <Nav>
            {(type === 'offer' ? offerMenuList : menuList).map((list) => (
                <NavItem
                    key={list.href}
                    href={list.href}
                    isActive={location.pathname.slice(0) == list.href}
                >
                    <img
                        src={`/assets/svg/${
                            location.pathname.slice(0) == list.href
                                ? list.active_icon
                                : list.icon_name
                        }`}
                        alt={list.label}
                    />
                    {list.label}
                </NavItem>
            ))}
        </Nav>
    )
}
