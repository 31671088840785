import React, { useReducer, useContext } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall, setAuthToken, getAuthToken } from '../../common/api'
import { response } from '../common'

import {
    COMMON_VALUES,
    BID_HISTORY_VALUE,
    US_STATE_VALUE,
    CLEAR_SEARCH,
    GET_STATIC_PAGE,
    CLEAR_BID_HISTORY,
    SEARCH_VALUE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    NONLOGINTOKEN,
    STATICPAGES,
    All_COUNTRIES,
    LANGUAGE,
    PHRASE,
    MSG_UNREAD_CNT,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        responseStatus: null,
        allCategory: [],
        allLocations: [],
        allCondition: [],
        allDamageTypes: [],
        allPackageTypes: [],
        allUsageTypes: [],
        allBidIncrements: [],
        searchValue: { searchbar: null, orderby: null },
        bidHistoryValue: 0,
        USStates: [],
        allCountries: [],
        static_page: {
            record: {},
        },
        nonLoginToken: localStorage.getItem('nonLoginToken')
            ? localStorage.getItem('nonLoginToken')
            : '',
        staticPages: '',
        language: localStorage.getItem('language') ? localStorage.getItem('language') : 'English',
        phrase: {},
        msgUnreadCount: '',
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const getNonLoginToken = async () => {
        let payload = {
            id: global.site_id,
        }
        const res = await apiCall('post', '', payload, '', 'generatejwttoken')
        let { data } = res
        if (data.status) {
            if (typeof data.token != 'undefined') {
                localStorage.setItem('nonLoginToken', data.token)
                dispatch({
                    type: NONLOGINTOKEN,
                    payload: data.token,
                })
            }
        }
    }
    const changeLanguage = async (language) => {
        dispatch({
            type: LANGUAGE,
            payload: language,
        })
    }
    // get all language phrases for the user
    const getLangPhrase = async (language) => {
        const res = await apiCall(
            'get',
            '',
            '',
            '',
            `getLangPhrases/${language}?site_id=${global.site_id}`,
        )
        let { data } = res
        try {
            if (data && data.success) {
                //console.log('PHRASES: ', res)
                localStorage.setItem('language', language)
                dispatch({
                    type: PHRASE,
                    payload: data.phrases,
                })
            }
        } catch (e) {
            console.log('[getLangPhrase] ', e)
        }
    }

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])

        if (res.data && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.allCategory,
                    allLocations: res.data.data.responseData.allLocations,
                    allCondition: res.data.data.responseData.allCondition,
                    allDamageTypes: res.data.data.responseData.allDamageTypes,
                    allPackageTypes: res.data.data.responseData.allPackageTypes,
                    allUsageTypes: res.data.data.responseData.allUsageTypes,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                },
            })
        }
    }

    const getStaticPage = async (formData) => {
        try {
            setAuthToken(getAuthToken())
            const res = await apiCall(
                'get',
                '',
                formData,
                '',
                `getStaticContent/${formData.page}/${global.site_id}`,
            )
            let { data } = res
            //console.log(data.data.content, 'this is a content')
            if (data.success) {
                if (typeof data.data != 'undefined') {
                    if (typeof data.data.content != 'undefined') {
                        if (typeof data.data.content.content != 'undefined') {
                            dispatch({
                                type: STATICPAGES,
                                payload:
                                    data.data.content.content == ''
                                        ? data.data.content
                                        : data.data.content,
                            })
                        }
                    }
                }
            }
            return res
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
            return err
        }
    }

    const postSupport = async (formData) => {
        try {
            formData.site_id = global.site_id
            const res = await apiCall('post', '', formData, '', 'customersupport')
            if (res.data.msg === 'success') {
                return res.data.msg
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
            return err
        }
    }

    const getSitemap = async () => {
        try {
            setAuthToken(getAuthToken())
            const res = await apiCall(
                'get',
                '',
                '',
                '',
                `sitemap${localStorage.getItem('token') ? '' : '_new'}?version=2`,
            )
            let { data } = res
            //console.log(data.data.content, 'this is a content')
            if (data.success) {
                dispatch({
                    type: STATICPAGES,
                    payload: data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
            return err
        }
    }

    const getMsgUnreadCount = async () => {
        try {
            const res = await apiCall('get', '', '', '', 'messages')
            if (res.data.success) {
                dispatch({
                    type: MSG_UNREAD_CNT,
                    payload: res.data.data.messageunreadcount,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
            return err
        }
    }

    const setUSState = async (data) => {
        dispatch({
            type: US_STATE_VALUE,
            payload: data,
        })
    }
    const setAllCountries = async (data) => {
        dispatch({
            type: All_COUNTRIES,
            payload: data,
        })
    }
    const setSearchValue = async (data) => {
        dispatch({
            type: SEARCH_VALUE,
            payload: data,
        })
    }

    const setBidHistoryValue = async (data) => {
        console.log('SET BID HISTORY VALUE: ', data)
        dispatch({
            type: BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearNotification = async () => {
        try {
            const res = await apiCall('post', '', '', '', 'updateBellNotify')
            return res.data.msg
        } catch (err) {
            return err
        }
    }

    const clearBidHistoryValue = () =>
        dispatch({
            type: CLEAR_BID_HISTORY,
        })

    const clearSearchValue = () =>
        dispatch({
            type: CLEAR_SEARCH,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allDamageTypes: state.allDamageTypes,
                allPackageTypes: state.allPackageTypes,
                allUsageTypes: state.allUsageTypes,
                nonloginToken: state.nonLoginToken,
                allBidIncrements: state.allBidIncrements,
                searchValue: state.searchValue,
                static_page: state.static_page,
                bidHistoryValue: state.bidHistoryValue,
                responseStatus: state.responseStatus,
                USStates: state.USStates,
                staticPages: state.staticPages,
                allCountries: state.allCountries,
                language: state.language,
                phrase: state.phrase,
                msgUnreadCount: state.msgUnreadCount,
                getGlobalVariable,
                setSearchValue,
                clearSearchValue,
                setBidHistoryValue,
                clearBidHistoryValue,
                getStaticPage,
                setUSState,
                clearResponse,
                getNonLoginToken,
                setAllCountries,
                changeLanguage,
                getLangPhrase,
                getSitemap,
                postSupport,
                getMsgUnreadCount,
                clearNotification,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
