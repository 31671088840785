import React from 'react'
import Layout from '../Layout'
import DashboardNav from '../../molecules/DashboardNav'
import './dashboard.css'

export default function DashboardLayout({ children, title }) {
    return (
        <Layout>
            <section className="dashboard-container">
                <DashboardNav />
                <div className="dashboard-bottom">
                    {title ? <h2 className="db-page-title">{title}</h2> : null}
                    <div className="dashboard-body-wrapper">{children}</div>
                </div>
            </section>
        </Layout>
    )
}
