import React from 'react'
import Header from '../../molecules/Header'
import Footer from '../../molecules/Footer'

export default function Layout({ children, showHeader = 'true', showFooter = 'true' }) {
    return (
        <>
            {showHeader === 'true' ? <Header /> : null}
            <main className="main-content-wrapper">{children}</main>
            {showFooter === 'true' ? <Footer /> : null}
        </>
    )
}
