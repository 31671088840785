import React, { useContext, useEffect, useRef } from 'react'
import './FilterPanel.css'
import { Link, useHistory } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckBox from '../../atoms/CheckBox'
import RadioBox, { GreenRadio } from '../../atoms/RadioBox'
import ProductContext from '../../../context/product/productContext'
import CommonContext from '../../../context/common/commonContext'
import { Button, MenuItem } from '@material-ui/core'
import { useFormik } from 'formik'
import Accordian from '@material-ui/core/Accordion'
import AccordianSummary from '@material-ui/core/AccordionSummary'
import AccordianDetails from '@material-ui/core/AccordionDetails'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        // backgroundColor: 'transparent',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const {
        search_alldashboardproducts,
        search_allproducts,
        selectedCategories,
        search_allselectedCategories,
    } = useContext(ProductContext)
    const { setSearchValue, phrase, language } = useContext(CommonContext)
    const [expanded, setExpanded] = React.useState('panel0')
    const history = useHistory()

    // console.log(expanded, 'expand')

    const selectedCatRef = useRef(selectedCategories)

    // Initialize Filter Values
    let filtervalues = [
        {
            title: phrase.category,
            type: 'check',
            name: 'category',
            item: [],
            key: 'category',
        },
        {
            title: phrase.sub_category,
            type: 'check',
            name: 'sub_category',
            item: [],
            key: 'sub_category',
        },
        {
            title: phrase.condition,
            type: 'check',
            name: 'condition',
            item: [],
            key: 'itemcondition',
        },
        {
            title: phrase.listing_format,
            type: 'check',
            name: 'listing',
            item: [],
            key: 'auctiontype',
        },
        // {
        //     title: phrase.regions,
        //     type: 'check',
        //     name: 'region',
        //     item: [],
        //     key: 'regionlist',
        // },
        {
            title: phrase.lot_size,
            type: 'check',
            name: 'lot',
            item: [],
            key: 'lottype',
        },
    ]
    const [filterValues, setFiltervalues] = React.useState(filtervalues)

    const sortValues = props.sorts
    const formik = useFormik({
        initialValues: {
            category: [],
            sub_category: [],
            banner: [],
            category: [],
            countrylist: [],
            itemcondition: [],
            lottype: [],
            regionlist: [],
        },
    })

    // language changed, update filter labels
    // const phraseRef = useRef(phrase)
    // useEffect(() => {
    //     console.log('lang changed___________', language)
    //     //phraseRef.current = phrase
    //     setFiltervalues([...filterValues])
    // }, [phrase])

    useEffect(() => {
        // console.log('SEARCH FILTERS ===== ', search_allproducts)
        if (
            typeof search_allproducts !== 'undefined' &&
            Object.keys(search_allproducts).length > 0
        ) {
            if (typeof filterValues !== 'undefined' && Array.isArray(filterValues)) {
                let filterValuesCopy = [...filterValues]
                let searchProductsCopy = { ...search_allproducts }
                filterValuesCopy = filterValuesCopy.map((ele) => {
                    // console.log(ele, 'ele....')
                    // Lot Size / Listing Format Filtering
                    // Filter by Name
                    // lottype = Lot Size
                    // auctiontype = Listing Format
                    if (ele.key === 'lottype' || ele.key === 'auctiontype') {
                        if (
                            Array.isArray(searchProductsCopy[ele.key]) &&
                            searchProductsCopy[ele.key].length
                        ) {
                            searchProductsCopy[ele.key] = searchProductsCopy[ele.key].map(
                                (ele1, idx) => {
                                    let labelTemp = phrase[ele1]

                                    // modify listing format label name
                                    if (ele.key == 'auctiontype') {
                                        if (ele1 == 'pricelock') {
                                            labelTemp = `${phrase.price_lock}`
                                        } else if (ele1 == 'buynow') {
                                            labelTemp = `${phrase.buy_it_now}`
                                        } else {
                                            labelTemp = `${phrase[ele1]} ${phrase.auction}`
                                        }
                                    }

                                    return {
                                        id: ele.key + idx,
                                        name: ele1,
                                        filterBy: ele1,
                                        label: labelTemp,
                                    }
                                },
                            )
                        }
                    }
                    // Item Condition / Category / Regions Filtering
                    // Filter by ID
                    else if (
                        ele.key === 'itemcondition' ||
                        ele.key === 'category' ||
                        ele.key === 'regionlist' ||
                        ele.key === 'sub_category'
                    ) {
                        if (
                            Array.isArray(searchProductsCopy[ele.key]) &&
                            searchProductsCopy[ele.key].length
                        ) {
                            searchProductsCopy[ele.key] = searchProductsCopy[ele.key].map(
                                (ele1) => {
                                    // console.log('FILTER BY ID: ', ele1)
                                    let nameTemp = ''
                                    if (ele.key === 'regionlist') {
                                        nameTemp = ele1.region_eng ? ele1.region_eng : ele1.name
                                    } else {
                                        nameTemp = phrase[ele1.language_var]
                                            ? phrase[ele1.language_var]
                                            : ele1.name
                                    }
                                    return {
                                        id: ele.key + ele1.id,
                                        filterBy: ele1.id,
                                        name: nameTemp,
                                        value: ele1.value,
                                    }
                                },
                            )
                        }
                    }

                    ele.item = searchProductsCopy[ele.key]
                    return ele
                })
                setFiltervalues(filterValuesCopy)
            }
        }
    }, [search_allproducts])
    // console.log(search_allproducts, 'search_allproducts')
    useEffect(() => {
        //console.log('categories changed: ', selectedCategories)
        selectedCatRef.current = selectedCategories
        setFiltervalues([...filterValues])
    }, [selectedCategories])

    const handleChange = (panel) => (event, newExpanded) => {
        // console.log(panel, 'console')
        if (panel === expanded && !newExpanded) {
            setExpanded(newExpanded ? panel : false)
        } else {
            setExpanded(newExpanded ? panel : false)
        }
    }
    const checkboxHandleChange = (data, d) => (event) => {
        // pricelock auction, take them to price lock page
        if (d.filterBy == 'pricelock') {
            history.push('/price_lock')
        } else {
            let { value, name } = event.target
            let sObj = {
                id: value,
                name,
                type: 'category',
                filterBy: value,
            }
            // console.log('SOBJ: ', sObj)
            if (d.hasOwnProperty('country')) sObj['country'] = d.country
            props.searchFilterFunc(sObj)
        }
    }
    const filterChange = (event) => {
        // console.log(event, 'console Evenet')
        let { value, name } = event.target
    }
    const isChecked = (data) => {
        // console.log(
        //     'isChecked________',
        //     selectedCatRef.current,
        //     '________data_______',
        //     data,
        //     '____',
        //     selectedCategories,
        // )
        if (Array.isArray(selectedCatRef.current) && selectedCatRef.current.length) {
            let selInd = selectedCatRef.current.findIndex((ele) => {
                return ele.id == data.id
            })
            if (selInd >= 0) {
                return true
            }
        }
        return false
    }
    return (
        <>
            {location.pathname.includes('/search/my_offers') ||
            location.pathname.includes('/search/accepted_offers') ||
            location.pathname.includes('/search/declined_offers') ? (
                <>
                    <div className="filterPanel">My Offers</div>
                    <div className="tabs_custom_container">
                        <ul className="custom_tabs">
                            <li>
                                <Link
                                    to="/search/my_offers"
                                    className={
                                        location.pathname.includes('/search/my_offers')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    Active Offers
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/search/accepted_offers"
                                    className={
                                        location.pathname.includes('/search/accepted_offers')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    {' '}
                                    Accepted Offers
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/search/declined_offers"
                                    className={
                                        location.pathname.includes('/search/declined_offers')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    Declined Offers
                                </Link>
                            </li>
                        </ul>
                    </div>
                </>
            ) : null}

            <div className="filter-wrapper">
                <div
                    className={
                        selectedCategories.length <= 0 ? 'filter-top' : 'filter-top filterBtnActive'
                    }
                >
                    {/* <h6 className="accTitleNew">{phrase.filter_by}</h6> */}
                    {/* <h6 className="accTitleNew">
                        <span className="material-icons-sharp mr-1">filter_alt</span>
                        {phrase.filter_by}
                    </h6> */}
                    <Button className="filterClear  filterClearNew">
                        <span className="material-icons-sharp mr-1 accTitleNew">filter_alt</span>
                        {phrase.filter_by}
                    </Button>
                    <Button
                        className="filterClear filterClearNew"
                        onClick={(e) => props.searchFilterFunc({}, true)}
                    >
                        {phrase.clear} {phrase.all}
                    </Button>
                </div>
                <h6 className="category-head">Category</h6>
                {/* {console.log(filterValues, '++++++')} */}
                <div className="filterTagsCnt">
                    {filterValues &&
                        Array.isArray(filterValues) &&
                        filterValues.map(
                            (item) =>
                                item.key === 'category' &&
                                item.item.map((data, index) => (
                                    <Accordion
                                        square
                                        key={index}
                                        expanded={
                                            expanded === `panel${index}`
                                            // || formik.values[data.name].length > 0
                                        }
                                        onChange={handleChange(`panel${index}`)}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                expanded === `panel${index}` ? (
                                                    <RemoveIcon />
                                                ) : (
                                                    <AddIcon />
                                                )
                                            }
                                            aria-controls={`panel${index}d-content`}
                                            id={`panel${index}d-header`}
                                            onChange={filterChange}
                                        >
                                            {data.name}
                                            {/* <span className="clearfix expanded-icon"></span> */}
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            {data && data.value && data.value.length > 0 ? (
                                                <div className="filterCheck">
                                                    {data.value.map((d, i) => (
                                                        <CheckBox
                                                            name={d.id}
                                                            label={d.name}
                                                            value={d.id}
                                                            checked={isChecked(d)}
                                                            onchange={checkboxHandleChange(data, d)}
                                                        />
                                                    ))}
                                                </div>
                                            ) : (
                                                <small>{`No ${data ? data.name : ''} found`}</small>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                )),
                        )}
                </div>
            </div>
            <div className="filterPanel">
                {/* <div className="d-flex fpTitle">
                <h4>{phrase.filter_by}</h4>
            </div> */}
                <div className="filterAcc"></div>
            </div>
        </>
    )
}
export default FilterPanel
