import { useContext } from 'react'
import AlertContext from './../context/alert/alertContext'
import { useSnackbar } from 'notistack'

const handleClick = (enqueueSnackbar, clearAlert, alertContent) => {
    enqueueSnackbar(alertContent.msg, {
        variant: alertContent.type,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })
    clearAlert(alertContent.id)
}

const Alerts = () => {
    const alertContext = useContext(AlertContext)
    const { alerts, clearAlert } = alertContext
    const { enqueueSnackbar } = useSnackbar()
    // const classes = useStyles();
    // warning error info success
    return (
        alerts.length > 0 &&
        alerts.map((alert) => alert.msg && handleClick(enqueueSnackbar, clearAlert, alert))
    )
}

export default Alerts
