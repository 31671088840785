import React, { useEffect } from 'react'
import '../ProductView.css'
import { apiCall } from '../../../common/api'

function LineItemDetails({ data, close }) {
    // update sublot view count
    useEffect(() => {
        console.log(data)
        apiCall('post', 'updateSubLotViewCount', { id: data.id }, '', 'product')
    }, [])

    let lineItemDataList = Object.keys(data).map((key, index) => {
        if (key != 'price' && key != 'product_name' && key != 'id') {
            return (
                <React.Fragment key={index}>
                    <li>
                        <label>
                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/\_/g, ' ')}:{' '}
                        </label>
                        <p>{data[key]}</p>
                    </li>
                </React.Fragment>
            )
        }
    })
    return (
        <div className="pvSlider">
            <div className="mb-2">
                <a onClick={close}>
                    <span className="material-icons">close</span>
                </a>
            </div>
            <h2>{data.product_name}</h2>
            <ul>{lineItemDataList}</ul>
        </div>
    )
}

export default LineItemDetails
