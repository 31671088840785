import React, { useEffect, useState } from 'react'
import { Badge, ListItem } from '@material-ui/core'
import './Message.css'
import moment from 'moment'
import { el } from 'date-fns/locale'

function ActiveSellers(props) {
    const [firstSeller, setFirstSeller] = useState()

    const assignFirstSeller = (element, index) => {
        if (element && index === 0) {
            setFirstSeller(element)
        }
    }

    useEffect(() => {
        if (firstSeller) {
            firstSeller.click()
        }
    }, [firstSeller, props.isArchive])

    return (
        <ul className="activeSellers">
            {props.sellers &&
                props.sellers.map((seller, index) => (
                    <ListItem
                        button
                        key={index}
                        autoFocus={index == 0}
                        onClick={(e) => props.onClick(e, index, seller.userid)}
                        ref={(element) => assignFirstSeller(element, index)}
                    >
                        <span className="material-icons userAvatar">account_circle</span>
                        <div className="d-flex justify-content-between flex-wrap w-100">
                            <div className="asContent d-flex justify-content-between w-100">
                                <h4 className="asTitle">{seller.username}</h4>
                                <h6>{moment(seller.date_sent).format('DD-MM-YYYY')}</h6>
                            </div>
                            <div className="asContent d-flex justify-content-between w-100">
                                <h5 className="asSubject">
                                    {seller.message
                                        ? seller.message.split('<')[0]
                                        : 'Attachment Sent'}
                                </h5>
                                {seller.unreadcount > 0 && !props.isArchive ? (
                                    <Badge badgeContent={seller.unreadcount}></Badge>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </ListItem>
                ))}
        </ul>
    )
}

export default ActiveSellers
