import React, { useReducer } from 'react'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { apiCall, setAuthToken, getAuthToken } from '../../common/api'
import { response } from '../common'

import {
    GET_STATIC_PAGE,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    USER_DATA,
    USER_PREFERENCES,
} from './userTypes'

const UserState = (props) => {
    const initialState = {
        responseStatus: null,
        static_page: {},
        userData: {},
        userNotifications: {},
        userPreferences: {},
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const updateProfile = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateProfile', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'updateProfile')
        } catch (err) {
            resp.commonErrorResponse('updateProfile')
        }
    }

    const forgotPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'forgot-password', formData, '', 'login'),
            ])
            res.data['responseType'] = res.data.status
            res.data['status'] = 'success'
            res.data['message'] = res.data.msg

            resp.commonResponse(res.data, 'forgotPassword')
        } catch (err) {
            resp.commonErrorResponse('forgotPassword')
        }
    }

    const sendPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'sendPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('sendPhoneVerifyCode')
        }
    }

    const verifyPhoneVerifyCode = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'verifyPhoneVerifyCode', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'verifyPhoneVerifyCode')
        } catch (err) {
            resp.commonErrorResponse('verifyPhoneVerifyCode')
        }
    }

    const resetPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resetPassword', formData, '', 'login'),
            ])
            res.data['status'] = res.data.status === true ? 'success' : 'error'
            resp.commonResponse(res.data, 'resetPassword')
        } catch (err) {
            resp.commonErrorResponse('resetPassword')
        }
    }

    const verifyEmail = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'verifyEmail', formData, '', 'user')])
            resp.commonResponse(res.data, 'verifyEmail')
        } catch (err) {
            resp.commonErrorResponse('verifyEmail')
        }
    }

    const getStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'show_static_content', formData)])
            if (res.data.status === 'true') {
                dispatch({
                    type: GET_STATIC_PAGE,
                    payload: res.data.data[0],
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const getUserData = async () => {
        try {
            const res = await apiCall('get', '', '', '', 'my-account')
            if (res.data.success) {
                dispatch({
                    type: USER_DATA,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateUserNumber = async (formData) => {
        const apiLink = 'dashboard/change_user_' + formData.type
        try {
            const res = await apiCall('post', '', formData, '', apiLink)
            if (res.data.success == 'yes') {
                getUserData()
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateBillingAddress = async (formData) => {
        try {
            const res = await apiCall('post', '', formData, '', 'dashboard/insertProfileAdmin')
            if (res.data.success == 'yes') {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateUserLanguage = async (lang) => {
        const apiLink = 'languser/' + lang
        try {
            const res = await apiCall('get', '', '', '', apiLink)
            if (res.data.success == 'yes') {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const checkOldPassword = async (formData) => {
        try {
            const res = await apiCall('post', '', formData, '', 'login/passwordcheck')
            if (res.data.status == 1) {
                return true
            }
            if (res.data.status == 2) {
                return false
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const decodeResetPasswordToken = async (formData) => {
        try {
            const res = await apiCall(
                'get',
                '',
                '',
                '',
                'login/forgot_password?id=' + formData + '&site_id=' + global.site_id,
            )
            if (res.data.success) {
                return res.data.data
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const unsubscribeEmail = async (formData) => {
        try {
            const res = await apiCall('post', 'email', formData, '', `unsubscribe`)
            if (res.data.success) {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
            return false
        }
    }
    const decodeUnsubscribeEmailToken = async (token) => {
        try {
            const res = await apiCall('get', '', '', '', `unsubscribe/${token}`)
            if (res.data.success) {
                return res.data.data
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateUserPassword = async (formData) => {
        try {
            const res = await apiCall('post', '', formData, '', 'login/changepassword')
            if (res.data.status == 1) {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateUserPasswordUnauth = async (formData) => {
        try {
            const res = await apiCall('post', '', formData, '', 'login/change_password')
            if (res.data.status == 1) {
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateUserNotifications = async (formData) => {
        try {
            const res = await apiCall('post', '', formData, '', 'insertProfilenotfication')
            console.log('Database Response-----------', res)
            return true
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
            return false
        }
    }

    const getUserPreferences = async () => {
        try {
            //get user preference and dispatch payload of type User Preferences
            const res = await apiCall('get', '', '', '', 'my-preferences')
            if (res.data.status) {
                dispatch({
                    type: USER_PREFERENCES,
                    payload: res.data.data,
                })
                return true
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went Wrong!',
            })
        }
    }

    const updateUserPreferences = async (formData) => {
        try {
            const res = await apiCall('post', '', formData, '', 'update-preference')
            if (res.data.status) {
                // dispatch({
                //     type: RESPONSE_STATUS,
                //     payload: 'Success',
                // })
                return true
            }
        } catch (err) {
            // dispatch({
            //     type: RESPONSE_STATUS,
            //     payload: 'Something went Wrong!',
            // })
            return false
        }
    }

    return (
        <UserContext.Provider
            value={{
                responseStatus: state.responseStatus,
                static_page: state.static_page,
                userData: state.userData,
                userNotifications: state.userNotifications,
                userPreferences: state.userPreferences,
                clearResponse,
                sendPhoneVerifyCode,
                verifyPhoneVerifyCode,
                updateProfile,
                forgotPassword,
                resetPassword,
                verifyEmail,
                getStaticPage,
                getUserData,
                updateUserNumber,
                updateBillingAddress,
                updateUserLanguage,
                updateUserPassword,
                checkOldPassword,
                updateUserNotifications,
                getUserPreferences,
                updateUserPreferences,
                decodeResetPasswordToken,
                decodeUnsubscribeEmailToken,
                unsubscribeEmail,
                updateUserPasswordUnauth,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState
