import React from 'react'
import './Loader.css'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}))
function Loader() {
    const classes = useStyles()

    return (
        <div className="loader">
            <div className={classes.root}>
                <CircularProgress />
                <h3 className="loaderText">LOADING...</h3>
            </div>
        </div>
    )
}

export default Loader
