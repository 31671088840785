import React from 'react'
import Accordian from '@material-ui/core/Accordion'
import AccordianSummary from '@material-ui/core/AccordionSummary'
import AccordianDetails from '@material-ui/core/AccordionDetails'
import CheckBox from '../../atoms/CheckBox'
import { filterList } from '../../../Utils/filterList'
import './FilterPanel.css'

export default function FilterPanel() {
    return (
        <div className="filter-wrapper">
            <div className="filter-top">
                <div className="ft-left">
                    <span className="material-icons">filter_alt</span>0 Filters
                </div>
                <a> Clear all</a>
            </div>
            <h6 className="category-head">Category</h6>
            {filterList.map((filter) => (
                <Accordian>
                    <AccordianSummary>
                        {filter.filterTitle} <span className="clearfix expanded-icon"></span>
                    </AccordianSummary>
                    <AccordianDetails>
                        {filter.lists.map((data) => (
                            <CheckBox
                                label={data.title}
                                value={data.value}
                                name={data.name}
                                id={data.id}
                            />
                        ))}
                    </AccordianDetails>
                </Accordian>
            ))}
        </div>
    )
}
