import React, { useState, useContext, useEffect, useRef } from 'react'
import './Header.css'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '../../../common/components'
import { Button, ListItem, Menu, MenuItem } from '@material-ui/core'
import { languageArr } from '../../../Utils'
import { logo, siteName } from '../../../Utils'
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
const { user, isAuthenticated, isAdmin, logout } = AuthContext

function AuthHeader() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()
    const { phrase, language, changeLanguage } = useContext(CommonContext)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const [anchorLanguage, setAnchorLanguage] = useState(null)
    const handleClose = (history, path) => {
        setAnchorEl(null)
        if (history && path && path !== 'backdropClick') {
            console.log('history', history, path)
            handleRedirectInternal(history, path)
        }
    }
    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }
    useEffect(() => {
        if (isAuthenticated) {
            if (!sessionInterval.current) {
                sessionInterval.current = setInterval(timerIncrement, 60000)
                document.addEventListener('mousemove', (e) => {
                    idleTime.current = 0
                })
                document.addEventListener('keypress', (e) => {
                    idleTime.current = 0
                })
            }
        }
    }, [isAuthenticated])
    const authContext = useContext(AuthContext)
    const switchLanguage = (e, lang) => {
        if (language != lang) {
            changeLanguage(lang)
            handleClose()
        }
    }
    let languageKey = 'english'
    switch (language) {
        case 'English':
            languageKey = 'english'
            break
        case 'Mandarin':
            languageKey = 'mandarin'
            break
        case 'French':
            languageKey = 'french'
            break
        case 'Spanish':
            languageKey = 'spanish'
            break
        case 'Portugese':
            languageKey = 'portugese'
            break
        case 'BrazilPortugese':
            languageKey = 'brazil_portugese'
            break
        case 'German':
            languageKey = 'german'
            break
    }

    return (
        <div className="AuthHeader customContainer">
            <header className="bg-soft-primary wrapper">
                <nav className="navbar navbar-expand-lg position-relative text-dark">
                    <div className="navbar-collapse-wrapper w-100">
                        <div className="navbar-collapse d-flex justify-content-between">
                            <div className="logo">
                                <Link to="/" className="transition-none d-none d-lg-flex">
                                    <img src={logo} alt={`${siteName} logo`} />
                                </Link>
                            </div>
                            <div className="nav_content">
                                <ul className="navbar-nav ms-lg-auto">
                                    <ListItem>
                                        <NavLink to="/search">Buy from Us</NavLink>
                                    </ListItem>
                                    <ListItem>
                                        <NavLink to="/register_seller">Sell to Us</NavLink>
                                    </ListItem>
                                    <ListItem>
                                        <NavLink to="/about">About Us</NavLink>
                                    </ListItem>
                                    <ListItem>
                                        <NavLink to="/support">Contact</NavLink>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            aria-controls="languageMenu"
                                            aria-haspopup="true"
                                            onClick={(e) => setAnchorLanguage(e.currentTarget)}
                                            className="langButton"
                                        >
                                            {phrase[languageKey]}
                                            <span className="material-icons">arrow_drop_down</span>
                                        </Button>
                                        <Menu
                                            id="languageMenu"
                                            anchorEl={anchorLanguage}
                                            keepMounted
                                            open={Boolean(anchorLanguage)}
                                            value={language}
                                            onClose={() => setAnchorLanguage(null)}
                                        >
                                            <MenuItem
                                                value="English"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'English')
                                                }
                                            >
                                                {phrase.english}
                                            </MenuItem>
                                            <MenuItem
                                                value="Mandarin"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Mandarin')
                                                }
                                            >
                                                {phrase.mandarin}
                                            </MenuItem>
                                            <MenuItem
                                                value="French"
                                                onClick={(event) => switchLanguage(event, 'French')}
                                            >
                                                {phrase.french}
                                            </MenuItem>
                                            <MenuItem
                                                value="Spanish"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Spanish')
                                                }
                                            >
                                                {phrase.spanish}
                                            </MenuItem>
                                            <MenuItem
                                                value="Portugese"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'Portugese')
                                                }
                                            >
                                                {phrase.portugese}
                                            </MenuItem>
                                            <MenuItem
                                                value="BrazilPortugese"
                                                onClick={(event) =>
                                                    switchLanguage(event, 'BrazilPortugese')
                                                }
                                            >
                                                {phrase.brazil_portugese}
                                            </MenuItem>
                                            <MenuItem
                                                value="German"
                                                onClick={(event) => switchLanguage(event, 'German')}
                                            >
                                                {phrase.german}
                                            </MenuItem>
                                        </Menu>
                                    </ListItem>
                                    {isAuthenticated ? (
                                        <ListItem onClick={() => onLogOut()}>Logout</ListItem>
                                    ) : (
                                        <>
                                            <ListItem>
                                                <Button className="loginn">
                                                    <NavLink activeClassName="active" to="/login">
                                                        Login |
                                                    </NavLink>
                                                    <NavLink
                                                        activeClassName="active"
                                                        className="ml-1"
                                                        to="/register"
                                                    >
                                                        Register
                                                    </NavLink>
                                                </Button>
                                            </ListItem>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    )
}

export default AuthHeader
