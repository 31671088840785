import React, { useContext } from 'react'

// Components
import Popup from '../../organisms/Popup'
import PrimaryButton from '../../atoms/PrimaryButton'
import SecondaryButton from '../../atoms/SecondaryButton'

// Context
import CommonContext from '../../../context/common/commonContext'
import AuthContext from '../../../context/auth/authContext'
import AlertContext from '../../../context/alert/alertContext'

// Common Function
import { apiCall } from '../../../common/api'

const AddDispute = (props) => {
    const { phrase } = useContext(CommonContext)
    const { user } = useContext(AuthContext)
    const { setAlert } = useContext(AlertContext)

    const addDispute = () => {
        let subject = document.getElementById('disputeSubject').value
        let msg = document.getElementById('disputeMsg').value
        if (subject != '' && msg != '') {
            let data = {
                projectmsgpost: props.product.id,
                subject: subject,
                message: msg,
                from_id: user.id,
            }
            apiCall('post', 'savedisputes', data, '', 'disputes')
                .then((response) => {
                    setAlert(phrase.your_dispute_is_successfully_added, 'success')
                    // close modal
                    props.closeDisputePopup()
                })
                .catch((err) => {
                    console.log(err)
                    setAlert(phrase.something_went_wrong, 'error')
                })
        }
    }
    return (
        <>
            {props.product ? (
                <Popup
                    open={props.open}
                    handleClose={props.closeDisputePopup}
                    modaltitle={phrase.add + ' ' + phrase.dispute}
                    footer={
                        <>
                            <PrimaryButton label={phrase.send} onClick={addDispute} />
                            <SecondaryButton
                                label={phrase.cancel}
                                onClick={props.closeDisputePopup}
                            />
                        </>
                    }
                >
                    {' '}
                    <div className="row">
                        <div className="col-2">
                            <label>{phrase.title}</label>
                        </div>
                        <div className="col-8">
                            <label>{props.product.title}</label>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-2">
                            <label>{phrase.subject}</label>
                        </div>
                        <div className="col-8">
                            <input
                                className="form-control"
                                type="text"
                                id="disputeSubject"
                                placeholder={phrase.enter + ' ' + phrase.subject}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <label>{phrase.message}</label>
                        </div>
                        <div className="col-8">
                            <textarea
                                className="form-control"
                                id="disputeMsg"
                                rows="4"
                                placeholder={phrase.enter_your_message}
                            ></textarea>
                        </div>
                    </div>
                </Popup>
            ) : null}
        </>
    )
}

export default AddDispute
