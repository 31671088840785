import React, { useState, useContext, useEffect } from 'react'
import '../Login/Login.css'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../context/auth/authContext'
import CommonContext from '../../context/common/commonContext'
import { logo, siteName } from '../../Utils'

const AutoLogin = (props) => {
    const history = useHistory()
    const authContext = useContext(AuthContext)

    const { phrase } = useContext(CommonContext)

    const { login, responseStatus, isAuthenticated } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, 'search')
        }
    }, [isAuthenticated])

    const validationArray = Yup.object({
        email: Yup.string().required(`${phrase.enter} ${phrase.email_address}`),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationArray,
        onSubmit: async (values) => {
            let payload = {
                username: values.email,
                autologin: 1,
                site_id: global.site_id,
            }
            login(payload)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'search')
                } else {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (props.match.params.token == process.env.REACT_APP_AUTO_LOGIN_TOKEN) {
            if (props.match.params.email) {
                formik.values.email = props.match.params.email
                formik.submitForm()
            } else {
                handleRedirectInternal(history, '')
            }
        } else {
            handleRedirectInternal(history, '')
        }
    }, [])

    return (
        <div className="loginContainer">
            <div className="login">
                <Link to="/">
                    <img className="brandLogo" src={logo} alt={`${siteName} logo`} />
                </Link>
                <h2 className="loginTitle">
                    <span>Please wait..</span>
                </h2>
            </div>
        </div>
    )
}

export default AutoLogin
