import React, { useState } from 'react'
import Spreadsheet from 'react-spreadsheet'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import ProductTable from '../../components/organisms/ProductTable'
import Layout from '../../components/templates/Layout'
import SubmitOffer from '../../components/organisms/SubmitOffer'
import { productList } from './products'
import './ProductView.css'
const productData = [
    {
        id: 1,
        title: '100* HP Elitebook * 360 1040 G6 (Core i7 - 8665u)',
        category: 'Laptop',
        product_type: 'Wholesale',
        quantity: 100,
        price: 200,
        date: '09-12-2023',
    },
    {
        id: 2,
        title: '100* HP Elitebook * 360 1040 G6 (Core i7 - 8665u)',
        category: 'Laptop',
        product_type: 'Wholesale',
        quantity: 100,
        price: 200,
        date: '09-12-2023',
    },
    {
        id: 3,
        title: '100* HP Elitebook * 360 1040 G6 (Core i7 - 8665u)',
        category: 'Laptop',
        product_type: 'Wholesale',
        quantity: 100,
        price: 200,
        date: '09-12-2023',
    },
    {
        id: 4,
        title: '100* HP Elitebook * 360 1040 G6 (Core i7 - 8665u)',
        category: 'Laptop',
        product_type: 'Wholesale',
        quantity: 100,
        price: 200,
        date: '09-12-2023',
    },
]

const ProductView2 = (props) => {
    const headData = ['Title', 'Category', 'Qty', 'Price', 'Action']
    const columnLabels = [
        'Manufacturer',
        'Model',
        'Processor Type',
        'Memory',
        'HD1 Size',
        'Video Card Model',
        'WebCam',
        'keyboard',
    ]
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const handleOpen = () => setOpen(true)

    return (
        <Layout>
            <div className="product-view-container pt-4 pb-4 container">
                <div className="pv-descp-text gray-box">
                    <div className="pv-top-box">
                        <h1 className="pv-product-title">
                            199 x Lenovo Thinkpad T460 T470 Notenooks - Take all @ 68.50GBP PER UNIT
                        </h1>
                        <div className="spread-sheet-wrapper">
                            <Spreadsheet data={productList} columnLabels={columnLabels} />
                        </div>
                        <div className="pv-bottom-actions dp-list-buttons">
                            <a className="dl-btn">
                                <span className="material-icons">download</span>Download Product
                                List
                            </a>
                            <PrimaryButton
                                // disabled={loading}
                                type="button"
                                label="Make an Offer"
                                btnSize="small"
                                onClick={handleOpen}
                            />
                            <SecondaryButton
                                // disabled={loading}
                                type="button"
                                label="Contact Us"
                                btnSize="small"
                            />
                        </div>
                    </div>
                </div>
                <h4 className="wd-head clearfix">Other Laptop Wholesale Deals</h4>
                <ProductTable headData={headData} productData={productData} />
                <SubmitOffer
                    open={open}
                    handleClose={handleClose}
                    title="100* HP Elitebook * 360 1040 G6 (Core i7 - 8665u)"
                />
            </div>
        </Layout>
    )
}

export default ProductView2
