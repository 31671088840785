import React, { useEffect, useContext, useState, useRef } from 'react'
import openSocket from 'socket.io-client'
import { Route, withRouter, Switch, useHistory } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Login from '../views/Login'
import AutoLogin from '../views/AutoLogin'
import Maintainance from '../components/molecules/Maintainance'
import Home from '../views/Home'
import Home1 from '../views/Home/index1'
import AuthContext from '../context/auth/authContext'
import AlertContext from '../context/alert/alertContext'
import CommonContext from '../context/common/commonContext'
import csc from 'country-state-city'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import UnsubscribeEmail from '../views/UnsubscribeEmail'
import Search from '../views/Search/search_old'
import UnAuthHeader from '../components/molecules/Header/UnAuthHeader'
import AuthHeader from '../components/molecules/Header/AuthHeader'
import Registration from '../views/Registration'
import SellerRegistration from '../views/SellerRegistration'
import StaticPage from '../views/StaticPages'
import Grading from '../views/Registration/Grading'
import ProductView from '../views/ProductView'
import ProductView2 from '../views/ProductView/ProductView2'
import Dashboard from '../views/Dashboard'
import Message from '../views/Message'
import MyAccount from '../views/MyAccount'
import MyPreferences from '../views/MyPreferences'
import PriceLock from '../views/PriceLock'
import Support from '../views/Support'
import Sitemap from '../views/Sitemap'
import Invoice from '../views/Invoice'
import BuynowConfirm from '../views/BuynowConfirm'
import OfferConfirm from '../views/OfferConfirm'
import { handleRedirectInternal } from '../common/components'
import ErrorPage from '../views/ErrorPage'
import ChangePassword from '../views/MyAccount/ChangePassword'
import DashboardOffer from '../views/DashboardOffer'

const Routes = (props) => {
    const authContext = useContext(AuthContext)
    const { user, loadUser, logout, isAuthenticated } = authContext
    const [maintainanceMode, setMatainanceMode] = useState(false)
    const { setAlert } = useContext(AlertContext)
    const commonContext = useContext(CommonContext)
    const {
        //getGlobalVariable,
        setUSState,
        getNonLoginToken,
        setAllCountries,
        phrase,
        getLangPhrase,
        language,
    } = commonContext
    const [readyToRender, setReady] = useState(false)
    const history = useHistory()

    const userRef = useRef(user)

    useEffect(() => {
        userRef.current = user
    })

    useEffect(() => {
        // ready to render after lang phrases are loaded
        if (Object.keys(phrase).length > 0) {
            setReady(true)
        }
    }, [phrase])

    useEffect(() => {
        getLangPhrase(language)
    }, [language])

    useEffect(() => {
        if (global.maintainance_mode == true && maintainanceMode == false) {
            setMatainanceMode(true)
        } else if (global.maintainance_mode == false) {
            setMatainanceMode(false)
        }
    }, [global.maintainance_mode])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
        socket.on('userDeactivate', (data) => {
            // current user deactivated, logout the user
            if (userRef.current.id == data.userId) {
                logout()
                setAlert('Your account have been deactivated. You were forced to log out.', 'error')
            }
        })
        if (localStorage.token) {
            loadUser()
        }
        getNonLoginToken()
        //getGlobalVariable()
        if (Object.keys(phrase).length == 0) {
            getLangPhrase(language)
        }
        const USStates = []
        const allCountries = csc.getAllCountries().map((ele) => {
            return { value: ele.id, show: ele.name }
        })
        csc.getStatesOfCountry('231').map((lot) => {
            USStates.push({ value: lot.name, show: lot.name })
        })
        setAllCountries(allCountries)
        setUSState(USStates)
        return () => {
            socket.off('userDeactivate', (data) => {})
            socket.disconnect()
        }
    }, [])

    // else {
    //     header = <AuthHeader />
    //     footer = <Footer />
    // }

    // if (isAuthenticated && props.location.pathname === '/') {
    //     handleRedirectInternal(history, 'search')
    // }
    return (
        <>
            {readyToRender ? (
                maintainanceMode ? (
                    <Maintainance />
                ) : (
                    <Switch>
                        {/* <Route exact path="/" component={Login} /> */}
                        {/* <Route exact path="/" component={Home} /> */}
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/" component={Search} />
                        <Route exact path="/auto_login/:email/:token" component={AutoLogin} />
                        <Route exact path="/forgot_password" component={ForgotPassword} />
                        <Route exact path="/reset_password/:token" component={ResetPassword} />
                        <Route exact path="/unsubscribe/:token" component={UnsubscribeEmail} />
                        <Route exact path="/afterregister/:token" component={Registration} />
                        <Route exact path="/register" component={Registration} />
                        <Route exact path="/register_seller" component={SellerRegistration} />
                        <Route exact path="/search" component={Search} />
                        <Route exact path="/search/my_offers" component={Search} />
                        <Route exact path="/search/accepted_offers" component={Search} />
                        <Route exact path="/search/declined_offers" component={Search} />
                        <Route exact path="/recent/:type" component={Search} />
                        <Route exact path="/product/view/:id" component={ProductView} />
                        <Route exact path="/product/view/:id/2" component={ProductView2} />
                        <PrivateRoute exact path="/product/buynow/:id" component={BuynowConfirm} />
                        <PrivateRoute
                            exact
                            path="/product/offer/:id/:oid"
                            component={OfferConfirm}
                        />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/messages" component={Message} />
                        <Route exact path="/my_account" component={MyAccount} />
                        <Route exact path="/change-password" component={ChangePassword} />
                        <PrivateRoute exact path="/my_preferences" component={MyPreferences} />
                        <PrivateRoute exact path="/price_lock" component={PriceLock} />
                        <Route exact path="/invoice/:id" component={Invoice} />
                        <Route exact path="/support" component={Support} />
                        <Route exact path="/sitemap" component={Sitemap} />
                        <Route exact path="/grading" component={Grading} />
                        <Route exact path="/about">
                            <StaticPage page="about_pallets" />
                        </Route>
                        <Route exact path="/how-to-bid">
                            <StaticPage page="how_to_bid_pallets" />
                        </Route>
                        <Route exact path="/how-to">
                            <StaticPage page="how_to_pallets" />
                        </Route>
                        <Route exact path="/privacy">
                            <StaticPage page="privacy_pallets" />
                        </Route>
                        <Route exact path="/privacy-shield">
                            <StaticPage page="privacy_shield_pallets" />
                        </Route>
                        <Route exact path="/terms">
                            <StaticPage page="terms_pallets" />
                        </Route>
                        <Route exact path="/grading">
                            <StaticPage page="grading_pallets" />
                        </Route>
                        <PrivateRoute exact path="/dashboard/:type" component={Dashboard} />
                        <PrivateRoute
                            exact
                            path="/dashboard_offer/:type"
                            component={DashboardOffer}
                        />
                        <Route exact path="*" component={ErrorPage} />
                    </Switch>
                )
            ) : null}
        </>
    )
}
export default withRouter(Routes)
