import React from 'react'
import { removeHTMLTags } from '../../../common/components'

export const ReadMore = ({
    data,
    limit,
    setHTML,
    buttonText,
    readMoreFunction,
    readMoreState,
    ...rest
}) => {
    let cleanedData = removeHTMLTags(data)

    return (
        <>
            <p className={`${rest.className}`}>
                {setHTML ? (
                    <>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: readMoreState
                                    ? cleanedData
                                    : cleanedData.substring(0, limit),
                            }}
                        ></span>
                    </>
                ) : (
                    <> {readMoreState ? cleanedData : cleanedData.substring(0, limit)}</>
                )}
                {cleanedData.length > limit && !readMoreState ? '...' : null}
            </p>
            {cleanedData.length > limit && !readMoreState && (
                <a onClick={() => readMoreFunction(true)}>{buttonText}</a>
            )}
        </>
    )
}

export default ReadMore
