export const productList = [
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
    [
        { value: 'Lenovo', readOnly: true },
        { value: 'Thinkpad T460s', readOnly: true },
        { value: 'Inter(R) Core(TM) i7-6600U CPU@2.60GHz', readOnly: true },
        { value: '20GB', readOnly: true },
        { value: '512GB', readOnly: true },
        { value: 'Skylake Gt2 [HD graphics 520]', readOnly: true },
        { value: 'Yes', readOnly: true },
        { value: 'Save', readOnly: true },
    ],
]
