import { Button, Divider, InputAdornment, Menu, MenuItem } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import CheckBox from '../../components/atoms/CheckBox'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import UserContext from '../../context/user/userContext'
import CommonContext from '../../context/common/commonContext'
import { useFormik } from 'formik'
import { mapData } from '../../common/components'
import * as Yup from 'yup'
import csc from 'country-state-city'
import lookup from 'country-code-lookup'
import AlertContext from '../../context/alert/alertContext'
import './MyAccount.css'
import SecondaryButton from '../../components/atoms/SecondaryButton'
import DashboardLayout from '../../components/templates/DashboardLayout'

function MyAccount() {
    let {
        getUserData,
        userData,
        userNotifications,
        updateUserNumber,
        updateBillingAddress,
        updateUserLanguage,
        updateUserPassword,
        checkOldPassword,
        updateUserNotifications,
    } = useContext(UserContext)
    let { language, getLangPhrase, phrase, allCountries, changeLanguage } =
        useContext(CommonContext)
    const { setAlert } = useContext(AlertContext)
    const [isEditPhoneNumberClicked, setEditPhoneNumberClick] = useState(false)
    const [isEditOfficeNumberClicked, setEditOfficeNumberClick] = useState(false)
    const [isEditAddressClicked, setEditAddressClick] = useState(false)
    const [anchorLang, setAnchorLang] = useState(null)
    const [notificationState, setNotificationState] = useState({
        watch_notify: false,
        watch_notify_sms: false,
        bid_notify: false,
        bid_notify_sms: false,
        win_notify: false,
        win_notify_sms: false,
        prefer: false,
        prefer_sms: false,
        endsoon_notify: false,
        endsoon_notify_sms: false,
        wsoon_notify: false,
        wsoon_notify_sms: false,
    })
    const [addressInitialValues, setAddressInitialValues] = useState({
        first_name: '',
        last_name: '',
        company_name: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    })

    let languageKey = 'english'
    switch (language) {
        case 'English':
            languageKey = 'english'
            break
        case 'Mandarin':
            languageKey = 'mandarin'
            break
        case 'French':
            languageKey = 'french'
            break
        case 'Spanish':
            languageKey = 'spanish'
            break
        case 'Portugese':
            languageKey = 'portugese'
            break
        case 'BrazilPortugese':
            languageKey = 'brazil_portugese'
            break
        case 'English':
            languageKey = 'german'
            break
    }

    useEffect(async () => {
        await getUserData()
        getLangPhrase(language)
        document.title = global.site_title + ' | My Account'
    }, [language])

    useEffect(() => {
        setNotificationState({
            ...notificationState,
            bid_notify: userNotifications.bid_notify && userNotifications.bid_notify.length > 0,
            bid_notify_sms:
                userNotifications.bid_notify_sms && userNotifications.bid_notify_sms.length > 0,
            win_notify: userNotifications.win_notify && userNotifications.win_notify.length > 0,
            win_notify_sms:
                userNotifications.win_notify_sms && userNotifications.win_notify_sms.length > 0,
            prefer: userNotifications.prefer && userNotifications.prefer.length > 0,
            prefer_sms: userNotifications.prefer_sms && userNotifications.prefer_sms.length > 0,
            endsoon_notify:
                userNotifications.endsoon_notify && userNotifications.endsoon_notify.length > 0,
            endsoon_notify_sms:
                userNotifications.endsoon_notify_sms &&
                userNotifications.endsoon_notify_sms.length > 0,
            wsoon_notify:
                userNotifications.wsoon_notify && userNotifications.wsoon_notify.length > 0,
            wsoon_notify_sms:
                userNotifications.wsoon_notify_sms && userNotifications.wsoon_notify_sms.length > 0,
            watch_notify:
                userNotifications.watch_notify && userNotifications.watch_notify.length > 0,
            watch_notify_sms:
                userNotifications.watch_notify_sms && userNotifications.watch_notify_sms.length > 0,
        })
    }, [userNotifications])

    const phoneNumberFormik = useFormik({
        initialValues: {
            phone: userData.phone,
        },
        onSubmit: async (values) => {
            let payload = {}
            payload['p_val'] = values['phone']
            payload['type'] = 'phone'

            updateUserNumber(payload)
            setEditPhoneNumberClick(false)
        },
        enableReinitialize: true,
    })

    const officeNumberFormik = useFormik({
        initialValues: {
            office: userData.landline,
        },
        onSubmit: async (values) => {
            let payload = {}
            payload['p_val'] = values['office']
            payload['type'] = 'landline'

            updateUserNumber(payload)
            setEditOfficeNumberClick(false)
        },
        enableReinitialize: true,
    })

    const addressValidationArray = Yup.object({
        company_name: Yup.string().required('Required Field'),
        address: Yup.string().required('Required Field'),
        first_name: Yup.string().required('Required Field'),
        last_name: Yup.string().required('Required Field'),
        city: Yup.string().required('Required Field'),
        state: Yup.string().required('Required Field'),
        country: Yup.string().required('Required Field'),
        zip: Yup.string()
            .matches(/^([0-9]+-)*[0-9]+$/, `${phrase.invalid_format} ${phrase.zip}`)
            .min(5, 'Min 4 Characters')
            .max(6, 'Max 6 Characters')
            .test('checkMin', `${phrase.invalid_format} ${phrase.zip}`, (value) => {
                let zip = parseInt(value)
                if (zip < 0) return false
                return true
            }),
    })

    const passwordValidationArray = Yup.object({
        old_password: Yup.string().required(`${phrase.enter} ${phrase.old_password}`),
        new_password: Yup.string()
            .min(8, 'Min 8 Characters')
            .required(`${phrase.enter} ${phrase.new_password}`),
        confirm_password: Yup.string()
            .required(`${phrase.enter} ${phrase.confirm_password}`)
            .when('new_password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf([Yup.ref('new_password')], 'Password Mismatch'),
            }),
    })

    const changePasswordFormik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: passwordValidationArray,
        onSubmit: async (values) => {
            let checkPasswordPayload = {}
            checkPasswordPayload['userid'] = userData.id
            checkPasswordPayload['password'] = values['old_password']
            checkPasswordPayload['username'] = userData.email

            let changePasswordPayload = {}
            changePasswordPayload['new_password1'] = values['new_password']
            changePasswordPayload['email_encry'] = userData.email

            const oldPasswordMatch = await checkOldPassword(checkPasswordPayload)
            if (oldPasswordMatch) {
                const passwordChanged = await updateUserPassword(changePasswordPayload)
                if (passwordChanged) {
                    setAlert(phrase.password_successfully_changed, 'success')
                }
            } else {
                setAlert('Old Password Mismatch', 'error')
            }

            changePasswordFormik.resetForm()
        },
    })

    const editPhoneNumber = [
        {
            type: 'countrySelect',
            name: 'phone',
            defaultCountry: 'us',
            class: 'col-8',
            regions: 'america',
            autoFocus: true,
            formik: phoneNumberFormik,
        },
    ]

    const editOfficeNumber = [
        {
            type: 'countrySelect',
            name: 'office',
            defaultCountry: 'us',
            class: 'col-8',
            regions: 'america',
            autoFocus: true,
            formik: officeNumberFormik,
        },
    ]

    //-------------------------------------------------------------------------------------------

    const switchLanguage = (e, lang) => {
        if (language != lang) {
            updateUserLanguage(lang)
            changeLanguage(lang)
            handleMenuClose()
        }
    }

    const handleMenuClose = () => {
        setAnchorLang(null)
    }

    //--------------------------------------------------------------------------------------------

    const editAddressFormik = useFormik({
        initialValues: addressInitialValues,
        validationSchema: addressValidationArray,
        onSubmit: async (values) => {
            let payload = {}
            let { name: countryName } = csc.getCountryById(values['country'])
            let { name: stateName } = csc.getStateById(values['state'])
            payload['billing_firstname'] = values['first_name']
            payload['billing_lastname'] = values['last_name']
            payload['billing_companyname'] = values['company_name']
            payload['billing_address'] = values['address']
            payload['billing_city'] = values['city']
            payload['billing_state'] = stateName
            payload['billing_country'] = countryName
            payload['billing_zipcode'] = values['zip']

            let addressUpdated = await updateBillingAddress(payload)
            if (addressUpdated) {
                await getUserData()
                setEditAddressClick(false)
                editAddressFormik.resetForm()
            }
        },
        enableReinitialize: true,
    })

    const [statesinLocal, setStatesinLocal] = useState([])
    useEffect(() => {
        if (statesinLocal.length > 0) {
            let stateId = statesinLocal.findIndex((element) => element.show == userData.state)
            if (lookup.byCountry(userData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(userData.country).iso2,
                )
                setAddressInitialValues({
                    ...addressInitialValues,
                    country: countryId,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    company_name: userData.companyname,
                    address: userData.address1,
                    city: userData.city,
                    zip: userData.zip,
                    state: statesinLocal[stateId] && statesinLocal[stateId].value,
                })
            }
        } else {
            if (lookup.byCountry(userData.country) != null) {
                let { id: countryId } = csc.getCountryByCode(
                    lookup.byCountry(userData.country).iso2,
                )
                setAddressInitialValues({
                    ...addressInitialValues,
                    country: countryId,
                    first_name: userData.first_name,
                    last_name: userData.last_name,
                    company_name: userData.companyname,
                    address: userData.address1,
                    city: userData.city,
                    zip: userData.zip,
                })
            }
        }
    }, [userData])

    useEffect(() => {
        const { id } = csc.getCountryById(editAddressFormik.values.country)
        let states = csc.getStatesOfCountry(id)
        states = states.map((ele) => {
            let o = {
                show: ele.name,
                value: ele.id,
            }
            return o
        })
        setStatesinLocal(states)
        let stateIndex = states.findIndex((element) => element.show == userData.state)
        if (stateIndex != -1) {
            setAddressInitialValues({
                ...addressInitialValues,
                state: states[stateIndex] && states[stateIndex].value,
            })
        }
    }, [editAddressFormik.values.country])

    const editBillingAddress = [
        {
            label: phrase.first_name,
            name: 'first_name',
            type: 'text',
            class: 'col-md-6',
            autoFocus: true,
            formik: editAddressFormik,
        },
        {
            label: phrase.last_name,
            name: 'last_name',
            type: 'text',
            class: 'col-md-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.company_name,
            name: 'company_name',
            type: 'text',
            class: 'col-md-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.billing_address,
            name: 'address',
            type: 'text',
            class: 'col-md-6',
            formik: editAddressFormik,
        },
        {
            label: phrase.country,
            name: 'country',
            type: 'select',
            options: allCountries,
            class: 'col-12',
            formik: editAddressFormik,
        },
        {
            label: phrase.city,
            name: 'city',
            type: 'text',
            class: 'col-4',
            formik: editAddressFormik,
        },
        {
            label: phrase.state,
            name: 'state',
            type: 'select',
            options: statesinLocal,
            class: 'col-4',
            formik: editAddressFormik,
        },
        {
            label: phrase.zip,
            name: 'zip',
            type: 'text',
            class: 'col-4',
            formik: editAddressFormik,
        },
    ]

    const changePassword = [
        {
            label: phrase.old_password,
            name: 'old_password',
            type: 'password',
            class: 'col-md-6',
            formik: changePasswordFormik,
            startAdornment: (
                <InputAdornment position="start">
                    <img src="./assets/svg/key.svg" />
                </InputAdornment>
            ),
        },
        {
            label: phrase.new_password,
            name: 'new_password',
            type: 'password',
            class: 'col-md-6',
            formik: changePasswordFormik,
            startAdornment: (
                <InputAdornment position="start">
                    <img src="./assets/svg/key.svg" />
                </InputAdornment>
            ),
        },
        {
            label: phrase.confirm_password,
            name: 'confirm_password',
            type: 'password',
            class: 'col-md-6',
            formik: changePasswordFormik,
            startAdornment: (
                <InputAdornment position="start">
                    <img src="./assets/svg/key.svg" />
                </InputAdornment>
            ),
        },
    ]

    const notificationList = [
        {
            label: 'Item Matching My Wishlist Is Listed For Auction',
            email: 'watch_notify',
            sms: 'watch_notify_sms',
        },
        { label: 'When I Bid On An Item', email: 'bid_notify', sms: 'bid_notify_sms' },
        { label: 'When I Win An Auction', email: 'win_notify', sms: 'win_notify_sms' },
        {
            label: 'When An Item Of My Preferred Category Is Posted',
            email: 'prefer',
            sms: 'prefer_sms',
        },
        {
            label: 'Auction Ending Soon',
            email: 'endsoon_notify',
            sms: 'endsoon_notify_sms',
        },
        {
            label: 'Watchlist Ending Soon',
            email: 'wsoon_notify',
            sms: 'wsoon_notify_sms',
        },
    ]

    const notificationSubmitHandler = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        let payloadFormData = new FormData()
        payloadFormData.append('user_id', userData.id)

        notificationState.watch_notify ? payloadFormData.append('user_notify', 1) : ''
        notificationState.watch_notify_sms ? payloadFormData.append('user_notify_sms', 1) : ''
        notificationState.bid_notify ? payloadFormData.append('user_notify', 2) : ''
        notificationState.bid_notify_sms ? payloadFormData.append('user_notify_sms', 2) : ''
        notificationState.win_notify ? payloadFormData.append('user_notify', 4) : ''
        notificationState.win_notify_sms ? payloadFormData.append('user_notify_sms', 4) : ''
        notificationState.prefer ? payloadFormData.append('user_notify', 5) : ''
        notificationState.prefer_sms ? payloadFormData.append('user_notify_sms', 5) : ''
        notificationState.endsoon_notify ? payloadFormData.append('user_notify', 6) : ''
        notificationState.endsoon_notify_sms ? payloadFormData.append('user_notify_sms', 6) : ''
        notificationState.wsoon_notify ? payloadFormData.append('user_notify', 7) : ''
        notificationState.wsoon_notify_sms ? payloadFormData.append('user_notify_sms', 7) : ''

        const notificationUpdated = await updateUserNotifications(payloadFormData)
        if (notificationUpdated) {
            setAlert('Notifications Updated Successfully', 'success')
        }
    }

    const checkboxChangeHandler = (event) => {
        let toChange = event.target.value
        if (notificationState[toChange]) {
            setNotificationState({
                ...notificationState,
                [toChange]: false,
            })
        } else {
            setNotificationState({
                ...notificationState,
                [toChange]: true,
            })
        }
    }
    console.log(userData, '++++++++++++++++++')
    return (
        <DashboardLayout title={phrase.change_password}>
            <div className="my-account-wrapper">
                <form action="" onSubmit={changePasswordFormik.handleSubmit} className="row">
                    {Object.values(mapData(changePassword))}
                    <div className="row col-12 btnAlignment">
                        <div className="col-md-6">
                            <PrimaryButton label={phrase.change_password} type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </DashboardLayout>
    )
}

export default MyAccount
